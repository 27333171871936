import React, { useCallback, useEffect, useState } from "react";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interaction from "@fullcalendar/interaction";

import AppointmentModal from "../components/AppointmentModal";
import "./Home.style.css";

import useFetchData from "../components/useFetchDatav3";

export default function Home() {
   useEffect(() => {
      document.body.classList.add("app-calendar");

      return () => {
         document.body.classList.remove("app-calendar");
      };
   }, []);
   const { fetchData } = useFetchData();
   const [appointments, setAppointments] = useState([]);
   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
   const [skin, setSkin] = useState(currentSkin);

   const [selectedAppointment, setSelectedAppointment] = useState(null);
   const [selectedAppointmentTitle, setSelectedAppointmentTitle] = useState(null);
   const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
   const [selectedAppointmentStartDate, setSelectedAppointmentStartDate] = useState(null);
   const [selectedMode, setSelectedMode] = useState(null);
   const [showAppointmentModal, setShowAppointmentModal] = useState(false);

   const switchSkin = (skin) => {
      if (skin === "dark") {
         const btnWhite = document.getElementsByClassName("btn-white");

         for (const btn of btnWhite) {
            btn.classList.add("btn-outline-primary");
            btn.classList.remove("btn-white");
         }
      } else {
         const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

         for (const btn of btnOutlinePrimary) {
            btn.classList.remove("btn-outline-primary");
            btn.classList.add("btn-white");
         }
      }
   };

   switchSkin(skin);

   useEffect(() => {
      switchSkin(skin);
   }, [skin]);

   const handleEventClick = (appointment) => {
      if (appointment.event) {
         setSelectedAppointmentTitle(appointment.event.title);
         setSelectedAppointmentId(appointment.event.id);
         setSelectedAppointmentStartDate(appointment.event.start);
         setSelectedAppointment(appointment.event.extendedProps);
         setSelectedMode("edit");
         setShowAppointmentModal(true);
      } else {
         setSelectedAppointmentTitle("");
         setSelectedAppointmentId("");
         setSelectedAppointmentStartDate(appointment.date);
         setSelectedAppointment({});
         setSelectedMode("new");
         setShowAppointmentModal(true);
      }
   };

   const handleAppointmentModalClose = () => {
      console.log("parent closing");
      setSelectedAppointment(null);

      setTimeout(() => {
         setShowAppointmentModal(null);
      }, 300);
   };

   const handleDatesSet = (fetchInfo) => {
      const startDate = fetchInfo.startStr.slice(0, 10);
      const endDate = fetchInfo.endStr.slice(0, 10);
      // Fetch events for the current date range
      fetchEvents(startDate, endDate);
   };

   const fetchEvents = async (startDate, endDate) => {
      const method = "POST";
      const values = { start: startDate, end: endDate, view: "month", on_calendar: true };
      const params = "params=" + JSON.stringify(values);
      const body = params;
      const url = "/getProviderAppointments.sz";
      const contentType = "application/x-www-form-urlencoded";

      try {
         const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
         // console.log(responseData);
         // console.log(fetchLoading);
         // console.log(fetchError);
         if (responseData.st === 1) {
            console.log(responseData.payload);
            setAppointments(responseData.payload.appointments);
         } else {
            if (responseData.m_en) {
               setAppointments([]);
            } else {
               setAppointments([]);
            }
         }
      } catch (error) {
         setAppointments([]);
      }
   };

   const customEventContent = useCallback(({ event }) => {
      console.log("event is:", event);

      return (
         <>
            <div className="fc-event-time"></div>
            <div className="fc-event-title">
               {event.title}
               <span className="float-end">
                  {event.extendedProps.sessionstatus === 2 ||
                     (event.extendedProps.sessionstatus === 9 && (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Session Completed</Tooltip>}>
                           <i className="ri-check-line me-1" aria-label="ri-check-line"></i>
                        </OverlayTrigger>
                     ))}

                  {event.extendedProps.split_link_no && (
                     <OverlayTrigger placement="top" overlay={<Tooltip>Split Session</Tooltip>}>
                        <i className="ri-git-merge-line me-1" aria-label="ri-git-merge-line"></i>
                     </OverlayTrigger>
                  )}
                  {event.extendedProps.islocked && (
                     <OverlayTrigger placement="top" overlay={<Tooltip>Session Locked</Tooltip>}>
                        <i className="ri-lock-line me-1" aria-label="ri-lock-line"></i>
                     </OverlayTrigger>
                  )}
                  {event.extendedProps.location_id === 1 ? (
                     <OverlayTrigger placement="top" overlay={<Tooltip>Remote Session</Tooltip>}>
                        <i className="ri-phone-line me-1" aria-label="ri-phone-line"></i>
                     </OverlayTrigger>
                  ) : (
                     <OverlayTrigger placement="top" overlay={<Tooltip>In Person</Tooltip>}>
                        <i className="ri-building-4-line me-1" aria-label="ri-building-4-line"></i>
                     </OverlayTrigger>
                  )}
               </span>
            </div>
         </>
      );
   }, []);

   // function handleViewDidMount(view) {
   //    const currentView = view;
   //    if (currentView.type === "dayGridMonth") {
   //       const endDate = new Date(currentView.currentEnd.getTime() - 1 * (1000 * 60 * 60 * 24));
   //       setCurrentMonth(moment(endDate).format("YYYY-MM-DD"));
   //    }
   // }

   useEffect(() => {
      console.log("Home.js appointments: ", appointments);
   }, [appointments]);

   return (
      <>
         <Header onSkin={setSkin} />

         <div className="main main-app p-3 p-lg-4">
            <div className="d-md-flex align-items-center justify-content-between mb-4">
               <div>
                  <h4 className="main-title mb-0">Appointments</h4>
               </div>
            </div>
            <div className="calendar-body">
               <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interaction]}
                  initialView="dayGridMonth"
                  views={{
                     dayGridMonth: {
                        type: "dayGridMonth",
                        buttonText: "Month",
                     },
                     timeGridWeek: {
                        type: "timeGridWeek",
                        buttonText: "Week",
                     },
                     timeGridDay: {
                        type: "timeGridDay",
                        buttonText: "Day",
                     },
                     listWeek: {
                        type: "listWeek",
                        buttonText: "List Week",
                     },
                  }}
                  // viewDidMount={handleViewDidMount}
                  // viewDidChange={handleViewDidChange}
                  events={appointments}
                  eventClick={handleEventClick}
                  dateClick={handleEventClick}
                  //onClose={handleAppointmentModalClose}
                  headerToolbar={{
                     left: "prev,today",
                     center: "title",
                     right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek,next",
                  }}
                  datesSet={handleDatesSet}
                  selectable={true}
                  timeZone="UTC"
                  eventContent={customEventContent}
               />
            </div>
            {showAppointmentModal && (
               <AppointmentModal
                  appointmentId={selectedAppointmentId}
                  appointmentTitle={selectedAppointmentTitle}
                  appointmentStartDate={selectedAppointmentStartDate}
                  eData={selectedAppointment}
                  eMode={selectedMode}
                  onCloseModal={handleAppointmentModalClose}
                  skin={skin}
               />
            )}

            <Footer />
         </div>
      </>
   );
}
