import { useState, useContext, useEffect, useRef, useReducer } from "react";
import { Offcanvas } from "react-bootstrap";
//import AppointmentForm from "../forms/AppointmentForm";
import TreatmentNoteForm from "../forms/treatmentnoteform";

import ClientContactView from "../views/ClientContactView";
import ClientReferralView from "../views/ClientReferralView";
import ClientEmploymentView from "../views/ClientEmploymentView";
import ClientDoctorView from "../views/ClientDoctorView";
import ClientDiagnosisView from "../views/ClientDiagnosisView";
import SecondaryTreatmentsView from "../views/SecondaryTreatmentsView";
import ClientMedicationsView from "../views/ClientMedicationsView";

import useFetchData from "../components/useFetchDatav3";
import { useToast } from "../context/useToast.js";

import { ModalFormContext } from "../context/ModalFormContext"; // useContext dirty

//const ClientModal = ({ clientId, clientName, category, treatmentId, treatmentName, eData, action, eMode, onCloseModal, skin }) => {
const ClientModal = ({ clientId, clientName, category, treatmentId, treatmentName, eData, action, eMode, skin }) => {
   console.log("CLIENTMODAL:", clientId, treatmentId);
   //const { isOpen, handleBackdropClick, handleClose } = useContext(ModalFormContext);
   const { isOpen, isFormDirty, handleFormChange, handleClose, resetFormDirtyState } = useContext(ModalFormContext);

   console.log("MODALFORMPROVIDER:", isOpen);
   const [editMode, setEditMode] = useState(false);
   const [show, setShow] = useState(true);
   // We use this as a flag to determine if the offcanvas is in the procsess of closing due to user interaction (clickingclose button or backdrop)
   const isExitingRef = useRef(false);

   const [payloadClient, setPayloadClient] = useState(null);
   const [isLoadingClient, setIsLoadingClient] = useState(true);
   const { triggerToast } = useToast();
   //for fetch
   const { fetchData } = useFetchData();
   // We use useReducer because it can manage more complex state logic

   // Fetch Client
   useEffect(() => {
      // this useEffect sets initial state to false for other treatment tabs
      const getClient = async () => {
         console.log("GETCLIENTDATA:", clientId, treatmentId);
         const params = {
            cs_id: treatmentId,
            client_id: clientId,
         };
         const url = "/getClientDetails.sz";
         const method = "GET";
         const body = { params: JSON.stringify(params) };
         const contentType = "application/json";
         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
            setIsLoadingClient(false);
            if (responseData.st === 1) {
               if (responseData?.payload?.clients?.length > 0) {
                  setPayloadClient(responseData?.payload?.clients[0]);
                  console.log("CLIENTMODAL PAYLOAD:", payloadClient);
               }
            } else {
               if (responseData.m_en) {
                  setIsLoadingClient(false);
                  //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
                  triggerToast("warning", responseData.m_en, false, "", false);
               } else {
                  setIsLoadingClient(false);
                  triggerToast("error", "We seem to have an issue retrieving client detail, please try again later.", true, "Issue", false, 0);
               }
            }
         } catch (error) {
            setIsLoadingClient(false);
            triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         }
      };
      getClient();
   }, [clientId, treatmentId]);

   const editButtonClick = () => {
      setEditMode(true);
   };

   const onClose = (e) => {
      setShow(false);
      //onCloseModal?.();
      handleClose();
   };

   const resetEdit = () => {
      setEditMode(false);
   };
   // the eMode manages if the AppointmentForm component is existing appointment or a new appointment
   // eMode is a prop passed from the Home page which has the Calendar component. If we click on an appointment its edit otherwise its new
   // we set the show variable to true
   useEffect(() => {
      if (eMode === "edit" || eMode === "new") {
         setShow(true);
      } else {
         setShow(false);
      }
   }, [eMode]);

   return (
      <>
         {console.log("DATA:", category, isLoadingClient, payloadClient, editMode)}
         <Offcanvas show={isOpen} onHide={onClose} onExited={resetEdit} placement="end" className="offcanvas-width-xl">
            <Offcanvas.Header closeButton className="pb-0" />
            <Offcanvas.Body className="pt-0" style={{ maxHeight: "calc(100vh - 3.5rem)", overflowY: "auto" }}>
               {payloadClient && !isExitingRef.current ? (
                  editMode ? (
                     <>
                        {category === "Contact Details" && (
                           <ClientContactView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                              skin={skin}
                           />
                        )}
                     </>
                  ) : action === "Add Note" || action === "Update Note" ? (
                     <>
                        <TreatmentNoteForm
                           clientId={clientId}
                           clientName={clientName}
                           treatmentId={treatmentId}
                           treatmentName={treatmentName}
                           eData={eData}
                           action={action}
                           eMode={eMode}
                           skin={skin}
                        />
                     </>
                  ) : (
                     <>
                        {category === "Contact Details" && (
                           <ClientContactView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Referral Details" && (
                           <ClientReferralView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Employment Details" && (
                           <ClientEmploymentView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Doctor Details" && (
                           <ClientDoctorView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Diagnosis" && (
                           <ClientDiagnosisView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Secondary Treatments" && (
                           <SecondaryTreatmentsView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                        {category === "Medications" && (
                           <ClientMedicationsView
                              clientId={clientId}
                              clientName={clientName}
                              category={category}
                              eData={payloadClient}
                              eMode={eMode}
                              isEdit={editMode}
                              onEditButtonClick={editButtonClick}
                           />
                        )}
                     </>
                  )
               ) : //<AppointmentView clientId={clientId} clientName={clientName} eData={eData} eMode={eMode} onEditButtonClick={editButtonClick} />
               !isExitingRef.current ? (
                  <>
                     <h5>No client available.</h5>
                  </>
               ) : null}
            </Offcanvas.Body>
         </Offcanvas>
      </>
   );
};

export default ClientModal;
