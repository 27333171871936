import React, { useContext, useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Card, Col, Nav, Table, Dropdown, ProgressBar, Row, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import InitialsAvatar from "../components/InitialsAvatar";
import StatusBadge from "../components/StatusBadge";
import ClientModal from "../components/ClientModal";
import { NavLink } from "react-router-dom";
import useFetchData from "../components/useFetchDatav3";
import { useToast } from "../context/useToast.js";
import { useAuth } from "../context/authContext.js";
import ModalFormContextProvider, { ModalFormContext } from "../context/ModalFormContext"; // useContext dirty

export default function Clients() {
   return (
      <ModalFormContextProvider>
         <ClientContent />
      </ModalFormContextProvider>
   );
}

function ClientContent() {
   const { isOpen, handleOpen } = useContext(ModalFormContext);
   const [selectedClientId, setClientId] = useState("");
   const [selectedClientName, setClientName] = useState("");
   const [selectedCategory, setCategory] = useState("");
   const [selectedTreatmentId, setTreatmentId] = useState("");
   const [selectedTreatmentName, setTreatmentName] = useState("");
   const [selectedAction, setAction] = useState("");
   const [selectedMode, setSelectedMode] = useState(null);
   const [gridHovered, setGridHovered] = useState(false);
   const { clientCardView, setClientCardView } = useAuth();
   // variable that determins in client modal is displayed
   const [showClientModal, setShowClientModal] = useState(false);
   const [payloadClients, setPayloadClients] = useState([]);
   const { fetchData } = useFetchData();
   const { triggerToast } = useToast();
   useEffect(() => {
      document.body.classList.add("app-task");
      return () => {
         document.body.classList.remove("app-task");
      };
   }, []);
   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
   const [skin, setSkin] = useState(currentSkin);
   const switchSkin = (skin) => {
      if (skin === "dark") {
         const btnWhite = document.getElementsByClassName("btn-white");

         for (const btn of btnWhite) {
            btn.classList.add("btn-outline-primary");
            btn.classList.remove("btn-white");
         }
      } else {
         const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

         for (const btn of btnOutlinePrimary) {
            btn.classList.remove("btn-outline-primary");
            btn.classList.add("btn-white");
         }
      }
   };
   switchSkin(skin);
   useEffect(() => {
      switchSkin(skin);
   }, [skin]);
   // click event to launch Offcanvas
   const handleDropdownItemClick = ({ id, name, category, treatmentid, treatmentname, action }) => {
      // {clientId, clientName, category, treatmentId, treatmentName, eData, action, eMode, onCloseModal, skin}
      setClientId(id);
      setClientName(name);
      if (action) {
         setTreatmentId(treatmentid);
         setTreatmentName(treatmentname);
         setCategory(category);
         setAction(action);
         setSelectedMode("edit");
      } else {
         setCategory(category);
         setSelectedMode("edit");
      }
      //launches the Offcanvas
      setShowClientModal(true);
      handleOpen();
   };

   //Example only Will apply a fetch to get all clients
   const handleGridMouseEnter = () => setGridHovered(true);
   const handleGridMouseLeave = () => setGridHovered(false);
   //fetch clients
   useEffect(() => {
      // this useEffect sets initial state to false for other treatment tabs
      const getClients = async () => {
         const params = {
            case_service_status: 1,
            sort_by: clientCardView.sort.sort_field,
            sort_by_dir: clientCardView.sort.sort_dir,
         };
         const url = "/getClients.sz";
         const method = "GET";
         const body = { params: JSON.stringify(params) };
         const contentType = "application/json";
         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);

            if (responseData.st === 1) {
               //setPayloadClients(responseData.payload?.clients);
               //setSortBy({ property: clientCardView.client_sort, order: clientCardView.client_sort_dir });
               //setPayloadClients(sortByClients(responseData.payload?.clients, clientCardView.client_sort, clientCardView.client_sort_dir));

               // data set returned has been sorted
               setPayloadClients(responseData.payload?.clients);
               // Sort options
               // payloadClients.sort((a,b) => a.cs_open_date - b.cs_open_date);
               // payloadClients.sort((a, b) => a.ca_ident - b.ca_ident);
               // payloadClients.sort((a, b) => a.s_name - b.s_name);
               // payloadClients.sort((a, b) => b.cs_open_date(a.cs_open_date));
               // payloadClients.sort((a, b) => b.ca_ident.localeCompare(a.ca_ident));
               // payloadClients.sort((a, b) => b.s_name.localeCompare(b.s_name));
            } else {
               if (responseData.m_en) {
                  triggerToast("warning", responseData.m_en, false, "", false);
               } else {
                  triggerToast("error", "We seem to have an issue retrieving clients, please try again later.", true, "Issue", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         }
      };
      getClients();
   }, []);
   const chunkArray = (array, size) => {
      const chunkedArray = [];
      for (let i = 0; i < array.length; i += size) {
         chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
   };
   const cardChunks = chunkArray(payloadClients, 2);
   const handleSortClick = (field, field_type) => {
      setClientCardView({
         ...clientCardView,
         sort: { ...clientCardView.sort, sort_field: field, sort_dir: clientCardView.sort.sort_dir === "asc" ? "desc" : "asc" },
      });

      setPayloadClients(sortByClients(payloadClients, field, field_type, clientCardView.sort.sort_dir));
   };
   const handleViewClick = (newView) => {
      setClientCardView({
         ...clientCardView,
         view: newView,
      });
   };
   const ListGridToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
         href=""
         ref={ref}
         onClick={(e) => {
            e.preventDefault();
            onClick(e);
         }}>
         {children}
      </a>
   ));
   const ClientCard = ({ cardChunks }) => (
      <Row className="row-cols-auto g-4 g-xxl-5">
         <Col xl>
            {cardChunks.map((chunk, index) => (
               <Row key={index} className="g-3 g-lg-4 mb-4">
                  {chunk.map((client, idx) => (
                     <Col key={idx} md={6}>
                        <Card className="card-task">
                           <Card.Body className="p-3 pb-1">
                              <div className="d-flex align-items-center justify-content-between">
                                 <div className="d-flex align-items-center">
                                    <InitialsAvatar name={client.c_client_name} />
                                    <div className="ms-2">
                                       <Card.Title as="h6">{client.c_client_name}</Card.Title>
                                       <span className="fs-sm mb-0">{client.s_name}</span>
                                    </div>
                                 </div>
                                 <div className="col text-end">
                                    <div className="col-auto">
                                       <span className="text-muted me-1">Case no:</span>
                                       <span className="h6">{client.ca_ident}</span>
                                    </div>
                                    <div className="col-auto">
                                       <span className="text-muted me-1 fs-xs">Start on:</span>
                                       <span className="h6 fs-xs">{client.cs_open_date}</span>
                                    </div>
                                    <div className="col-auto">
                                       <StatusBadge name={client.cs_s_name} bg={client.cs_s_name === "Open" ? "success" : "warning"} />
                                    </div>
                                 </div>
                              </div>
                              <div className="container ps-0 pe-0 mt-3">
                                 <div className="row align-items-center">
                                    <div className="col">
                                       <p className="fs-xs mb-1">
                                          Account Manager: <span className="h6">{client.account_manager_name}</span>
                                       </p>
                                       <p className="fs-xs mb-1">
                                          C.O.D: <span className="h6">{client.ca_change_definition}</span>
                                       </p>
                                       <p className="fs-xs mb-1">
                                          D.O.D: <span className="h6">{client.ca_date_disability}</span>
                                       </p>
                                    </div>
                                    <div className="col-auto">
                                       <div className="row justify-content-center">
                                          <div className="col-auto">
                                             <span className="fs-xs">Sessions</span>
                                          </div>
                                       </div>
                                       <div className="row justify-content-end">
                                          <div className="col-auto text-muted d-flex flex-column align-items-center">
                                             <span className="fs-xs">Booked</span>
                                             <span className="fw-medium h6">{client.booked}</span>
                                          </div>
                                          <div className="col-auto text-muted d-flex flex-column align-items-center ms-2">
                                             <span className="fs-xs">Completed</span>
                                             <span className="fw-medium h6">{client.completed}</span>
                                          </div>
                                          <div className="col-auto text-muted d-flex flex-column align-items-center ms-2">
                                             <span className="fs-xs">Allocated</span>
                                             <span className="fw-medium h6">{client.allocated}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between fs-xs text-secondary mt-3 mb-1">
                                 <span>
                                    <strong className="fw-medium">{client.percent_completed}%</strong> case complete
                                 </span>
                              </div>
                              <ProgressBar now={client.percent_completed} className="mb-2" />
                           </Card.Body>
                           <Card.Footer className="p-3 pt-0">
                              <div className="d-flex align-items-center justify-content-between fs-xs text-secondary mb-1">
                                 <div>
                                    <Nav>
                                       <NavDropdown title="Client Details" id="nav-dropdown">
                                          {/* The meat for the offcanvas */}
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Contact Details",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "View Contacts",
                                                })
                                             }>
                                             Contact Details
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Referral Details",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "Referral",
                                                })
                                             }>
                                             Referral
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Employment Details",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "Employment",
                                                })
                                             }>
                                             Employment
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Doctor Details",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "doctor",
                                                })
                                             }>
                                             Doctor
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Diagnosis",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "diagnosis",
                                                })
                                             }>
                                             Diagnosis
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Secondary Treatments",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "secondary",
                                                })
                                             }>
                                             Secondary Treatments
                                          </NavDropdown.Item>
                                          <NavDropdown.Item
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Medications",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "medications",
                                                })
                                             }>
                                             Medications
                                          </NavDropdown.Item>
                                       </NavDropdown>
                                       <span className="ms-1 me-1">|</span>
                                       <Nav.Item>
                                          <NavLink to="/apps/notes" state={{ cid: client.ca_external_id, tid: client.cs_external_id }}>
                                             View Notes
                                          </NavLink>
                                       </Nav.Item>
                                       <span className="ms-1 me-1">|</span>
                                       <Nav.Item>
                                          <Nav.Link
                                             href=""
                                             onClick={() =>
                                                handleDropdownItemClick({
                                                   id: client.c_external_id,
                                                   name: client.c_client_name,
                                                   category: "Add Note",
                                                   treatmentid: client.cs_external_id,
                                                   treatmentname: client.s_name,
                                                   action: "Add Note",
                                                })
                                             }>
                                             Add Note
                                          </Nav.Link>
                                       </Nav.Item>
                                    </Nav>
                                 </div>
                              </div>
                              <div>
                                 <Nav>
                                    <Nav.Item>
                                       <Nav.Link href="">
                                          <span className="d-flex align-items-center position-relative">
                                             {client.reminders > 0 ? (
                                                <>
                                                   <i className="ri-notification-3-line ms-2" style={{ fontSize: "20px" }}></i>
                                                   <small
                                                      className="rounded-pill bg-danger text-white px-1 py-0 position-absolute"
                                                      style={{ top: "-5px", right: "-5px" }}>
                                                      {client.reminders}
                                                   </small>
                                                </>
                                             ) : (
                                                <i className="ri-notification-off-line ms-2" style={{ fontSize: "20px" }}></i>
                                             )}
                                          </span>
                                       </Nav.Link>
                                    </Nav.Item>
                                 </Nav>
                              </div>
                           </Card.Footer>
                        </Card>
                     </Col>
                  ))}
               </Row>
            ))}
         </Col>
      </Row>
   );
   const ClientList = ({ payloadClients }) => (
      <Container fluid>
         <Table className="table-hover mb-0">
            <thead>
               <tr>
                  <th scope="col">Case</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Treatment</th>
                  <th scope="col" className="d-none d-sm-table-cell">
                     Started On
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col" className="d-none d-xl-table-cell">
                     Booked
                  </th>
                  <th scope="col" className="d-none d-xl-table-cell">
                     Allocated
                  </th>
                  <th scope="col" className="d-none d-xl-table-cell">
                     Completed
                  </th>
                  <th scope="col" className="d-none d-xl-table-cell">
                     C.O.D.
                  </th>
                  <th scope="col" className="d-none d-xxl-table-cell">
                     Account Manager
                  </th>
                  <th scope="col" className="fs-7 text-end">
                     Action
                  </th>
               </tr>
            </thead>
            <tbody>
               {payloadClients.map((client, idx) => (
                  <tr key={idx}>
                     <td>{client.ca_ident}</td>
                     <td>{client.c_client_name}</td>
                     <td>{client.s_name}</td>
                     <td className="d-none d-sm-table-cell">{client.cs_open_date}</td>
                     <td>
                        <StatusBadge name={client.cs_s_name} bg={client.cs_s_name === "Open" ? "success" : "warning"} />
                     </td>
                     <td className="d-none d-xl-table-cell">{client.booked}</td>
                     <td className="d-none d-xl-table-cell">{client.completed}</td>
                     <td className="d-none d-xl-table-cell">{client.allocated}</td>
                     <td className="d-none d-xl-table-cell">{client.ca_change_definition}</td>
                     <td className="d-none d-xxl-table-cell">{client.account_manager_name}</td>
                     <td className="text-end">
                        <Dropdown align="end" className="dropdown-file">
                           <Dropdown.Toggle as={ListGridToggle}>
                              <i className="fw-bold ri-more-2-fill"></i>
                           </Dropdown.Toggle>
                           <Dropdown.Menu>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Contact Details",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "View Contacts",
                                    })
                                 }>
                                 Contact Details
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Referral Details",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "Referral",
                                    })
                                 }>
                                 Referral
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Employment Details",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "Employment",
                                    })
                                 }>
                                 Employment
                              </Dropdown.Item>
                              <NavDropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Doctor Details",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "Doctor",
                                    })
                                 }>
                                 Doctor
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Diagnosis",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "diagnosis",
                                    })
                                 }>
                                 Diagnosis
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Secondary Treatment",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "secondary",
                                    })
                                 }>
                                 Secondary Treatments
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Medications",
                                       treatmentid: client.cs_external_id,
                                       treatmentname: client.s_name,
                                       action: "medications",
                                    })
                                 }>
                                 Medications
                              </NavDropdown.Item>

                              <Dropdown.Divider />
                              <Dropdown.Item
                                 as={NavLink}
                                 to="/apps/notes"
                                 state={{
                                    cid: client.ca_external_id,
                                    tid: client.cs_external_id,
                                 }}>
                                 View Notes
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: client.c_external_id,
                                       name: client.c_client_name,
                                       category: "Add Note",
                                       treatmentid: client.cs_treatment_id,
                                       treatmentname: client.s_name,
                                       action: "Add Note",
                                    })
                                 }>
                                 Add Note
                              </Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </td>
                  </tr>
               ))}
            </tbody>
         </Table>
      </Container>
   );
   function sortByClients(data, field, field_type, order = "asc") {
      if (!data || !field) {
         return data; // Return original client data if invalid arguments
      }
      const sortFunction = (a, b) => {
         let comparison;
         // Handle sorting by different data types
         if (field_type === "string" || field_type === "dateField") {
            comparison = a[field].localeCompare(b[field]);
         } else if (field_type === "number") {
            comparison = a[field] - b[field];
         } else {
            console.warn("Unsupported data type for sorting:", field_type);
            comparison = 0; // Default to no change
         }
         // Apply ascending or descending order based on the 'order' parameter
         return order === "asc" ? comparison : -comparison;
      };
      return data.slice().sort(sortFunction); // Sort a copy of the data
   }

   return (
      <>
         <Header onSkin={setSkin} />
         <div className="main main-app p-3 p-lg-4 p-xxl-5">
            <Row className="row-cols-auto g-4 g-xxl-5">
               <Col xl>
                  <h2 className="main-title fs-4 pb-3">Clients</h2>
               </Col>
            </Row>
            <div className="d-flex align-content-center justify-content-between mb-4">
               <div>
                  <h5 className="mb-0">Sort</h5>
                  <button
                     key="sort-recent"
                     type="button"
                     className={`btn btn-sm btn-outline-dark rounded-pill ps-2 pb-0 pe-2 pt-0 fs-7 mt-3 position-relative me-3 ${
                        clientCardView.sort.sort_field === "cs_open_date" && "active"
                     }`}
                     onClick={() => handleSortClick("cs_open_date", "dateField")}>
                     <span className="d-flex align-items-center">
                        Recent Activity{" "}
                        {clientCardView.sort.sort_field === "cs_open_date" ? (
                           clientCardView.sort.sort_dir === "asc" ? (
                              <i className="ms-1 fs-7 ri-arrow-down-line"></i>
                           ) : (
                              <i className="ms-1 fs-7 ri-arrow-up-line"></i>
                           )
                        ) : (
                           ""
                        )}
                     </span>
                  </button>
                  <button
                     key="sort-case-id"
                     type="button"
                     className={`btn btn-sm btn-outline-dark rounded-pill ps-2 pb-0 pe-2 pt-0 fs-7 mt-3 position-relative me-3 ${
                        clientCardView.sort.sort_field === "ca_ident" && "active"
                     }`}
                     onClick={() => handleSortClick("ca_ident", "string")}>
                     <span className="d-flex align-items-center">
                        Case Number{" "}
                        {clientCardView.sort.sort_field === "ca_ident" ? (
                           clientCardView.sort.sort_dir === "asc" ? (
                              <i className="ms-1 fs-7 ri-arrow-down-line"></i>
                           ) : (
                              <i className="ms-1 fs-7 ri-arrow-up-line"></i>
                           )
                        ) : (
                           ""
                        )}
                     </span>
                  </button>
                  <button
                     key="sort-treatment"
                     type="button"
                     className={`btn btn-sm btn-outline-dark rounded-pill ps-2 pb-0 pe-2 pt-0 fs-7 mt-3 position-relative me-3 ${
                        clientCardView.sort.sort_field === "s_name" && "active"
                     }`}
                     onClick={() => handleSortClick("s_name", "string")}>
                     <span className="d-flex align-items-center">
                        Treatment{" "}
                        {clientCardView.sort.sort_field === "s_name" ? (
                           clientCardView.sort.sort_dir === "asc" ? (
                              <i className="ms-1 fs-7 ri-arrow-down-line"></i>
                           ) : (
                              <i className="ms-1 fs-7 ri-arrow-up-line"></i>
                           )
                        ) : (
                           ""
                        )}
                     </span>
                  </button>
               </div>
               <div>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Client list view</Tooltip>}>
                     <button
                        key="filter-list"
                        type="button"
                        className="btn btn-link pe-1"
                        aria-disabled={clientCardView.view === "list" || clientCardView.view === ""}
                        onClick={() => handleViewClick("list")}>
                        <i className="fs-3 ri-menu-line"></i>
                     </button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="left" overlay={<Tooltip>Client card view</Tooltip>}>
                     <button
                        key="filter-card"
                        type="button"
                        className="btn btn-link ps-0"
                        onMouseEnter={handleGridMouseEnter}
                        onMouseLeave={handleGridMouseLeave}
                        aria-disabled={clientCardView.view === "card"}
                        onClick={() => handleViewClick("card")}>
                        <i className={`fs-3 ${gridHovered ? "ri-function-fill" : "ri-function-line"} `}></i>
                     </button>
                  </OverlayTrigger>
               </div>
            </div>

            {clientCardView.view === "card" && <ClientCard cardChunks={cardChunks} />}
            {(clientCardView.view === "list" || clientCardView.view === "") && <ClientList payloadClients={payloadClients} />}

            <Footer />
         </div>
         {showClientModal && (
            <ClientModal
               clientId={selectedClientId}
               clientName={selectedClientName}
               category={selectedCategory}
               treatmentId={selectedTreatmentId}
               treatmentName={selectedTreatmentName}
               eData={null}
               action={selectedAction}
               // eMode is a prop that lets us know should we have an edit button. Remember the user can be ready only.
               eMode={selectedMode}
               // onCloseModal={handleClientModalClose}
               skin={skin}
            />
         )}
      </>
   );
}
