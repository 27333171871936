import { useState, useEffect } from "react";
import { Button, Form, Col, Row, Container } from "react-bootstrap";
import AppointmentModalHeader from "../components/AppointmentModalHeader";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import moment from "moment";
import { APPOINTMENT_STATUS_OPTIONS, APPOINTMENT_LOCATION_OPTIONS, APPOINTMENT_DURATION_OPTIONS } from "../constants/appointmentConstants";

const AppointmentView = ({ appointmentStartDate, eData, eMode, onEditButtonClick }) => {
   // const initialFormData = {
   //    appointmentStatus: appointmentData.appointmentstatus,
   //    appointmentLocation: appointmentData.location_id,
   //    appointmentDate: appointmentStart,
   //    appointmentDuration: appointmentData.sessionduration,
   //    appointmentTreatment: appointmentData.treatmentname,
   //    appointmentClientName: appointmentData.clientname,
   //    appointmentClientId: appointmentData.clientid,
   //    appointmentRepeat: appointmentData.repeatConfig,
   //    appointmentHasNote: appointmentData.hasnote,
   //    appointmentStatus: appointmentData.sessionstatus,
   //    appointmentSplitEnabled: appointmentData.treatment_split_session_enabled,
   //    appointmentSplitActive: appointmentData.treatment_split_session_active,
   //    appointmentSplitCount: appointmentData.treatment_split_session_count,
   //    appointmentSplitNo: appointmentData.split_link_no,
   //    appointmentSplitId: appointmentData.other_split_id,
   //    appointmentSplitAltDate: appointmentData.other_split_apt_date,
   //    appointmentClientEmail: appointmentData.client_email,
   //    appointmentClientType: appointmentData.client_email_type,
   //    appointmentEmailComment: appointmentData.client_email_comment,
   //    appointmentEmailReminder: appointmentData.client_appointment_reminder,
   //    appointmentClientPhone: appointmentData.client_phone,
   //    appointmentClientPhoneType: appointmentData.client_phone_type,
   //    appointmentClientPhoneComment: appointmentData.client_phone_comment,
   //    appointmentClientPhoneMessage: appointmentData.client_phone_message,
   //    appointmentClientPhoneReminder: appointmentData.client_appointment_reminder,
   //    appointmentIsLocked: appointmentData.islocked,
   //    appointmentNoofSessions: appointmentData.noofsessions,
   // };

   const navigate = useNavigate();

   const getStatusLabel = (statusValue) => {
      const option = APPOINTMENT_STATUS_OPTIONS.find((opt) => opt.value === statusValue);
      return option ? option.label : "Unknown";
   };

   const getLocationLabel = (locationValue) => {
      const option = APPOINTMENT_LOCATION_OPTIONS.find((opt) => opt.value === locationValue);
      return option ? option.label : "Unknown";
   };

   const getSessionDurationLabel = (sessionDurationValue) => {
      const option = APPOINTMENT_DURATION_OPTIONS.find((opt) => opt.value === sessionDurationValue);
      return option ? option.label : "Unknown";
   };

   const [contentReady, setContentReady] = useState(false);

   useEffect(() => {
      const timer = setTimeout(() => {
         setContentReady(true);
      }, 1500);
      return () => clearTimeout(timer);
   }, []);

   const handleButtonClick = ({ cid, tid }) => {
      navigate("/apps/notes", { state: { cid: "bab0e0f1-2a36-4245-a57c-e6339ab7cc10", tid: "368c66b9-9593-4839-b789-59e6ea492efd" } });
   };

   return (
      <>
         <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Row>
               <Col className="event-body ps-3 pe-3">
                  <AppointmentModalHeader eData={eData} appDate={appointmentStartDate} eMode={eMode} />
               </Col>
            </Row>
            <Row style={{ flex: "1", overflowY: "auto" }}>
               <Col>
                  <Form id="appointmentForm">
                     <div className="card-bc card border-0">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Appointment Details'" }}>
                           <Row>
                              <Col xs={12} md={6}>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Form.Label>Status</Form.Label>
                                       {contentReady ? (
                                          <div className="">{getStatusLabel(eData.appointmentstatus)}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Form.Label>Client</Form.Label>
                                       {contentReady ? (
                                          <div className="">{eData ? eData.clientname : "Unknown"}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Form.Label>Treatment</Form.Label>
                                       {contentReady ? (
                                          <div className="">{eData ? eData.treatmentname : "Unknown"}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Form.Label>Location</Form.Label>
                                       {contentReady ? (
                                          <div className="">{getLocationLabel(eData.location_id)}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                              </Col>
                              <Col xs={12} md={6}>
                                 <Row className="g-0 mt-3">
                                    <Col sm={5} className="pe-4">
                                       <Form.Label>Date</Form.Label>
                                       {contentReady ? (
                                          <div className="">{eData ? moment(eData.start).format("YYYY-MM-DD") : ""}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col sm={4} className="pe-4">
                                       <Form.Label>Time</Form.Label>
                                       {contentReady ? (
                                          <div className="">{eData ? moment(eData.start).format("hh:mm a") : ""}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col sm>
                                       <Form.Label>Duration</Form.Label>
                                       {contentReady ? (
                                          <div className="">{getSessionDurationLabel(eData.sessionduration)}</div>
                                       ) : (
                                          <div className="placeholder-glow">
                                             <span className="placeholder col-12"></span>
                                          </div>
                                       )}
                                    </Col>
                                 </Row>
                              </Col>
                           </Row>
                        </div>
                     </div>
                     <div className="card-bc card border-0">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Appointment Schedule Options'" }}>
                           <Row className="g-0 mt-2">
                              <Col>
                                 {contentReady ? (
                                    eData && eData["repeat"] ? (
                                       <>
                                          <Form.Label>Repeat</Form.Label>
                                          <div className="">{eData.repeat["repeat-text"]}</div>
                                       </>
                                    ) : (
                                       <div className="">No schedule repeats</div>
                                    )
                                 ) : (
                                    <>
                                       <div className="placeholder-glow">
                                          <span className="placeholder col-12"></span>
                                       </div>
                                       <div className="placeholder-glow">
                                          <span className="placeholder col-12"></span>
                                       </div>
                                    </>
                                 )}
                              </Col>
                           </Row>
                        </div>
                     </div>
                     <div className="card-bc card border-0">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Session Notes'" }}>
                           <Row className="g-0 mt-0 align-items-center">
                              <Col className="d-flex justify-content-between">
                                 <Button
                                    onClick={() =>
                                       handleButtonClick({
                                          cid: eData.caseid,
                                          tid: eData.treatmentid,
                                       })
                                    }
                                    variant="outline-secondary"
                                    style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }}>
                                    {" "}
                                    View All Case Notes
                                 </Button>
                                 <Button
                                    variant="outline-secondary"
                                    style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }}>
                                    Create Session Note
                                 </Button>
                              </Col>
                           </Row>
                        </div>
                     </div>
                  </Form>
               </Col>
            </Row>
            <Row className="g-0 mt-4 ">
               <Col className="d-flex justify-content-between">
                  <div></div>

                  <Button variant="outline-secondary" size="lg" onClick={onEditButtonClick} className="rounded-pill">
                     Edit Appointment
                  </Button>
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default AppointmentView;
