import React, { useContext, useState, useEffect } from "react";
import ChildTest from "../components/ChildTest";
import ModalFormContextProvider, { ModalFormContext } from "../context/ModalFormContext"; // useContext dirty
import { Col, Row, Button } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function ParentTest() {
   return (
      <ModalFormContextProvider>
         <ParentContent />
      </ModalFormContextProvider>
   );
}

function ParentContent() {
   const { isOpen, handleOpen } = useContext(ModalFormContext);
   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
   const [skin, setSkin] = useState(currentSkin);
   const switchSkin = (skin) => {
      if (skin === "dark") {
         const btnWhite = document.getElementsByClassName("btn-white");

         for (const btn of btnWhite) {
            btn.classList.add("btn-outline-primary");
            btn.classList.remove("btn-white");
         }
      } else {
         const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

         for (const btn of btnOutlinePrimary) {
            btn.classList.remove("btn-outline-primary");
            btn.classList.add("btn-white");
         }
      }
   };
   switchSkin(skin);
   useEffect(() => {
      switchSkin(skin);
   }, [skin]);
   return (
      <>
         <Header onSkin={setSkin} />
         <div className="main main-app p-3 p-lg-4 p-xxl-5">
            <Row className="row-cols-auto g-4 g-xxl-5">
               <Col xl>
                  <h2 className="main-title fs-4 pb-3">Clients</h2>
                  <Button onClick={handleOpen}>Open ChildTest</Button>
                  <ChildTest />
               </Col>
            </Row>
         </div>
         <Footer />
      </>
   );
}
