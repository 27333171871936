// useFetchData.js
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useFetchData = () => {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState("");
   const navigate = useNavigate();

   const fetchData = async (url, method = "GET", body = null, contentType = "application/json") => {
      if (!navigator.onLine) {
         setError("Network connection is lost");
         return { responseData: null, fetchLoading: false, fetchError: "Network connection is lost" };
      }

      setLoading(true);
      // const controller = new AbortController();
      // const signal = controller.signal;

      let finalUrl = url;
      let urlPostParams;
      if (method === "GET" && typeof body === "object" && !Array.isArray(body)) {
         const queryString = buildQueryString(body);
         finalUrl = `${url}?${queryString}`;
      }

      if (method === "POST") {
         urlPostParams = new URLSearchParams(body);
         console.log("POST Values:", body);
         console.log("POST Values:", urlPostParams);
      }
      const requestOptions = {
         method,
         headers: {
            "Content-Type": contentType === "application/json" ? "application/json" : "application/x-www-form-urlencoded",
         },
         body: method === "POST" ? urlPostParams : undefined,
         credentials: "include",
      };

      try {
         const response = await fetch(finalUrl, requestOptions);
         if (!response.ok) {
            throw new Error("Failed to fetch data");
         }
         const result = await response.json();

         if (result.st === 0) {
            // Session expired, redirect to sign-in page
            navigate("/pages/signin3", { replace: true });
            return { responseData: null, fetchLoading: false, fetchError: "Session expired" };
         }

         setData(result);
         return { responseData: result, fetchLoading: false, fetchError: "" };
      } catch (error) {
         if (error.name === "AbortError") {
            console.warn("Fetch request aborted:", error); // Log for debugging
            return { responseData: null, fetchLoading: false, fetchError: "Fetch aborted" };
         } else {
            setError(error.message);
            return { responseData: null, fetchLoading: false, fetchError: error.message };
         }
      } finally {
         setLoading(false);
      }
   };

   function buildQueryString(params) {
      const queryString = new URLSearchParams();
      for (const key in params) {
         queryString.append(key, params[key]);
      }
      return queryString.toString();
   }

   useEffect(() => {
      const handleConnectionChange = () => {
         if (!navigator.onLine) {
            setError("Network connection is lost");
         }
      };

      window.addEventListener("offline", handleConnectionChange);
      window.addEventListener("online", handleConnectionChange);

      return () => {
         window.removeEventListener("offline", handleConnectionChange);
         window.removeEventListener("online", handleConnectionChange);
      };
   }, []);

   return { fetchData };
};

export default useFetchData;
