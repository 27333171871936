import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { useAuth } from "./context/authContext";
import PrivateRoute from "./routes/PrivateRoute";

import Signin3 from "./pages/Signin3";

// import css
import "./assets/css/remixicon.css";
// import scss
import "./scss/style.scss";
import "../src/styles/custom.styles.css";

// set skin on load
window.addEventListener("load", function () {
   let skinMode = localStorage.getItem("skin-mode");
   let HTMLTag = document.querySelector("html");

   if (skinMode) {
      HTMLTag.setAttribute("data-skin", skinMode);
   }
});

export default function App() {
   return (
      <>
         <Routes>
            <Route path="/" element={<Signin3 />} />

            <Route element={<PrivateRoute />}>
               {protectedRoutes.map((route, index) => {
                  return <Route path={route.path} element={route.link} key={index} />;
               })}
            </Route>

            {publicRoutes.map((route, index) => {
               return <Route path={route.path} element={route.link} key={index} />;
            })}

            <Route path="*" element={<NotFound />} />
         </Routes>
      </>
   );
}
