import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
   return (
      <div className="main-footer">
         <span>&copy; 2024. Breadcrumb Technology Inc. All Rights Reserved.</span>
         <span>
            Created by:{" "}
            <Link to="https://breadcrumtechnology.com" target="_blank">
               Breadcrumb Technologies Inc.
            </Link>
         </span>
      </div>
   );
}
