export const CLINICIAN_NOTE_TYPE = [
   { value: 7, label: "Confeence Call" },
   { value: 8, label: "Email" },
   { value: 9, label: "Telephone" },
];

export const EVENT_NOTE_TYPE = [
   { value: 1, label: "Accept case" },
   { value: 2, label: "Session treatment" },
   { value: 3, label: "Personel" },
   { value: 4, label: "Other" },
   { value: 5, label: "Administrative" },
   { value: 6, label: "Report" },
   { value: 7, label: "Conference" },
   { value: 8, label: "Email" },
   { value: 9, label: "Telephone" },
   { value: 10, label: "Intake" },
   { value: 11, label: "Other Services" },
   { value: 12, label: "Resource" },
   { value: 13, label: "Treatment Guidelines" },
   { value: 14, label: "Service Fee" },
   { value: 15, label: "Consent" },
];
