import { useEffect, useState, useMemo } from "react";
import { Offcanvas, Container, Row, Col, Button, Table, Modal } from "react-bootstrap";
import ModalHeader from "./DocumentModalHeader.js";
import { useToast } from "../context/useToast.js";
import useFetchData from "./useFetchDatav3.js";
import "./ReminderModal.style.css";
import moment from "moment";

const ReminderModal = ({ clientId, clientName, treatmentId, treatmentName, onCloseModal, skin }) => {
   const [showReminder, setReminderShow] = useState(true);
   const [selectedTID, setTID] = useState(null);
   const [payloadReminders, setPayloadReminders] = useState([]);
   const { triggerToast } = useToast();
   const { fetchData } = useFetchData();

   useEffect(() => {
      setReminderShow(true);
   }, []);

   useEffect(() => {
      setTID(treatmentId);
   }, [treatmentId]);

   const onClose = (e) => {
      setReminderShow(false);
      onCloseModal?.();
   };

   //fetch notes
   useEffect(() => {
      // this useEffect sets initial state to false for other treatment tabs
      const getReminders = async () => {
         // if (!selectedTID) {
         //    return; // Exit early if missing parameters
         // }
         const params = {
            // cs_id: selectedTID,
            orderby: [{ name: "rem_created_on", direction: "desc" }],
         };
         const url = "/getReminders.sz";
         const method = "POST";
         const body = { params: JSON.stringify(params) };
         const contentType = "application/x-www-form-urlencoded";
         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
            if (responseData.st === 1) {
               setPayloadReminders(responseData.payload?.reminders);
               if (responseData.payload?.reminders.length < 1) {
                  const firstRow = [{ rem_html: "No reminders found" }];
                  setPayloadReminders(firstRow);
               }
            } else {
               if (responseData.m_en) {
                  //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
                  triggerToast("warning", responseData.m_en, false, "", false);
               } else {
                  triggerToast("error", "We seem to have an issue retrieving reminders, please try again later.", true, "Issue", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         }
      };
      getReminders();
   }, [selectedTID]);
   // }, [selectedTID]);

   const ReminderList = ({ payloadReminders }) => (
      <Container fluid className="ps-0 pe-0 h-100">
         <div className="table-wrapper">
            <Table className="table-hover table-responsive mb-0">
               <thead>
                  <tr>
                     <th scope="col">Reminder</th>
                     <th scope="col">Created On</th>
                  </tr>
               </thead>
            </Table>
            <div className="table-body-wrapper">
               <Table className="table-hover table-responsive mb-0">
                  <tbody>
                     {payloadReminders.map((rems, idx) => (
                        <tr key={idx}>
                           <td>{rems.rem_text}</td>
                           <td>
                              {moment.utc(rems.rem_created_on).format("MMM DD, YYYY")}{" "}
                              {rems.rem_created_on && (
                                 <span className="ms-3 fs-5">
                                    <i className="ri-delete-bin-line"></i>
                                 </span>
                              )}
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </div>
      </Container>
   );

   return (
      <>
         <Offcanvas show={showReminder} onHide={onClose} placement="end" className="offcanvas-width-xl">
            <Offcanvas.Header closeButton className="pb-0" />
            <Offcanvas.Body className="pt-0" style={{ maxHeight: "calc(100vh - 3.5rem)", overflowY: "auto" }}>
               <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <Row>
                     <Col className="event-body ps-3 pe-3">
                        <ModalHeader title="Reminders" clientName={clientName} treatmentName={treatmentName} />
                     </Col>
                  </Row>
                  <Row style={{ flex: "1", overflowY: "auto" }}>
                     <section className="container mt-3">
                        <div className="mt-auto w-100 h-100 table-container">
                           <ReminderList payloadReminders={payloadReminders} />
                        </div>
                     </section>
                  </Row>
                  <Row className="g-0 mt-4 ">
                     <Col className="d-flex justify-content-start">
                        <Button variant="outline-secondary" size="lg" onClick={onClose} className="rounded-pill">
                           Cancel
                        </Button>
                     </Col>
                  </Row>
               </Container>
            </Offcanvas.Body>
         </Offcanvas>
      </>
   );
};

export default ReminderModal;
