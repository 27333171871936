import { useState, useEffect, useCallback } from "react";
import { deviceType, browserName, browserVersion, osName, osVersion } from "react-device-detect";

const useDeviceDetection = () => {
   const [deviceInfo, setDeviceInfo] = useState(null);
   const [error, setError] = useState(null);

   const getDeviceInfo = useCallback(() => {
      try {
         return {
            deviceType: deviceType,
            browserName: browserName,
            browserVersion: browserVersion,
            osName: osName,
            osVersion: osVersion,
         };
      } catch (error) {
         setError(error);
         return null; // Return null in case of error
      }
   }, []);

   useEffect(() => {
      const deviceData = getDeviceInfo();
      if (deviceData) {
         setDeviceInfo(deviceData);
      }
   }, [getDeviceInfo]); // Only re-run effect if getDeviceInfo changes

   return { deviceInfo, error };
};

export default useDeviceDetection;
