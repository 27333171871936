import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/img1.jpg";
import notification from "../data/Notification";
import { useAuth } from "../context/authContext";
import ReminderModal from "../components/ReminderModal";
import { Button } from "react-bootstrap";

export default function Header({ onSkin }) {
   const { currentUser } = useAuth();
   const [showReminderModal, setShowReminderModal] = useState(false);
   const [name, setName] = useState("");

   // State to track the current skin mode
   const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem("skin-mode") === "dark");
   // Function to toggle the skin mode
   const toggleSkinMode = () => {
      const newMode = isDarkMode ? "" : "dark";
      setIsDarkMode(!isDarkMode);
      const HTMLTag = document.querySelector("html");

      if (newMode === "dark") {
         HTMLTag.setAttribute("data-skin", newMode);
         localStorage.setItem("skin-mode", newMode);
         onSkin(newMode);
      } else {
         HTMLTag.removeAttribute("data-skin");
         localStorage.removeItem("skin-mode");
         onSkin("");
      }
   };
   useEffect(() => {
      // Apply the initial skin mode based on the state
      const HTMLTag = document.querySelector("html");
      if (isDarkMode) {
         HTMLTag.setAttribute("data-skin", "dark");
      } else {
         HTMLTag.removeAttribute("data-skin");
      }
   }, [isDarkMode]);

   useEffect(() => {
      setName((currentUser.customClaims.first_name?.trim() || "") + " " + (currentUser.customClaims.last_name?.trim() || ""));
   }, [currentUser.customClaims.first_name, currentUser.customClaims.last_name]);

   const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <Link
         to=""
         ref={ref}
         onClick={(e) => {
            e.preventDefault();
            onClick(e);
         }}
         className="dropdown-link">
         {children}
      </Link>
   ));

   const toggleSidebar = (e) => {
      e.preventDefault();
      let isOffset = document.body.classList.contains("sidebar-offset");
      if (isOffset) {
         document.body.classList.toggle("sidebar-show");
      } else {
         if (window.matchMedia("(max-width: 991px)").matches) {
            document.body.classList.toggle("sidebar-show");
         } else {
            document.body.classList.toggle("sidebar-hide");
         }
      }
   };

   function NotificationList() {
      const notiList = notification.map((item, key) => {
         return (
            <li className="list-group-item" key={key}>
               <div className={item.status === "online" ? "avatar online" : "avatar"}>{item.avatar}</div>
               <div className="list-group-body">
                  <p>{item.text}</p>
                  <span>{item.date}</span>
               </div>
            </li>
         );
      });

      return <ul className="list-group">{notiList}</ul>;
   }

   const skinMode = (e) => {
      e.preventDefault();
      e.target.classList.add("active");

      let node = e.target.parentNode.firstChild;
      while (node) {
         if (node !== e.target && node.nodeType === Node.ELEMENT_NODE) node.classList.remove("active");
         node = node.nextElementSibling || node.nextSibling;
      }

      let skin = e.target.textContent.toLowerCase();
      let HTMLTag = document.querySelector("html");

      if (skin === "dark") {
         HTMLTag.setAttribute("data-skin", skin);
         localStorage.setItem("skin-mode", skin);

         onSkin(skin);
      } else {
         HTMLTag.removeAttribute("data-skin");
         localStorage.removeItem("skin-mode");

         onSkin("");
      }
   };

   const sidebarSkin = (e) => {
      e.preventDefault();
      e.target.classList.add("active");

      let node = e.target.parentNode.firstChild;
      while (node) {
         if (node !== e.target && node.nodeType === Node.ELEMENT_NODE) node.classList.remove("active");
         node = node.nextElementSibling || node.nextSibling;
      }

      let skin = e.target.textContent.toLowerCase();
      let HTMLTag = document.querySelector("html");

      HTMLTag.removeAttribute("data-sidebar");

      if (skin !== "default") {
         HTMLTag.setAttribute("data-sidebar", skin);
         localStorage.setItem("sidebar-skin", skin);
      } else {
         localStorage.removeItem("sidebar-skin", skin);
      }
   };

   const handleAllReminders = () => {
      setShowReminderModal(true);
   };

   const handleReminderModalClose = () => {
      setTimeout(() => {
         setShowReminderModal(false);
      }, 300);
   };

   function InitialsAvatar({ name, className = "avatar-header avatar-rounded-xl", color = "secondary" }) {
      const initials = name ? generateInitials(name) : "";

      return (
         <div className={className}>
            <span className={`avatar-initial bg-${color}`}>{initials}</span>
         </div>
      );
   }

   function generateInitials(clientName) {
      if (!clientName) {
         return ""; // Return empty string for undefined or empty clientName
      }
      const words = clientName.split(" ");
      const firstName = words[0];
      const lastName = words[words.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      return initials.toUpperCase();
   }

   return (
      <>
         <div className="header-main px-3 px-lg-4">
            <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
               <i className="ri-menu-2-fill"></i>
            </Link>

            <div className="me-auto"></div>
            {/* <div className="form-search me-auto">
            <input type="text" className="form-control" placeholder="Search" />
            <i className="ri-search-line"></i>
         </div> */}

            <div className="skin-toggle" onClick={toggleSkinMode}>
               {isDarkMode ? <i className="fs-4 ri-sun-line"></i> : <i className="fs-4 ri-moon-line"></i>}
            </div>

            {/* <Dropdown className="dropdown-skin" align="end">
            <Dropdown.Toggle as={CustomToggle}>
               <i className="ri-settings-3-line"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-10-f">
               <label>Skin Mode</label>
               <nav className="nav nav-skin">
                  <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>
                     Light
                  </Link>
                  <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>
                     Dark
                  </Link>
               </nav>
               <hr />
               <label>Sidebar Skin</label>
               <nav id="sidebarSkin" className="nav nav-skin">
                  <Link onClick={sidebarSkin} className={!localStorage.getItem("sidebar-skin") ? "nav-link active" : "nav-link"}>
                     Default
                  </Link>
                  <Link onClick={sidebarSkin} className={localStorage.getItem("sidebar-skin") === "prime" ? "nav-link active" : "nav-link"}>
                     Prime
                  </Link>
                  <Link onClick={sidebarSkin} className={localStorage.getItem("sidebar-skin") === "dark" ? "nav-link active" : "nav-link"}>
                     Dark
                  </Link>
               </nav>
            </Dropdown.Menu>
         </Dropdown>  */}

            <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
               <Dropdown.Toggle as={CustomToggle}>
                  <small>1</small>
                  <i className="ri-notification-3-line"></i>
               </Dropdown.Toggle>
               <Dropdown.Menu className="mt-10-f me--10-f">
                  <div className="dropdown-menu-header">
                     <h6 className="dropdown-menu-title">Case Reminders</h6>
                  </div>
                  {NotificationList()}
                  <div className="dropdown-menu-footer">
                     <Link onClick={handleAllReminders}>Show all Reminders</Link>
                  </div>
               </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
               <Dropdown.Toggle as={CustomToggle}>
                  {/* <div className="avatar online">
                     <img src={userAvatar} alt="" />
                  </div> */}
                  <InitialsAvatar name={name} />
               </Dropdown.Toggle>
               <Dropdown.Menu className="mt-10-f">
                  <div className="dropdown-menu-body">
                     <div className="avatar avatar-xl online mb-3">
                        <img src={userAvatar} alt="" />
                     </div>
                     <h5 className="mb-1 text-dark fw-semibold">
                        {currentUser?.customClaims && currentUser.customClaims.first_name + " " + currentUser.customClaims.last_name}
                     </h5>
                     <p className="fs-sm text-secondary">{currentUser?.customClaims && currentUser.customClaims.role}</p>
                     {/* <nav className="nav">
                        <Link to="">
                           <i className="ri-edit-2-line"></i> View Profile
                        </Link>
                     </nav> */}
                     <hr />
                     <nav className="nav">
                        {/* <Link to="">
                           <i className="ri-question-line"></i> Help Center
                        </Link> */}
                        <Link to="">
                           <i className="ri-lock-line"></i> Privacy Policies
                        </Link>
                        <Link to="">
                           <i className="ri-lock-line"></i> Terms and Conditions
                        </Link>
                        <Link to="">
                           <i className="ri-user-settings-line"></i> Account Settings
                        </Link>
                        <Link to="/pages/signin3" replace>
                           <i className="ri-logout-box-r-line"></i> Log Out
                        </Link>
                     </nav>
                  </div>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         {showReminderModal && (
            <>
               <ReminderModal clientId="" clientName="" treatmentId="" treatmentName="" onCloseModal={handleReminderModalClose} skin={isDarkMode} />
            </>
         )}
      </>
   );
}
