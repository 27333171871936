import React, { useState, useRef } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { APPOINTMENT_STATUS_OPTIONS } from "../constants/appointmentConstants.js";

function AppointmentStatusDropdown({ status = 1, locked }) {
   const [selectedStatus, setSelectedStatus] = useState(status);
   const [dropdownOpen, setDropdownOpen] = useState(false);
   const dropdownButtonRef = useRef(null);

   const handleSelect = (eventKey) => {
      console.log("DROPDOWN:", eventKey);
      const selectedOption = APPOINTMENT_STATUS_OPTIONS.find((option) => option.value === parseInt(eventKey));
      setSelectedStatus(selectedOption.value);
   };

   const buttonText = APPOINTMENT_STATUS_OPTIONS.find((option) => option.value === selectedStatus)?.label;

   return (
      <DropdownButton
         ref={dropdownButtonRef}
         disabled={locked}
         id="dropdown-basic-button"
         title={buttonText}
         variant="outline-primary rounded-pill pt-1 pb-1"
         onSelect={handleSelect}>
         {APPOINTMENT_STATUS_OPTIONS.map(
            (option) =>
               option.value !== selectedStatus && (
                  <Dropdown.Item key={option.value} eventKey={option.value}>
                     {option.label}
                  </Dropdown.Item>
               )
         )}
      </DropdownButton>
   );
}

export default AppointmentStatusDropdown;
