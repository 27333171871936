import { Col, Row } from "react-bootstrap";
import InitialsAvatar from "./InitialsAvatar";
import moment from "moment";
const ClientModalHeader = ({ name, category, eMode, eData }) => {
   const UpdateCol = category && category.startsWith("Update");
   const treatmentName = eData?.treatmentName;
   const createDate = eData?.eventstart
      ? moment.utc(eData?.eventstart).format("DD MMM YYYY h:mm a")
      : eData?.start
      ? moment.utc(eData?.start).format("DD MMM YYYY h:mm a")
      : "";
   return (
      <>
         {name && category && (
            <>
               <Row className="d-flex align-items-start">
                  <Col xs="auto">
                     <InitialsAvatar name={name} />
                  </Col>
                  <Col>
                     <p className="fs-5 mb-1">
                        {category} <span className="fs-7">for</span> {treatmentName && treatmentName}
                     </p>
                     <p className="fs-6">{name}</p>
                  </Col>
                  {UpdateCol && (
                     <Col className="mb-1 me-4 d-flex align-self-end  justify-content-end">
                        <p className="mb-1 text-end">
                           <span className="fs-7">Created on:</span> {createDate}
                        </p>
                     </Col>
                  )}
               </Row>
            </>
         )}
      </>
   );
};

export default ClientModalHeader;
