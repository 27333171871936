import { useState, useEffect, useMemo, useRef } from "react";
import { Button, ToggleButton, ToggleButtonGroup, Col, Row, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { customSelectStyles } from "./customSelectStyles";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import AppointmentModalHeader from "../components/AppointmentModalHeader";
import useFetchData from "../components/useFetchDatav3";
import { useToast } from "../context/useToast.js";

import {
   APPOINTMENT_STATUS_OPTIONS,
   APPOINTMENT_LOCATION_OPTIONS,
   APPOINTMENT_REPEAT_OPTIONS,
   APPOINTMENT_END_TYPE_OPTIONS,
   APPOINTMENT_REPEAT_OCCURANCE_OPTIONS,
   APPOINTMENT_REPEAT_END_AFTER_OPTIONS,
   APPOINTMENT_DURATION_OPTIONS,
} from "../constants/appointmentConstants";

const appointmentSchema = z.object({
   appointmentId: z.string(),
   appointmentMode: z.string(),
   appointmentStatus: z.number(),
   appointmentLocation: z.number().refine(
      (value) => {
         const matchingOption = APPOINTMENT_LOCATION_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid location selection",
         path: ["appointmentLocation"],
      }
   ),
   appointmentClient: z.string(),
   appointmentTreatment: z.string(),
   appointmentDate: z.date(),
   appointmentTime: z.date(),
   appointmentDuration: z.number().refine(
      (value) => {
         const matchingOption = APPOINTMENT_DURATION_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid duration selection",
         path: ["appointmentDuration"],
      }
   ),
   repeatType: z.string().refine(
      (value) => {
         const matchingOption = APPOINTMENT_REPEAT_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid selection repeat",
         path: ["repeatType"],
      }
   ),
   repeatDow: z.array(z.number()),
   repeatOccurance: z.number().refine(
      (value) => {
         const matchingOption = APPOINTMENT_REPEAT_OCCURANCE_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid selection",
         path: ["repeatOccurance"],
      }
   ),
   repeatEndsType: z.string().refine(
      (value) => {
         const matchingOption = APPOINTMENT_END_TYPE_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid selection for ending on",
         path: ["repeatEndsType"],
      }
   ),
   repeatEndsAfterDate: z.date(),
   repeatEndsAfterPeriod: z.number().refine(
      (value) => {
         const matchingOption = APPOINTMENT_REPEAT_END_AFTER_OPTIONS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Invalid selection",
         path: ["repeatEndAfterPeriod"],
      }
   ),
});

const AppointmentForm = ({ appointmentId, appointmentTitle, appointmentStartDate, eData, eMode, onCloseModal, formIsDirty, skin }) => {
   const [mode, setMode] = useState(eMode);
   const [clients, setClients] = useState([]);
   const [selectedClient, setSelectedClient] = useState(null);
   const [filteredTreatments, setFilteredTreatments] = useState([]);
   const [treatments, setTreatments] = useState([]);
   const { fetchData } = useFetchData();
   const { triggerToast } = useToast();
   const formRef = useRef(null);

   const getAppointmentLocation = () => {
      const defaultAppointmentLocation = eData["location_id"] ? APPOINTMENT_LOCATION_OPTIONS.find((option) => option.value === eData.location_id)?.value : null;
      return defaultAppointmentLocation;
   };
   const getAppointmentStatus = () => {
      const defaultAppointmentStatus = eData["appointmentstatus"]
         ? APPOINTMENT_STATUS_OPTIONS.find((option) => option.value === eData.appointmentstatus)?.value
         : null;
      return defaultAppointmentStatus;
   };
   const getAppointmentDuration = () => {
      const defaultAppointmentDuration = eData["sessionduration"]
         ? APPOINTMENT_DURATION_OPTIONS.find((option) => option.value === eData.sessionduration)?.value
         : APPOINTMENT_DURATION_OPTIONS[15];
      return defaultAppointmentDuration;
   };
   const getAppointmentRepeatType = () => {
      //    const defaultRepeatType = eData.repeat
      //       ? APPOINTMENT_REPEAT_OPTIONS.find((option) => option.value === eData.repeat?.["repeat-type"]?.value)
      //       : APPOINTMENT_REPEAT_OPTIONS[0].value;
      const defaultRepeatType = eData.repeat?.["repeat-type"] ?? APPOINTMENT_REPEAT_OPTIONS[0].value;
      console.log("getAppointmentRepeatType:", defaultRepeatType, typeof defaultRepeatType);
      return defaultRepeatType;
   };
   const getRepeatEndsType = () => {
      console.log("get repeat end type initial default value:", eData.repeat?.["repeat-ends-type"]);
      // const defaultRepeatEndsType = eData.repeat?.["repeat-ends-type"]
      //    ? APPOINTMENT_END_TYPE_OPTIONS.find((option) => option.value === eData.repeat?.["repeat-ends-type"])
      //    : APPOINTMENT_END_TYPE_OPTIONS[0].value;
      const defaultRepeatEndsType = eData.repeat?.["repeat-ends-type"] ?? APPOINTMENT_END_TYPE_OPTIONS[0].value;
      console.log("get repeat end type after default value:", defaultRepeatEndsType);
      return defaultRepeatEndsType;
   };
   const getRepeatOccurance = () => {
      const defaultRepeatOccurance = eData.repeat?.["repeat-occurance"] ?? APPOINTMENT_REPEAT_OCCURANCE_OPTIONS[0].value;
      console.log("getRepeatOccurance value:", defaultRepeatOccurance);
      return defaultRepeatOccurance;
   };
   const getRepeatEndsAfterPeriod = () => {
      const defaultRepeatEndsAfterPeriod = eData.repeat?.["repeat-ends-after-period"] ?? APPOINTMENT_REPEAT_END_AFTER_OPTIONS[0].value;
      console.log("getRepeatEndsAfterPeriod value:", defaultRepeatEndsAfterPeriod);
      return defaultRepeatEndsAfterPeriod;
   };
   const getClient = (eData, eMode) => {
      let id = "";
      if (eMode === "edit") {
         id = eData.clientid;
      }
      console.log("DEFAULT VALUES CLIENTS:", eData.clientid, eMode, id);
      //return id;
      return id;
   };
   const getTreatment = (eData, eMode) => {
      let id = "";
      if (eMode === "edit") {
         id = eData.treatmentid;
      }
      console.log("DEFAULT VALUES TREATMENTS:", id);
      //return id;
      return id;
   };
   function combineClientNames(firstName, lastName) {
      // Check if both names are provided
      if (!firstName || !lastName) {
         return ""; // Return empty string if either name is missing
      }

      // Add a space between the names
      return firstName.trim() + " " + lastName.trim();
   }
   const getClients = (data, eMode) => {
      let c = [];
      if (eMode === "edit") {
         c.push({ value: data.clientid, label: data.clientname });
      }
      if (eMode === "new") {
         const uniqueClientIds = new Set();
         data.forEach((client) => {
            if (!uniqueClientIds.has(client.c_external_id)) {
               uniqueClientIds.add(client.c_external_id);
               c.push({ value: client.c_external_id, label: combineClientNames(client.c_first_name, client.c_last_name) });
            }
         });
      }
      return c;
   };
   const getTreatments = (eData, fetchedClients, eMode) => {
      let t = [];
      if (eMode === "edit") {
         let treatmentObj = {};
         treatmentObj[eData.clientid] = { value: eData.treatmentid, label: eData.treatmentname };
         treatments.push(treatmentObj);
      }
      if (eMode === "new") {
         fetchedClients.forEach((client) => {
            let treatmentObj = {};
            treatmentObj[client.c_external_id] = {
               value: client.cs_external_id,
               label: client.s_name,
            };
            t.push(treatmentObj);
         });
      }
      return t;
   };

   const defaultValues = useMemo(
      () => ({
         appointmentId: "",
         appointmentMode: "",
         appointmentStatus: getAppointmentStatus(),
         appointmentClient: getClient(eData, eMode),
         appointmentTreatment: getTreatment(eData, eMode),
         appointmentLocation: getAppointmentLocation(),
         appointmentDate: appointmentStartDate || new Date(),
         appointmentTime: appointmentStartDate || new Date(),
         appointmentDuration: getAppointmentDuration(),
         repeatType: getAppointmentRepeatType(),
         repeatDow: [],
         repeatOccurance: getRepeatOccurance(),
         repeatEndsType: getRepeatEndsType(),
         repeatEndsAfterDate: new Date(),
         repeatEndsAfterPeriod: getRepeatEndsAfterPeriod(),
      }),
      [eData]
   );

   const [initialData, setInitialData] = useState({});

   const {
      control,
      handleSubmit,
      setError,
      methods,
      watch,
      setValue,
      formState: { isDirty, dirtyFields, errors, isSubmitting, isSubmitted },
      reset,
   } = useForm({
      resolver: zodResolver(appointmentSchema),
      defaultValues,
   });

   useEffect(() => {
      console.log("Form Errors:", errors); // Debugging log
      console.log("Is Submitted:", isSubmitted); // Debugging log
      console.log("Is Submitting:", isSubmitting); // Debugging log
   }, [errors, isSubmitted, isSubmitting]);

   const onSubmit = async (data) => {
      console.log("Form Data:", data);
      try {
         const method = "POST";
         const params = "params=" + JSON.stringify(data);
         const body = params;
         const url = "/insertAppointment.sz";
         const contentType = "application/x-www-form-urlencoded";
         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
            if (responseData.st === 1) {
               reset(data); //updates the default values to new values, not sure if it needed
               triggerToast("success", "Note was successfully saved.", false, "", false, 0);
            } else {
               if (responseData.m_en) {
                  triggerToast("warning", responseData.m_en, false, "", false, 0);
               } else {
                  triggerToast("error", "It appears your session has expired, please Sign In", false, "", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "An error has occurred, If the problem persists contact support", false, "", false, 0);
         }

         console.log("Submit Success");
      } catch (error) {
         triggerToast("error", "Note was not saved.");
         console.log("Submit Error");
      }
      // from productions
      // {"appointment-type":"client","appointment-mode":"new","calendar-month-end-date":"2024-07-31","appointment-id":"","appointment-status":"1","appointment-client":"f94a1d93-a2fd-4fed-bfa4-36db7732ecf1","appointment-title":"","appointment-treatment":"248ffb6b-5562-4080-9f06-1cb4187962bc","appointment-location":"1","appointmentDate":"2024-07-09","appointmenttime":"03:50+pm","appointment-duration":"60","repeat-type":"weekly","repeat-occurance":"1","repeat-ends-type":"after","repeat-ends-after-period":"2","repeat-ends-after-date":"","repeat-dow":[1]}
      // This form
      //  {"appointmentId":"","appointmentMode":"","appointmentStatus":1,"appointmentLocation":1,"appointmentClient":"f94a1d93-a2fd-4fed-bfa4-36db7732ecf1","appointmentTreatment":"fa625dc7-4fa1-48a6-a0d0-e01665664125","appointmentDate":"2024-07-08T00:00:00.000Z","appointmentTime":"2024-07-08T00:00:00.000Z","appointmentDuration":60,"repeatType":"weekly","repeatDow":[1],"repeatOccurance":1,"repeatEndsType":"ondate","repeatEndsAfterDate":"2024-07-30T20:03:40.000Z","repeatEndsAfterPeriod":1}
   };

   const fetchClients = async () => {
      const params = {
         cs_status: 1,
      };
      const url = "/getCaseSummary.sz";
      const method = "GET";
      const body = { params: JSON.stringify(params) };
      const contentType = "application/json";

      try {
         const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
         if (responseData.st === 1) {
            return responseData.payload?.clients;
         } else {
            if (responseData.m_en) {
               //triggerToast("warning", responseData.m_en, false, "", false);
               throw new Error(responseData.m_en);
            } else {
               //triggerToast("error", "We seem to have an issue retrieving clients, please try again later.", true, "Issue", false, 0);
               throw new Error("We seem to have an issue retrieving clients, please try again later.");
            }
         }
      } catch (error) {
         //triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         throw new Error("We seem to have an access your data, please try again later.");
      }
   };

   useEffect(() => {
      console.log("eData:", eData);
      console.log("eMode:", eMode);
   }, []);

   //setup client data
   useEffect(() => {
      const fetchData = async () => {
         setMode(eMode);
         if (eMode === "new") {
            try {
               const fetchedClients = await fetchClients();
               const updatedClients = getClients(fetchedClients, eMode);
               const updatedTreatments = getTreatments([], fetchedClients, eMode);
               setClients(updatedClients);
               setTreatments(updatedTreatments);
            } catch (error) {
               triggerToast("error", error, false, "", false, 0);
            }
         } else {
            setClients(getClients(eData, [], eMode));
            setTreatments(getTreatments(eData, eMode));
         }
         setInitialData(eData);
      };
      fetchData();
   }, [eData, eMode]);

   useEffect(() => {
      if (initialData && Object.keys(initialData).length > 0) {
         setValue("appointmentId", appointmentId);
         setValue("appointmentMode", mode);

         // const defaultAppointmentStatus = initialData["appointmentstatus"]
         //    ? APPOINTMENT_STATUS_OPTIONS.find((option) => option.value === initialData["appointmentstatus"])?.value
         //    : APPOINTMENT_STATUS_OPTIONS[0].value;
         // setValue("appointmentStatus", defaultAppointmentStatus);

         // const defaultAppointmentLocation = initialData["location_id"]
         //    ? APPOINTMENT_LOCATION_OPTIONS.find((option) => option.value === initialData["location_id"])?.value
         //    : null;
         // setValue("appointmentLocation", defaultAppointmentLocation);

         // const defaultClient = {
         //    value: initialData["clientid"],
         //    label: initialData["clientname"],
         // };
         // setValue("appointmentClient", defaultClient);
         //setClientOptions([defaultClient]);

         // const defaultTreatment = {
         //    value: initialData["treatmentid"],
         //    label: initialData["treatmentname"],
         // };
         // setValue("appointmentTreatment", defaultTreatment);
         // setTreatmentOptions([defaultTreatment]);

         // const defaultAppointmentDuration = initialData["sessionduration"]
         //    ? APPOINTMENT_DURATION_OPTIONS.find((option) => option.value === initialData["sessionduration"])
         //    : APPOINTMENT_DURATION_OPTIONS[11];
         // setValue("appointmentDuration", defaultAppointmentDuration);

         if (initialData.repeat) {
            // const defaultRepeatEndsType = initialData.repeat["repeat-ends-type"]
            //    ? APPOINTMENT_END_TYPE_OPTIONS.find((option) => option.value === initialData.repeat["repeat-ends-type"])
            //    : APPOINTMENT_END_TYPE_OPTIONS[0];
            // setValue("repeatEndsType", defaultRepeatEndsType);

            // const defaultRepeatType = initialData.repeat["repeat-type"]
            //    ? APPOINTMENT_REPEAT_OPTIONS.find((option) => option.value === initialData.repeat["repeat-type"])
            //    : APPOINTMENT_REPEAT_OPTIONS[0];
            // setValue("repeatType", defaultRepeatType);

            const defaultRepeatDow = initialData.repeat["repeat-dow"] || [];
            setValue("repeatDow", defaultRepeatDow);

            // const repeatOccuranceValue = initialData.repeat["repeat-occurance"];
            // const defaultRepeatOccurance =
            //    APPOINTMENT_REPEAT_OCCURANCE_OPTIONS.find((option) => option.value === repeatOccuranceValue) || APPOINTMENT_REPEAT_OCCURANCE_OPTIONS[0];
            // setValue("repeatOccurance", defaultRepeatOccurance);

            const stringRepeatAfterDate = initialData.repeat["repeat-ends-after-date"];
            const isValidDate = !isNaN(Date.parse(stringRepeatAfterDate));
            if (isValidDate) {
               const defaultRepeatAfterDate = new Date(
                  Date.UTC(
                     new Date(stringRepeatAfterDate).getUTCFullYear(),
                     new Date(stringRepeatAfterDate).getUTCMonth(),
                     new Date(stringRepeatAfterDate).getUTCDate()
                  )
               );
               setValue("repeatEndsAfterDate", defaultRepeatAfterDate);
            } else {
               setValue("repeatEndsAfterDate", null);
            }

            // const defaultRepeatAfterPeriod = initialData.repeat["repeat-ends-after-period"]
            //    ? initialData.repeat["repeat-ends-after-period"]
            //    : APPOINTMENT_REPEAT_END_AFTER_OPTIONS[0];
            // setValue("repeatEndsAfterPeriod", defaultRepeatAfterPeriod);
         } else {
            // setValue("repeatType", APPOINTMENT_REPEAT_OPTIONS[0]);
            // setValue("repeatOccurance", APPOINTMENT_REPEAT_OCCURANCE_OPTIONS[0]);
            setValue("repeatDow", []);
            //setValue("repeatEndsType", APPOINTMENT_END_TYPE_OPTIONS[0]);
            // setValue("repeatEndsAfterPeriod", APPOINTMENT_REPEAT_END_AFTER_OPTIONS[0]);
            setValue("repeatEndsAfterDate", appointmentStartDate);
            // setValue("appointmentDuration", APPOINTMENT_DURATION_OPTIONS[11]);
         }
      }
      console.log("defaultValues:", defaultValues);
   }, [initialData, setValue, appointmentId, appointmentStartDate, mode]);

   const watchAppointmentStatus = watch("appointmentStatus");
   const watchAppointmentClient = watch("appointmentClient");
   const watchAppointmentTreatment = watch("appointmentTreatment");
   const watchAppointmentLocation = watch("appointmentLocation");
   const watchAppointmentDate = watch("appointmentDate");
   const watchAppointmentTime = watch("appointmentTime");
   const watchAppointmentDuration = watch("appointmentDuration");
   const watchRepeatType = watch("repeatType");
   const watchRepeatOccurance = watch("repeatOccurance");
   const watchRepeatEndsType = watch("repeatEndsType");
   const watchRepeatEndsAfterPeriod = watch("repeatEndsAfterPeriod");
   const watchRepeatEndsAfterDate = watch("repeatEndsAfterDate");
   const watchRepeatDow = watch("repeatDow");

   function getTreatmentsOptions(clientId, clientArray) {
      if (Array.isArray(clientArray)) {
         const client = clientArray.find((client) => client.clientid === clientId);
         if (client) {
            return client.treatments.map((treatment) => ({
               value: treatment.treatmentid,
               label: treatment.treatmentname,
            }));
         }
      } else {
         console.error("Client data is not an array:", clientArray);
         return [];
      }
   }

   useEffect(() => {
      if (selectedClient) {
         const clientKey = selectedClient;
         const clientTreatments = treatments.filter((treatment) => Object.keys(treatment)[0] === clientKey);
         if (clientTreatments.length > 0) {
            const selectedTreatments = clientTreatments.map((treatment) => ({
               value: treatment[clientKey].value,
               label: treatment[clientKey].label,
            }));
            setFilteredTreatments(selectedTreatments);
            console.log("SELECTEDTREATMENTS:", selectedTreatments);
         } else {
            setFilteredTreatments([]);
         }
      }
   }, [selectedClient, treatments]);

   const isFormDirty = () => {
      return Object.values(dirtyFields || {}).some((isDirty) => isDirty);
   };

   useEffect(() => {
      console.log("pass to parent event before:", isDirty);
      formIsDirty(isFormDirty());
      console.log("pass to parent event after:", isFormDirty());
   }, [
      watchAppointmentStatus,
      watchAppointmentClient,
      watchAppointmentTreatment,
      watchAppointmentLocation,
      watchAppointmentDate,
      watchAppointmentTime,
      watchAppointmentDuration,
      watchRepeatType,
      watchRepeatOccurance,
      watchRepeatEndsType,
      watchRepeatEndsAfterPeriod,
      watchRepeatEndsAfterDate,
      watchRepeatDow,
   ]); // Dependency on form values

   const onHandleButtonClose = (e) => {
      // console.log("cancel form dirty fields:", dirtyFields);
      // const hasDirtyFields = isFormDirty();
      // console.log("hasDirtyFields:", hasDirtyFields);

      // if (hasDirtyFields && !isSubmitting) {
      //    console.log("Trying to exist on a field change");
      //    e.preventDefault();
      // } else {
      //setShow(false);
      console.log("cancel form Offcanvas has exited");
      onCloseModal?.();
      //}
   };

   const isDisabled = (mode) => {
      return mode === "edit";
   };

   return (
      <>
         <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Row>
               <Col className="event-body ps-3 pe-3">
                  <AppointmentModalHeader eData={eData} appDate={appointmentStartDate} eMode={eMode} />
               </Col>
            </Row>
            <Row style={{ flex: "1", overflowY: "auto" }}>
               <Col>
                  <form ref={formRef} id="appointmentForm" onSubmit={handleSubmit(onSubmit)}>
                     <div className="card-bc card border-0 ">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Appointment Details'" }}>
                           <Row>
                              <Col xs={12} md={6}>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Controller name="appointmentId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                       <Controller name="appointmentMode" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                       <Controller
                                          name="appointmentStatus"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          defaultValue={defaultValues.appointmentStatus}
                                          render={({ field }) => (
                                             <>
                                                {console.log("defaultValue:", defaultValues.appointmentStatus)}
                                                <label className="form-label" htmlFor="appointmentStatus">
                                                   Status
                                                </label>
                                                <Select
                                                   inputId="appointmentStatus"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   options={APPOINTMENT_STATUS_OPTIONS}
                                                   value={APPOINTMENT_STATUS_OPTIONS.find((s) => s.value === field.value)}
                                                   onChange={(selectedOption) => {
                                                      field.onChange(selectedOption.value);
                                                   }}
                                                   isDisabled={!!initialData.islocked}
                                                   onBlur={() => field.onBlur()}
                                                />
                                                {errors["appointmentStatus"] && <div className="text-danger">{errors["appointmentStatus"].message}</div>}
                                                {console.log("appointmentStatus value is:", field.value)}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Controller
                                          name="appointmentClient"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          defaultValue={defaultValues.appointmentClient}
                                          render={({ field }) => (
                                             <>
                                                {console.log("CLIENT defaultValue:", defaultValues.appointmentClient, field)}
                                                {console.log("CLIENTS ARRAY:", clients)}
                                                <label htmlFor="appointmentClient" className="form-label">
                                                   Client
                                                </label>
                                                <Select
                                                   inputId="appointmentClient"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   value={clients.find((s) => s.value === field.value)}
                                                   onChange={(selectedOption) => {
                                                      console.log("selectedOption:", selectedOption);
                                                      // set client id
                                                      setSelectedClient(selectedOption.value);
                                                      field.onChange(selectedOption.value);
                                                   }}
                                                   onBlur={() => field.onBlur()}
                                                   isDisabled={isDisabled(mode)}
                                                   options={clients}></Select>
                                                {errors["appointmentClient"] && <div className="text-danger">{errors["appointmentClient"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Controller
                                          name="appointmentTreatment"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          defaultValue={filteredTreatments}
                                          render={({ field }) => (
                                             <>
                                                {console.log("FILTEREDTREATMENTS:", filteredTreatments)}
                                                <label htmlFor="appointmentTreatment" className="form-label">
                                                   Treatment
                                                </label>
                                                <Select
                                                   inputId="appointmentTreatment"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   value={filteredTreatments.find((s) => s.value === field.value)}
                                                   onChange={(selectedOption) => {
                                                      field.onChange(selectedOption.value);
                                                   }}
                                                   onBlur={() => field.onBlur()}
                                                   isDisabled={isDisabled(mode)}
                                                   options={filteredTreatments}></Select>
                                                {errors["appointmentTreatment"] && <div className="text-danger">{errors["appointmentTreatment"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col>
                                       <Controller
                                          name="appointmentLocation"
                                          control={control}
                                          // rules={{ required: "Required" }}
                                          defaultValue={defaultValues.appointmentLocation}
                                          render={({ field }) => (
                                             <>
                                                {console.log("default location at render:", defaultValues.appointmentLocation, field.value)}
                                                <label htmlFor="appointmentLocation" className="form-label">
                                                   Location
                                                </label>
                                                <Select
                                                   inputId="appointmentLocation"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   options={APPOINTMENT_LOCATION_OPTIONS}
                                                   //value={field.value}
                                                   value={APPOINTMENT_LOCATION_OPTIONS.find((s) => s.value === field.value)}
                                                   onChange={(value) => {
                                                      field.onChange(value.value);
                                                   }}
                                                   isDisabled={!!initialData.islocked}
                                                   onBlur={() => field.onBlur()}></Select>
                                                {errors["appointmentLocation"]?.message && (
                                                   <div className="text-danger">{errors["appointmentLocation"].message}</div>
                                                )}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                              </Col>
                              <Col xs={12} md={6}>
                                 <Row className="g-0 mt-3">
                                    <Col sm={4}>
                                       <Controller
                                          name="appointmentDate"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          render={({ field }) => (
                                             <>
                                                <label className="form-label" htmlFor="appointmentDate">
                                                   Date
                                                </label>
                                                <DatePicker
                                                   dateFormat="yyyy-MM-dd"
                                                   id="appointmentDate"
                                                   disabled={isDisabled(mode) || !!initialData.islocked}
                                                   selected={field.value}
                                                   onChange={(date) => field.onChange(date)}
                                                   className="form-control picker"
                                                />
                                                {errors["appointmentDate"] && <div className="text-danger">{errors["appointmentDate"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col sm={4}>
                                       <Controller
                                          name="appointmentTime"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          render={({ field }) => (
                                             <>
                                                <label className="form-label" htmlFor="appointmentTime">
                                                   Time
                                                </label>
                                                <DatePicker
                                                   selected={field.value}
                                                   onChange={(date) => field.onChange(date)}
                                                   showTimeSelect
                                                   id="appointmentTime"
                                                   showTimeSelectOnly
                                                   timeIntervals={30}
                                                   disabled={isDisabled(mode) || !!initialData.islocked}
                                                   timeCaption="Time"
                                                   dateFormat="h:mm aa"
                                                   className="form-control picker"
                                                />
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="g-0 mt-3">
                                    <Col sm={4}>
                                       <Controller
                                          name="appointmentDuration"
                                          control={control}
                                          rules={{ required: "Required" }}
                                          defaultValue={defaultValues.appointmentDuration}
                                          render={({ field }) => (
                                             <>
                                                <label className="form-label" htmlFor="appointmentDuration">
                                                   Duration
                                                </label>
                                                {console.log("field value for duration:", field)}
                                                <Select
                                                   inputId="appointmentDuration"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   isDisabled={!!initialData.islocked}
                                                   value={APPOINTMENT_DURATION_OPTIONS.find((s) => s.value === field.value)}
                                                   onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                                   options={APPOINTMENT_DURATION_OPTIONS}></Select>
                                                {errors["appointmentDuration"] && <div className="text-danger">{errors["appointmentDuration"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </Row>
                              </Col>
                           </Row>
                        </div>
                     </div>
                     <div className="card-bc card border-0 ">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Appointment Schedule Options'" }}>
                           <Row className="g-0 mt-2 align-items-end">
                              <Col sm={2} className="me-2">
                                 <Controller
                                    name="repeatType"
                                    control={control}
                                    defaultValue={defaultValues.repeatType}
                                    render={({ field }) => (
                                       <>
                                          {console.log("default repeat type at render:", defaultValues.repeatType, field.value)}
                                          <label className="form-label" htmlFor="repeatType">
                                             Repeat
                                          </label>
                                          <Select
                                             inputId="repeatType"
                                             styles={customSelectStyles(skin)}
                                             {...field}
                                             options={APPOINTMENT_REPEAT_OPTIONS}
                                             isDisabled={!!initialData.islocked}
                                             value={APPOINTMENT_REPEAT_OPTIONS.find((s) => s.value === field.value)}
                                             onBlur={() => field.onBlur()}
                                             onChange={(selectedOption) => {
                                                field.onChange(selectedOption.value);
                                             }}></Select>
                                          {errors["repeatType"] && <div className="text-danger">{errors["repeatType"].message}</div>}
                                       </>
                                    )}
                                 />
                              </Col>
                              {console.log("RepeatType", watchRepeatType)}
                              {watchRepeatType !== "" && (
                                 <>
                                    <Col sm={1} className="me-2">
                                       <Controller
                                          name="repeatOccurance"
                                          control={control}
                                          defaultValue={defaultValues.repeatOccurance}
                                          render={({ field }) => (
                                             <>
                                                <label htmlFor="repeatOccurance" className="form-label">
                                                   Every
                                                </label>
                                                {console.log("default repeat occurance render:", defaultValues.repeatOccurance, field.value)}
                                                <Select
                                                   inputId="repeatOccurance"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   isDisabled={!!initialData.islocked}
                                                   value={APPOINTMENT_REPEAT_OCCURANCE_OPTIONS.find((s) => s.value === field.value)}
                                                   options={APPOINTMENT_REPEAT_OCCURANCE_OPTIONS}
                                                   onChange={(selectOption) => {
                                                      field.onChange(selectOption.value);
                                                   }}></Select>
                                                {errors["repeatOccurance"] && <div className="text-danger">{errors["repeatOccurance"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                    <Col className="col-auto">
                                       <Controller
                                          name="repeatDow"
                                          control={control}
                                          render={({ field }) => (
                                             <>
                                                {/* {console.log("field value", field.value)}
                                                               {console.log("field value is", field.value.includes(2))} */}
                                                <div className="col-auto">
                                                   <div className="btn-toolbar">
                                                      <label htmlFor="repeat-dow-1" className="col-form-label pe-2">
                                                         {console.log("watchRepeatType after:", watchRepeatType)}
                                                         {watchRepeatType &&
                                                            (watchRepeatOccurance.value > 1
                                                               ? APPOINTMENT_REPEAT_OPTIONS.find((option) => option.value === watchRepeatType).plural + " "
                                                               : APPOINTMENT_REPEAT_OPTIONS.find((option) => option.value === watchRepeatType).singular + " ")}
                                                         on
                                                      </label>
                                                      <ToggleButtonGroup type="checkbox" onChange={(val) => setValue("repeatDow", val)}>
                                                         <ToggleButton
                                                            id="repeat-dow-1"
                                                            name="repeat-dow-1"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={1}
                                                            variant={field.value.includes(1) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(1)}>
                                                            Mo
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-2"
                                                            name="repeat-dow-2"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={2}
                                                            variant={field.value.includes(2) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(2)}>
                                                            Tu
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-3"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={3}
                                                            variant={field.value.includes(3) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(3)}>
                                                            We
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-4"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={4}
                                                            variant={field.value.includes(4) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(4)}>
                                                            Th
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-5"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={5}
                                                            variant={field.value.includes(5) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(5)}>
                                                            Fr
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-6"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={6}
                                                            variant={field.value.includes(6) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(6)}>
                                                            Sa
                                                         </ToggleButton>
                                                         <ToggleButton
                                                            id="repeat-dow-7"
                                                            disabled={!!initialData.islocked}
                                                            type="button"
                                                            value={7}
                                                            variant={field.value.includes(7) ? "primary" : "outline-secondary"}
                                                            active={field.value.includes(7)}>
                                                            Su
                                                         </ToggleButton>
                                                      </ToggleButtonGroup>
                                                   </div>
                                                </div>
                                             </>
                                          )}
                                       />
                                    </Col>
                                 </>
                              )}
                           </Row>
                           {watchRepeatType !== "" && (
                              <>
                                 <Row className="g-0 mt-2 align-items-end">
                                    <Col sm={2} className="me-2">
                                       <div className="col-form-label">Ends</div>
                                       <Controller
                                          name="repeatEndsType"
                                          control={control}
                                          defaultValue={defaultValues.repeatEndsType}
                                          render={({ field }) => (
                                             <>
                                                {console.log("field value for RepeatEndsType:", defaultValues.repeatEndsType, field)}
                                                <Select
                                                   inputId="repeatEndsType"
                                                   styles={customSelectStyles(skin)}
                                                   {...field}
                                                   isDisabled={!!initialData.islocked}
                                                   value={APPOINTMENT_END_TYPE_OPTIONS.find((s) => s.value === field.value)}
                                                   options={APPOINTMENT_END_TYPE_OPTIONS}
                                                   onChange={(selectedOption) => {
                                                      field.onChange(selectedOption.value);
                                                   }}></Select>
                                                {errors["repeatEndsType"] && <div className="text-danger">{errors["repeatEndsType"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </Col>
                                    <Col sm={6}>
                                       {console.log("Watch watchRepeatEndsType:", watchRepeatEndsType)}
                                       {watchRepeatEndsType === "ondate" && (
                                          <Row className="d-flex align-items-center">
                                             <Col sm={4}>
                                                <Controller
                                                   name="repeatEndsAfterDate"
                                                   control={control}
                                                   rules={{ required: "Required" }}
                                                   render={({ field }) => {
                                                      const selectedDate = field.value ? new Date(field.value) : null;
                                                      return (
                                                         <>
                                                            <DatePicker
                                                               dateFormat="yyyy-MM-dd"
                                                               id="repeatEndsAfterDate"
                                                               disabled={!!initialData.islocked}
                                                               selected={selectedDate}
                                                               onChange={(value) => {
                                                                  field.onChange(value);
                                                               }}
                                                               className="form-control picker"
                                                            />
                                                            {errors["repeatEndsAfterDate"] && (
                                                               <div className="text-danger">{errors["repeatEndsAfterDate"].message}</div>
                                                            )}
                                                         </>
                                                      );
                                                   }}
                                                />
                                             </Col>
                                             <Col sm={4} className="ps-0">
                                                <label htmlFor="repeatEndsAfterDate" className="col-form-label">
                                                   ending
                                                </label>
                                             </Col>
                                          </Row>
                                       )}
                                       {watchRepeatEndsType === "after" && (
                                          <Row>
                                             <Col xs={12}>
                                                <Controller
                                                   name="repeatEndsAfterPeriod"
                                                   control={control}
                                                   defaultValue={defaultValues.repeatEndsAfterPeriod}
                                                   render={({ field }) => (
                                                      <>
                                                         <div className="d-flex align-items-center">
                                                            <Select
                                                               className="pe-2"
                                                               inputId="repeatEndsAfterPeriod"
                                                               styles={customSelectStyles(skin)}
                                                               {...field}
                                                               isDisabled={!!initialData.islocked}
                                                               value={APPOINTMENT_REPEAT_END_AFTER_OPTIONS.find((s) => s.value === field.value)}
                                                               options={APPOINTMENT_REPEAT_END_AFTER_OPTIONS}
                                                               onChange={(selectedOption) => {
                                                                  field.onChange(selectedOption.value);
                                                               }}></Select>
                                                            <label htmlFor="repeatEndsAfterPeriod" className="col-form-label pe-2">
                                                               occurrences
                                                            </label>
                                                            {errors["repeatEndsAfterPeriod"] && (
                                                               <div className="text-danger">{errors["repeatEndsAfterPeriod"].message}</div>
                                                            )}
                                                         </div>
                                                      </>
                                                   )}
                                                />
                                             </Col>
                                          </Row>
                                       )}
                                    </Col>
                                 </Row>
                              </>
                           )}
                        </div>
                     </div>
                     {eMode !== "new" && (
                        <div className="card-bc card border-0">
                           <div className="card-body border-top border-1" style={{ "--card-body-content": "'Session Notes'" }}>
                              <Row className="g-0 mt-0 align-items-center">
                                 <Col className="d-flex justify-content-between">
                                    <Button
                                       variant="outline-secondary"
                                       style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }}>
                                       View All Notes
                                    </Button>
                                    <Button
                                       variant="outline-secondary"
                                       style={{ "--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem" }}>
                                       Create Session Note
                                    </Button>
                                 </Col>
                                 {errors.root && <div className="text-danger">{errors.root.message}</div>}
                              </Row>
                           </div>
                        </div>
                     )}
                  </form>
               </Col>
            </Row>
            <Row className="g-0 mt-4 ">
               <Col className="d-flex justify-content-between">
                  <Button variant="outline-secondary" size="lg" onClick={onHandleButtonClose} className="rounded-pill">
                     Cancel
                  </Button>

                  <Button
                     type="submit"
                     size="lg"
                     className="rounded-pill"
                     form="appointmentForm"
                     disabled={isSubmitting}
                     onClick={() => {
                        console.log("Submit button clicked"); // Debugging log
                        const form = document.getElementById("appointmentForm");
                        if (form) {
                           console.log("Form exists, submitting form");
                           form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
                        }
                     }}
                     variant="primary">
                     {isSubmitting ? "Saving Appointment..." : "Save Appointment"}
                  </Button>
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default AppointmentForm;
