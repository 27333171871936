import { initializeApp } from "firebase/app";

// const firebaseInt = initializeApp({
//    apiKey: "AIzaSyD618hsuC13Mb-1NROTYLnoDTeDO11_oLM",
//    authDomain: "react-http-bfcc9.firebaseapp.com",
//    databaseURL: "https://react-http-bfcc9-default-rtdb.firebaseio.com",
//    projectId: "react-http-bfcc9",
//    storageBucket: "react-http-bfcc9.appspot.com",
//    messagingSenderId: "996915453270",
//    appId: "1:996915453270:web:e0c162d758f4ada40d0af7",
// });

const firebaseInt = initializeApp({
   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
   appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const firebaseApp = firebaseInt;
