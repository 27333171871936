import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/authContext";
import { ModalProvider } from "./context/ErrorModalContext";
import ErrorModal from "./components/ErrorModal";
import ActivityTracker from "./components/ActivityTracking";
import { ToastProvider } from "./context/useToast";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   //  <React.StrictMode>
   <BrowserRouter>
      <ModalProvider>
         <AuthProvider>
            <ToastProvider>
               <>
                  <ActivityTracker />
                  <App />
                  <div className="main-backdrop"></div>
               </>
            </ToastProvider>
         </AuthProvider>
         <ErrorModal />
      </ModalProvider>
   </BrowserRouter>
   // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
