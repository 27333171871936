import { Badge } from "react-bootstrap";

function StatusBadge({ name, bg, opacity = 0.7 }) {
   return (
      <Badge pill bg={bg} style={{ opacity: opacity }}>
         {name}
      </Badge>
   );
}

export default StatusBadge;
