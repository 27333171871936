import { useContext } from "react";
import { ModalFormContext } from "../context/ModalFormContext";
import { Col, Row, Button } from "react-bootstrap";

const ChildFormTest = () => {
   const { isFormDirty, handleFormChange, handleClose } = useContext(ModalFormContext);

   // ... (Form logic)

   const handleInputChange = (event) => {
      // Update form data and call handleFormChange to update dirty state
      handleFormChange(true); // Assuming form is dirty after changes
   };

   return (
      <div>
         <Row>
            <Col>
               {/* Form content */}
               <input onChange={handleInputChange} />
               {/* ... (other form elements) */}
            </Col>
         </Row>
         <Row>
            <Col>
               <Button
                  onClick={() => {
                     handleClose();
                  }}>
                  Cancel
               </Button>
            </Col>
         </Row>
      </div>
   );
};

export default ChildFormTest;
