export const customSelectStyles = (skin) => ({
   control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? (skin === "dark" ? "#141926" : "#f3f5f9") : skin === "dark" ? "#192030" : "#fff",
      borderColor: skin === "dark" ? "#27314b" : "#e2e5ec",
      color: skin === "dark" ? "rgba(255, 255, 255, 0.65)" : "#41505f",
      minHeight: "36px",
      height: "36px",
      "&:hover": {
         borderColor: "#ccd2da",
      },
      boxShadow: state.isFocused
         ? "0 1px 1px rgba(226, 229, 236, 0.25), 0 2px 2px rgba(226, 229, 236, 0.2), 0 4px 4px rgba(226, 229, 236, 0.15), 0 8px 8px rgba(226, 229, 236, 0.1)"
         : null,
   }),
   menu: (provided, state) => ({
      top: "100%",
      position: "absolute",
      width: "100%",
      "z-index": 10,
      backgroundColor: skin === "dark" ? "#192030" : "hsl(0, 0%, 100%);",
      borderRadius: "4px",
      boxShadow:
         "0 1px 1px rgba(226, 229, 236, 0.25), 0 2px 2px rgba(226, 229, 236, 0.2), 0 4px 4px rgba(226, 229, 236, 0.15), 0 8px 8px rgba(226, 229, 236, 0.1)",
      marginBottom: "8px",
      marginTop: "8px",
      boxSizing: "border-box",

      // backgroundColor: skin === "dark" ? "#192030;" : "transparent",
      // color: skin === "dark" ? "rgba(255, 255, 255, 0.6)" : null,
      // border: skin === "dark" ? "1 solid #27314b" : null,
   }),
   option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#62707e" : "transparent",
      "&:hover": {
         backgroundColor: skin === "dark" ? "#6e7985" : "#e3e6e9", // Background color on hover
      },
   }),
   singleValue: (provided, state) => ({
      ...provided,
      color: skin === "dark" ? "rgba(255, 255, 255, 0.65)" : "#41505f", // Text color for selected item
   }),
});
