// src/context/ModalContext.js
import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
   const [modal, setModal] = useState({ isOpen: false, message: "" });

   const openModal = (message) => {
      setModal({ isOpen: true, message });
   };

   const closeModal = () => {
      setModal({ isOpen: false, message: "" });
   };

   return <ModalContext.Provider value={{ modal, openModal, closeModal }}>{children}</ModalContext.Provider>;
};
