import { useEffect, useState, useMemo, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Offcanvas, Container, Row, Col, Button, Table, Form, Modal } from "react-bootstrap";
import ModalHeader from "./DocumentModalHeader";
import { useToast } from "../context/useToast.js";
import useFetchData from "../components/useFetchDatav3";
import "./DocumentModal.style.css";
import { PROVIDER_DOCUMENT_TYPES } from "../constants/documentTypes.js";
import { ModalFormContext } from "../context/ModalFormContext";

const DocumentModal = ({ clientId, clientName, treatmentId, treatmentName, onCloseModal, skin }) => {
   const { isOpen, isFormDirty, handleFormChange, handleClose, resetFormDirtyState } = useContext(ModalFormContext);
   //const [show, setShow] = useState(true);
   const [acceptFileShow, setAcceptFileShow] = useState(false);
   const [acceptFile, setAcceptFile] = useState(null);
   const [selectedTID, setTID] = useState(null);
   const [payloadDocuments, setPayloadDocuments] = useState([]);
   const { triggerToast } = useToast();
   const { fetchData } = useFetchData();
   const [selectedFileType, setSelectedFileType] = useState("");

   const [isFormValid, setIsFormValid] = useState(true);

   const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      maxFiles: 1,
      noKeyboard: true,
      accept: { "application/pdf": [".pdf"] },
      onDrop: (acceptedFiles) => {
         if (acceptedFiles) {
            setAcceptFile(acceptedFiles);
            setAcceptFileShow(true);
         }
         console.log("Dropped files:", acceptedFiles); // Default logging
      },
      onDropRejected: (fileRejections) => {
         console.log("Rejected files:", fileRejections);

         if (fileRejections && fileRejections.length > 0) {
            const firstRejection = fileRejections[0];
            if (firstRejection && firstRejection.errors && firstRejection.errors.length > 0) {
               const errorMsg = firstRejection.errors[0].message;
               triggerToast("error", errorMsg);
               console.log("Trigger Toast:", errorMsg);
            } else {
               console.log("No errors found in the first rejection");
            }
         } else {
            console.log("No Trigger Toast");
         }
      },
   });

   // useEffect(() => {
   //    setShow(true);
   // }, []);

   useEffect(() => {
      setTID(treatmentId);
   }, [treatmentId]);

   //fetch notes
   useEffect(() => {
      // this useEffect sets initial state to false for other treatment tabs
      const getDocuments = async () => {
         if (!selectedTID) {
            return; // Exit early if missing parameters
         }
         const params = {
            cs_id: selectedTID,
            orderby: [{ name: "doc_inserted_on", direction: "desc" }],
         };
         const url = "/getCaseDocuments.sz";
         const method = "GET";
         const body = { params: JSON.stringify(params) };
         const contentType = "application/json";
         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);

            if (responseData.st === 1) {
               setPayloadDocuments(responseData.payload?.documents);
            } else {
               if (responseData.m_en) {
                  //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
                  triggerToast("warning", responseData.m_en, false, "", false);
               } else {
                  triggerToast("error", "We seem to have an issue retrieving notes, please try again later.", true, "Issue", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         }
      };
      getDocuments();
   }, [selectedTID]);

   const baseStyle = {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
   };
   const focusedStyle = {
      borderColor: "#2196f3",
   };

   const acceptStyle = {
      borderColor: "#00e676",
   };

   const rejectStyle = {
      borderColor: "#ff1744",
   };

   const style = useMemo(
      () => ({
         ...baseStyle,
         ...(isFocused ? focusedStyle : {}),
         ...(isDragAccept ? acceptStyle : {}),
         ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
   );

   const DocumentList = ({ payloadDocuments }) => (
      <Container fluid className="ps-0 pe-0 h-100">
         <div className="table-wrapper">
            <Table className="table-hover table-responsive mb-0">
               <thead>
                  <tr>
                     <th scope="col">File Name</th>
                     <th scope="col" className="d-none d-sm-table-cell">
                        Size
                     </th>
                     <th scope="col">Type</th>
                     <th scope="col" className="d-none d-sm-table-cell">
                        Uploaded On
                     </th>
                     <th scope="col" className="d-none d-sm-table-cell">
                        Uploaded By
                     </th>
                     <th scope="col" className="fs-7 text-end">
                        Action
                     </th>
                  </tr>
               </thead>
            </Table>
            <div className="table-body-wrapper">
               <Table className="table-hover table-responsive mb-0">
                  <tbody>
                     {payloadDocuments.map((docs, idx) => (
                        <tr key={idx}>
                           <td>{docs.filename}</td>
                           <td className="d-none d-sm-table-cell">{docs.filesize}</td>
                           <td>{docs.doc_type}</td>
                           <td className="d-none d-sm-table-cell">{docs.insert_on}</td>
                           <td className="d-none d-sm-table-cell">{docs.insert_by}</td>
                           <td className="text-end">
                              <span className="fs-5 me-2">
                                 <i className="ri-file-download-line"></i>
                              </span>
                              <span className="fs-5">
                                 <i className="ri-delete-bin-line"></i>
                              </span>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </div>
      </Container>
   );

   const handleFileTypeChange = (e) => {
      setSelectedFileType(e.target.value);
      setIsFormValid(true); // Reset validity when a selection is made
   };

   const handleFormSubmit = () => {
      if (!selectedFileType) {
         setIsFormValid(false);
         return;
      }
      // Handle form submission logic here
      console.log("File attached with type:", selectedFileType);
      setAcceptFileShow(false);
   };

   const FilePromptModal = ({ acceptFile, onHide }) => (
      <Modal centered show={true} onHide={handleFileModalHide} animation={false}>
         <Modal.Header closeButton>
            <Modal.Title>Define File Type Dropped</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form>
               <input type="hidden" name="filename" value={acceptFile} />
               {PROVIDER_DOCUMENT_TYPES.map((fileType, idx) => (
                  <div key={idx} className="form-check">
                     <Form.Check
                        //className={`form-check-input ${!isFormValid ? "is-invalid" : ""}`}
                        type="radio"
                        name="fileType"
                        id={`fileType-${idx}`}
                        value={fileType.value}
                        checked={selectedFileType === fileType.value}
                        onChange={handleFileTypeChange}
                        label={`${fileType.label}`}
                     />
                  </div>
               ))}
               {!isFormValid && <div className="invalid-feedback d-block">Please select a file type.</div>}
            </Form>
         </Modal.Body>
         <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
               <div>
                  <Button variant="outline-secondary" onClick={handleFileModalHide}>
                     Cancel
                  </Button>
               </div>
               <div>
                  <Button variant="primary" onClick={handleFormSubmit} disabled={!selectedFileType}>
                     Attach File to Treatment
                  </Button>
               </div>
            </div>
         </Modal.Footer>
      </Modal>
   );

   const handleFileModalHide = () => {
      console.log("File Prompt Modal hidden!");
      setSelectedFileType("");
      setAcceptFileShow(false);
   };

   const onHandleButtonClose = (e) => {
      handleClose();
      onCloseModal?.();
   };

   return (
      <>
         <Offcanvas show={isOpen} onHide={onHandleButtonClose} placement="end" className="offcanvas-width-xl">
            <Offcanvas.Header closeButton className="pb-0" />
            <Offcanvas.Body className="pt-0" style={{ maxHeight: "calc(100vh - 3.5rem)", overflowY: "auto" }}>
               <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <Row>
                     <Col className="event-body ps-3 pe-3">
                        <ModalHeader title="Documents" clientName={clientName} treatmentName={treatmentName} />
                     </Col>
                  </Row>
                  <Row style={{ flex: "1", overflowY: "auto" }}>
                     <section className="container mt-3">
                        <div className="h-100" {...getRootProps({ style })}>
                           <input {...getInputProps()} />
                           <p>
                              Drag 'n' drop some <span className="fw-semibold">Pdf</span> files here, or click to select{" "}
                              <span className="fw-semibold">Pdf</span> files
                           </p>
                           <div className="mt-auto w-100 h-50 table-container">
                              <DocumentList payloadDocuments={payloadDocuments} />
                           </div>
                        </div>
                     </section>
                  </Row>
                  <Row className="g-0 mt-4 ">
                     <Col xs={4}></Col>
                     <Col className="d-flex justify-content-center">
                        <Button variant="outline-secondary" size="lg" onClick={onHandleButtonClose} className="rounded-pill">
                           Cancel
                        </Button>
                     </Col>
                     <Col xs={4}></Col>
                  </Row>
               </Container>
            </Offcanvas.Body>
         </Offcanvas>
         {console.log("SHOWMODAL:", acceptFileShow)}
         {acceptFileShow && acceptFile.length > 0 && (
            <>
               console.log("File Prompt Modal should render!")
               <FilePromptModal acceptFile={acceptFile[0].name} onHide={() => setAcceptFileShow(false)} />
            </>
         )}
      </>
   );
};

export default DocumentModal;
