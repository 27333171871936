import React, { useState, useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Button, Col, Row, Container, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { zodResolver, anyResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ClientModalHeader from "../components/ClientModalHeader";
import ClassicEditor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./treatmentnoteform.css";
import { CLINICIAN_NOTE_TYPE } from "../constants/noteTypes.js";
import { useToast } from "../context/useToast.js";
import moment from "moment";
import useFetchData from "../components/useFetchDatav3";
import { ModalFormContext } from "../context/ModalFormContext";

//Schema Definition for RHF from zod
const noteSchema = z.object({
   // uuid can be optional
   treatmentId: z.string().uuid(),
   serviceEventNoteId: z
      .string()
      .optional()
      .refine((value) => !value || z.string().uuid().safeParse(value).success, {
         message: "Invalid uuid",
         type: "invalid_string",
      }),
   serviceEventId: z
      .string()
      .optional()
      .refine((value) => !value || z.string().uuid().safeParse(value).success, {
         message: "Invalid uuid",
         type: "invalid_string",
      }),
   treatmentNote: z.string().min(5, { message: "Treatment Note is required." }),
   treatmentNoteType: z.number().refine(
      (value) => {
         const matchingOption = CLINICIAN_NOTE_TYPE.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Note Type selection is required",
      }
   ),
   treatmentNoteDate: z.date(),
   treatmentNoteTime: z.date(),
});

const TreatmentNoteForm = ({ clientId, clientName, treatmentId, treatmentName, eData, eMode, action, skin }) => {
   // this is the modal context for capturing changes and a user does not save
   const { isFormDirty, handleFormChange, handleClose, resetFormDirtyState } = useContext(ModalFormContext);

   const { fetchData } = useFetchData();
   console.log("PROPS:", clientId, clientName, treatmentId, treatmentName, eData, eMode, action);
   const { triggerToast } = useToast();
   const formRef = useRef(null);

   const generateNoteText = (note) => {
      let nh = "";
      if (note.eventnotequestion) {
         for (let ii = 0, llen = note.eventnotequestion.length; ii < llen; ii++) {
            if (note.eventnotequestion[ii].question) {
               nh +=
                  `<h6 className="font-weight-bold mb-0">${note.eventnotequestion[ii].question}</h6>` +
                  (note.eventstatus === 2 || note.eventstatus === 9 ? note.eventnotefinal[ii]?.html_final : note.eventnoteanswer[ii]?.html_answer);
            } else {
               let noteTxt = "";
               if (Array.isArray(note.eventnotefinal) && note.eventnotefinal[ii]?.html_final) {
                  noteTxt = note.eventnotefinal[ii]?.html_final;
               } else if (Array.isArray(note.eventnoteanswer) && note.eventnoteanswer[ii]?.html_answer) {
                  noteTxt = note.eventnoteanswer[ii]?.html_answer;
               } else if (Array.isArray(note.eventnoteresponse) && note.eventnoteresponse[ii]?.html_answer) {
                  noteTxt = note.eventnoteresponse[ii]?.html_answer;
               }
               nh += note.eventstatus === 2 || note.eventstatus === 9 ? noteTxt : noteTxt;
            }
         }
      }
      return nh;
   };

   function removeLastZRegex(str) {
      return str.replace(/Z$/, "");
   }

   // Very import to set up value if they exist coming into the component.
   const defaultValues = useMemo(
      () => ({
         treatmentId: treatmentId,
         serviceEventNoteId: eData?.id ? eData?.id : "",
         serviceEventId: eData?.eventid ? eData?.eventid : "",
         treatmentNote: eData ? generateNoteText(eData) : "",
         treatmentNoteType: eData?.eventtype ? eData?.eventtype : "",
         treatmentNoteDate: eData?.eventstart ? new Date(removeLastZRegex(eData.eventstart)) : new Date(),
         treatmentNoteTime: eData?.eventstart ? new Date(removeLastZRegex(eData.eventstart)) : new Date(),
      }),
      [treatmentId, eData]
   );

   const [mode, setMode] = useState(eMode);
   const colRef = useRef(null);
   const editorRef = useRef();
   const [colHeight, setColHeight] = useState(0);

   useEffect(() => {
      if (editorRef.current && editorRef.current.editor) {
         const editorInstance = editorRef.current.editor;
         if (editorInstance.ui && editorInstance.ui.view && editorInstance.ui.view.editable) {
            const editorElement = editorInstance.ui.view.editable.element;
            // Set the height of the editor dynamically
            editorElement.style.height = `${colHeight}px`;
            console.log("Set Editor Height:", colHeight);
         }
      }
   }, [colHeight]);

   useLayoutEffect(() => {
      if (colRef.current) {
         setColHeight(colRef.current.clientHeight);
         console.log("Col height:", colHeight);
      }
   }, [colRef.current]);

   const {
      control,
      handleSubmit,
      setError,
      methods,
      watch,
      setValue,
      clearErrors,
      formState: { isDirty, dirtyFields, errors, isSubmitting, isSubmitted },
      reset,
   } = useForm({
      resolver: zodResolver(noteSchema),
      defaultValues,
   });

   const getEditorClassName = (skin) => (skin === "dark" ? "ck-editor--dark" : "");

   const onSubmit = async (data) => {
      console.log("onSubmit called");
      try {
         console.log("Form Data:", data);
         //
         // params: {"note":[{"id":1,"text_answer":"test test","html_answer":"<p>test test</p>"}],"eid":"1f48ec95-d2fc-42d1-9088-ccc08ba6eb4f","nid":"c9b31788-164e-4740-a64f-516642606274",
         //                   "orderby":[{"name":"se_apt_start","direction":"desc"}]}
         //
         const editor = editorRef.current;
         if (!editor) {
            triggerToast("error", "Error saving the note. Please contact support", false, "", false, 0);
            return;
         }
         // converts html to markdown text. There is also a marked.js library
         const markdownRequestNote = editor.getData(data.requestNote);
         const method = "POST";
         const values = {
            note_text: markdownRequestNote,
            note_html: data.requestNote,
            note_type: data.requestType,
            note_date: data.requestDate,
            cs_id: data.treatmentId,
            // use for Update not Insert
            // eid: data.serviceEventId,
            // nid: data.serviceEventNoteId,
            orderby: [{ name: "se_apt_start", direction: "desc" }],
         };
         const params = "params=" + JSON.stringify(values);
         const body = params;
         const url = "/insertRequest.sz";
         const contentType = "application/x-www-form-urlencoded";

         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
            if (responseData.st === 1) {
               reset(data); //updates the default values to new values, not sure if it needed
               resetFormDirtyState(); // This will close the modal without call handleClose();
               triggerToast("success", "Note was successfully saved.", false, "", false, 0);
            } else {
               if (responseData.m_en) {
                  triggerToast("warning", responseData.m_en, false, "", false, 0);
               } else {
                  triggerToast("error", "It appears your session has expired, please Sign In", false, "", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "An error has occurred, If the problem persists contact support", false, "", false, 0);
         }

         console.log("Submit Success");
      } catch (error) {
         triggerToast("error", "Note was not saved.");
         console.log("Submit Error");
      }
   };

   useEffect(() => {
      console.log("Form Errors:", errors); // Debugging log

      console.log("Is Submitted:", isSubmitted); // Debugging log
      console.log("Is Submitting:", isSubmitting); // Debugging log
   }, [errors, isSubmitted, isSubmitting]);

   useEffect(() => {
      setMode(eMode);
      //setClients(getClients(eData, eMode));
      //setTreatments(getTreatments(eData, eMode));
   }, [eData, eMode]);

   const watchTreatmentNoteType = watch("treatmentNoteType");
   const watchTreatmentNoteDate = watch("treatmentNoteDate");
   const watchTreatmentNoteTime = watch("treatmentNoteTime");
   //const watchTreatmentNote = watch("treatmentNote");

   useEffect(() => {
      const isFormDirty = Object.values(dirtyFields || {}).some((isDirty) => isDirty);
      handleFormChange(isFormDirty);
      console.log("FORM IS DIRTY:", isFormDirty);
   }, [watchTreatmentNoteType, watchTreatmentNoteDate, watchTreatmentNoteTime]);

   const onHandleButtonClose = (e) => {
      console.log("cancel form dirty fields:", dirtyFields, isDirty);
      console.log("cancel form dirty fields 2:", Object.keys(dirtyFields).length > 0);
      if (isFormDirty && !isSubmitting) {
         console.log("Trying to exist on a field change");
         e.preventDefault();
         // } else {
         //    setShow(false);
      }
      //console.log(
      //   Object.values(dirtyFields || {}).some((isDirty) => {
      //      console.log("LOOP DIRTY:", dirtyFields);
      //      return isDirty;
      //   })
      //);
      handleClose();
   };

   return (
      <>
         <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Row>
               <Col className="event-body ps-3 pe-3">
                  <ClientModalHeader name={clientName} category={action} eMode={eMode} eData={eData} />
               </Col>
            </Row>
            <Row style={{ flex: "1", overflowY: "auto" }}>
               <div className="col ref={colRef} ckeditor-bc">
                  <Form ref={formRef} id="treatmentEventNoteForm" type="submit" onSubmit={handleSubmit(onSubmit)}>
                     <div className="card-bc card border-0 ">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Treatment Note'" }}>
                           <Row className="g-0 mt-3">
                              <Col>
                                 <Controller name="treatmentId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                 <Controller name="serviceEventNoteId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                 <Controller name="serviceEventId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                 <Controller
                                    control={control}
                                    name="treatmentNote"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                       <div className="ckeditor-expand">
                                          <CKEditor
                                             editor={ClassicEditor}
                                             name="treatmentNote"
                                             onReady={(editor) => {
                                                editorRef.current = editor;
                                                if (colHeight) {
                                                   console.log("Set Editor Height:", colHeight);
                                                   editor.ui.view.editable.element.style.height = `${colHeight - 300}px`;
                                                }
                                             }}
                                             // config={{
                                             //    removePlugins: ["CKFinderUploadAdapter", "CKBox", "CKFinder", "CloudServices", "EasyImage", "Image", "ImageCaption", "ImageStyle", "ImageToolbar", "ImageUpload", "Link", "MediaEmbed", "PictureEditing", ],
                                             // }}
                                             data={value}
                                             onChange={(event, editor) => {
                                                const data = editor.getData().trim();
                                                //setValue("treatmentNote", data);
                                                onChange(data);
                                                if (errors.treatmentNote) {
                                                   clearErrors("treatmentNote"); // Clear the error if present
                                                }
                                             }}
                                             onBlur={(event, editor) => {
                                                console.log("Blur.", editor);
                                                //const editorElement = editor.ui.view.editable.element;
                                                //editorElement.style.height = `${colHeight - 300}px`;
                                             }}
                                             onFocus={(event, editor) => {
                                                console.log("Set Editor Height:", colHeight);
                                                //const editorElement = editor.ui.view.editable.element;
                                                //editorElement.style.height = `${colHeight - 300}px`;
                                             }}
                                          />{" "}
                                          {errors.treatmentNote?.message && <div className="text-danger">{errors.treatmentNote.message}</div>}
                                       </div>
                                    )}
                                 />
                                 {/* {console.log(ClassicEditor.builtinPlugins.map((plugin) => plugin.pluginName))} */}
                              </Col>
                           </Row>
                           <Row className="g-0 mt-3 justify-content-center">
                              <Col className="pe-3">
                                 <Controller
                                    name="treatmentNoteDate"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                       <>
                                          <label className="form-label" htmlFor="treatmentNoteDate">
                                             Date
                                          </label>

                                          <DatePicker
                                             dateFormat="yyyy-MM-dd"
                                             id="treatmentNoteDate"
                                             selected={field.value}
                                             onChange={(date) => field.onChange(date)}
                                             className="form-control picker"
                                          />
                                          {errors["treatmentNoteDate"] && <div className="text-danger">{errors["treatmentNoteDate"].message}</div>}
                                       </>
                                    )}
                                 />
                              </Col>
                              <Col className="pe-3">
                                 <Controller
                                    name="treatmentNoteTime"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                       <>
                                          {console.log("TREATMENTNOTETIME:", field.value)}
                                          <label className="form-label" htmlFor="treatmentNoteTime">
                                             Time
                                          </label>
                                          <DatePicker
                                             //selected={field.value}
                                             selected={field.value}
                                             onChange={(date) => field.onChange(date)}
                                             showTimeSelect
                                             id="treatmentNoteTime"
                                             showTimeSelectOnly
                                             timeIntervals={30}
                                             timeCaption="Time"
                                             dateFormat="h:mm aa"
                                             className="form-control picker"
                                          />
                                       </>
                                    )}
                                 />
                              </Col>
                              <Col>
                                 <Form.Group controlId="treatmentNoteType-9">
                                    <fieldset>
                                       <legend style={{ fontSize: "14px" }}>Note type:</legend>
                                       <Col>
                                          <Controller
                                             name="treatmentNoteType"
                                             control={control}
                                             defaultValue={0}
                                             type="number"
                                             render={({ field }) => (
                                                <>
                                                   <Form.Check
                                                      type="radio"
                                                      id="treatmentNoteType-9"
                                                      label="Telephone"
                                                      value="9"
                                                      checked={field.value === 9}
                                                      {...field}
                                                      onChange={(e) => {
                                                         if (e.target.checked) {
                                                            //setValue("treatmentNoteType", 9);
                                                            field.onChange(9);
                                                         }
                                                         clearErrors("treatmentNoteType");
                                                      }}
                                                   />
                                                   <Form.Check
                                                      type="radio"
                                                      id="treatmentNoteType-8"
                                                      label="Email"
                                                      value="8"
                                                      checked={field.value === 8}
                                                      {...field}
                                                      onChange={(e) => {
                                                         if (e.target.checked) {
                                                            //setValue("treatmentNoteType", 8);
                                                            field.onChange(8);
                                                         }
                                                         clearErrors("treatmentNoteType");
                                                      }}
                                                   />
                                                   <Form.Check
                                                      type="radio"
                                                      id="treatmentNoteType-7"
                                                      label="Conference Call"
                                                      value="7"
                                                      checked={field.value === 7}
                                                      {...field}
                                                      onChange={(e) => {
                                                         if (e.target.checked) {
                                                            //setValue("treatmentNoteType", 7);
                                                            field.onChange(7);
                                                         }
                                                         clearErrors("treatmentNoteType");
                                                      }}
                                                   />
                                                </>
                                             )}
                                          />
                                          {errors.treatmentNoteType && <div className="text-danger">{errors.treatmentNoteType.message}</div>}
                                       </Col>
                                    </fieldset>
                                 </Form.Group>
                              </Col>
                           </Row>
                        </div>
                     </div>
                     {console.log("FORM Errors:", errors)}
                  </Form>
               </div>
            </Row>
            <Row className="g-0 mt-4 ">
               <Col className="d-flex justify-content-between">
                  <Button variant="outline-secondary" size="lg" className="rounded-pill" onClick={onHandleButtonClose}>
                     Cancel
                  </Button>
                  <Button type="submit" form="treatmentEventNoteForm" disabled={isSubmitting} size="lg" variant="primary" className="rounded-pill">
                     {isSubmitting ? "Saving Note..." : "Save Note"}
                  </Button>
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default TreatmentNoteForm;
