import { useEffect } from "react";
import { useAuth } from "../context/authContext";
const ActivityTracker = ({ timeout = 3000000 }) => {
   let timeoutId;
   const { currentUser, logout } = useAuth();
   const handleActivity = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
         // User is inactive, initiate logout
         if (currentUser) {
            console.log("User signing out ", new Date());
            logout()
               .then(() => {
                  console.log("User signed out successfully.");
               })
               .catch((error) => {
                  console.error("Sign out error:", error);
               });
         }
      }, timeout);
   };
   useEffect(() => {
      const events = ["mousemove", "mousedown", "keydown", "scroll", "touchstart"];
      events.forEach((event) => {
         document.addEventListener(event, handleActivity);
      });
      // Initial setup
      handleActivity();
      return () => {
         events.forEach((event) => {
            document.removeEventListener(event, handleActivity);
         });
      };
      // eslint-disable-next-line
   }, []);
   return null; // This component doesn't render anything visible
};
export default ActivityTracker;
