import { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Offcanvas, Container, Row, Col, Button, Table, Form, Modal } from "react-bootstrap";
import ModalHeader from "./DocumentModalHeader.js";
import { useToast } from "../context/useToast.js";
import useFetchData from "./useFetchDatav3.js";
import { REQUESTSUBJECTS, REQUESTSTATUS } from "../constants/requestsubjects.js";
import { useForm, Controller } from "react-hook-form";
import { zodResolver, anyResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import ClassicEditor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../ckeditor/ckeditor.styles.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { ModalFormContext } from "../context/ModalFormContext";
import "bootstrap-icons/font/bootstrap-icons.css";

const requestSchema = z.object({
   treatmentId: z.string(),
   requestNote: z.string().min(5, { message: "Treatment Note is required." }),
   requestSubject: z.string().refine(
      (value) => {
         const matchingOption = REQUESTSUBJECTS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Request subject selection is required",
      }
   ),
   requestDate: z.date(),
   requestStatus: z.string().refine(
      (value) => {
         const matchingOption = REQUESTSTATUS.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Request status selection is required",
      }
   ),
});

const RequestModal = ({ clientId, clientName, treatmentId, treatmentName, onCloseModal, skin }) => {
   const { isFormDirty, handleFormChange, handleClose, resetFormDirtyState } = useContext(ModalFormContext);
   const [show, setShow] = useState(true);

   const [acceptFile, setAcceptFile] = useState(null);
   const [selectedTID, setTID] = useState(null);
   const [selectedRequestStatus, setSelectedRequestStatus] = useState(null);
   const [selectedSubject, setSelectedSubject] = useState(null);
   const [payloadDocuments, setPayloadDocuments] = useState([]);
   const { triggerToast } = useToast();
   const { fetchData } = useFetchData();
   const [selectedFileType, setSelectedFileType] = useState("");
   const formRef = useRef(null);
   const editorRequestRef = useRef();
   const [isFormValid, setIsFormValid] = useState(true);

   const defaultValues = useMemo(
      () => ({
         treatmentId: selectedTID,
         requestNote: "",
         requestSubject: "",
         requestDate: new Date(),
         requestStatus: "",
      }),
      [selectedTID]
   );

   const {
      control,
      handleSubmit,
      setError,
      methods,
      watch,
      setValue,
      clearErrors,
      formState: { isDirty, dirtyFields, errors, isSubmitting, isSubmitted },
      reset,
   } = useForm({
      resolver: zodResolver(requestSchema),
      defaultValues,
   });

   const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      maxFiles: 1,
      noKeyboard: true,
      accept: { "application/pdf": [".pdf"] },
      onDrop: (acceptedFiles) => {
         setAcceptFile(acceptedFiles);
         console.log("Dropped files:", acceptedFiles); // Default logging
      },
      onDropRejected: (fileRejections) => {
         console.log("Rejected files:", fileRejections);
         const errorMsg = fileRejections[0].errors[0].message;
         triggerToast("warning", errorMsg, false, "", false);
      },
   });

   useEffect(() => {
      setShow(true);
   }, []);

   useEffect(() => {
      setTID(treatmentId);
   }, [treatmentId]);

   const onClose = (e) => {
      setShow(false);
      onCloseModal?.();
   };

   const baseStyle = {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      height: "200px",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
   };
   const focusedStyle = {
      borderColor: "#2196f3",
   };
   const acceptStyle = {
      borderColor: "#00e676",
   };
   const rejectStyle = {
      borderColor: "#ff1744",
   };
   const style = useMemo(
      () => ({
         ...baseStyle,
         ...(isFocused ? focusedStyle : {}),
         ...(isDragAccept ? acceptStyle : {}),
         ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
   );
   const handleFileTypeChange = (e) => {
      setSelectedFileType(e.target.value);
      setIsFormValid(true); // Reset validity when a selection is made
   };
   const handleFormSubmit = () => {
      if (!selectedFileType) {
         setIsFormValid(false);
         return;
      }
      // Handle form submission logic here
      console.log("File attached with type:", selectedFileType);
   };
   const handleFileModalHide = () => {
      console.log("File Prompt Modal hidden!");
      setSelectedFileType("");
   };
   const onSubmit = async (data) => {
      console.log("onSubmit called");
      try {
         console.log("Form Data:", data);
         //
         // params: {"note":[{"id":1,"text_answer":"test test","html_answer":"<p>test test</p>"}],"eid":"1f48ec95-d2fc-42d1-9088-ccc08ba6eb4f","nid":"c9b31788-164e-4740-a64f-516642606274",
         //                   "orderby":[{"name":"se_apt_start","direction":"desc"}]}
         //
         const editor = editorRequestRef.current;
         if (!editor) {
            triggerToast("error", "Error saving the note. Please contact support", false, "", false, 0);
            return;
         }
         // converts html to markdown text. There is also a marked.js library
         const markdownRequestNote = editor.getData(data.requestNote);
         const method = "POST";
         const values = {
            note_text: markdownRequestNote,
            note_html: data.treatmentNote,
            note_type: data.treatmentNoteType,
            note_date: data.treatmentNoteDate,
            note_time: data.treatmentNoteTime,
            cs_id: data.treatmentId,
            // use for Update not Insert
            // eid: data.serviceEventId,
            // nid: data.serviceEventNoteId,
            orderby: [{ name: "se_apt_start", direction: "desc" }],
         };
         const params = "params=" + JSON.stringify(values);
         const body = params;
         const url = "/insertNote.sz";
         const contentType = "application/x-www-form-urlencoded";

         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
            if (responseData.st === 1) {
               reset(data); //updates the default values to new values, not sure if it needed
               resetFormDirtyState(); // This will close the modal without call handleClose();
               triggerToast("success", "Note was successfully saved.", false, "", false, 0);
            } else {
               if (responseData.m_en) {
                  triggerToast("warning", responseData.m_en, false, "", false, 0);
               } else {
                  triggerToast("error", "It appears your session has expired, please Sign In", false, "", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "An error has occurred, If the problem persists contact support", false, "", false, 0);
         }

         console.log("Submit Success");
      } catch (error) {
         triggerToast("error", "Note was not saved.");
         console.log("Submit Error");
      }
   };

   const FilePreview = ({ file }) => {
      const fileType = file[0].type;
      const fileName = file[0].name;

      let icon;
      if (fileType === "application/pdf") {
         icon = <i className="bi bi-file-earmark-pdf" style={{ fontSize: "2rem", color: "red" }}></i>;
      }
      return (
         <div className="d-flex justify-content-center m-0">
            <div className="d-flex flex-column align-items-center m-0">
               {icon}
               <p>{fileName}</p>
            </div>
         </div>
      );
   };

   return (
      <>
         <Offcanvas show={show} onHide={onClose} placement="end" className="offcanvas-width-xl">
            <Offcanvas.Header closeButton className="pb-0" />
            <Offcanvas.Body className="pt-0" style={{ maxHeight: "calc(100vh - 3.5rem)", overflowY: "auto" }}>
               <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <Row>
                     <Col className="event-body ps-3 pe-3">
                        <ModalHeader title="Requests" clientName={clientName} treatmentName={treatmentName} />
                     </Col>
                  </Row>
                  <Row className="d-flex align-items-start">
                     <div className="mt-3 align-self-start " style={{ height: "200px" }}>
                        <div className="" {...getRootProps({ style })}>
                           <input {...getInputProps()} />
                           <p>
                              Drag 'n' drop some <span className="fw-semibold">Pdf</span> files here, or click to select{" "}
                              <span className="fw-semibold">Pdf</span> files
                           </p>
                           {acceptFile && <FilePreview file={acceptFile} />}
                        </div>
                     </div>

                     <div className="align-self-start w-100">
                        <div className="col ref={colRef} ckeditor-bc">
                           <Form ref={formRef} id="requestForm" type="submit" onSubmit={handleSubmit(onSubmit)}>
                              <div className="card-bc card border-0 ">
                                 <div className="card-body border-top border-1" style={{ "--card-body-content": "'Request Note'" }}>
                                    <Row className="g-0 mt-3 justify-content-center">
                                       <Col className="pe-5">
                                          <Controller name="treatmentId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                          <Controller
                                             name="RequestSubject"
                                             control={control}
                                             rules={{ required: "Required" }}
                                             defaultValue={defaultValues.requestSubject}
                                             render={({ field }) => (
                                                <>
                                                   <label htmlFor="requestSubject" className="form-label">
                                                      Request Type
                                                   </label>
                                                   <Select
                                                      inputId="requestSubject"
                                                      {...field}
                                                      value={REQUESTSUBJECTS.find((s) => s.value === field.value)}
                                                      onChange={(selectedOption) => {
                                                         console.log("selectedOption:", selectedOption);
                                                         // set client id
                                                         setSelectedSubject(selectedOption.value);
                                                         field.onChange(selectedOption.value);
                                                      }}
                                                      onBlur={() => field.onBlur()}
                                                      options={REQUESTSUBJECTS}></Select>
                                                   {errors["requestSubject"] && <div className="text-danger">{errors["requestSubject"].message}</div>}
                                                </>
                                             )}
                                          />
                                       </Col>
                                       <Col className="pe-5">
                                          <Controller
                                             name="requestDate"
                                             control={control}
                                             rules={{ required: true }}
                                             render={({ field }) => (
                                                <>
                                                   <label className="form-label" htmlFor="requestDate">
                                                      Request Date By
                                                   </label>
                                                   <DatePicker
                                                      dateFormat="yyyy-MM-dd"
                                                      id="requestDate"
                                                      selected={field.value}
                                                      onChange={(date) => field.onChange(date)}
                                                      className="form-control picker"
                                                   />
                                                   {errors["requestDate"] && <div className="text-danger">{errors["requestDate"].message}</div>}
                                                </>
                                             )}
                                          />
                                       </Col>
                                       <Col>
                                          <Controller
                                             name="requestStatus"
                                             control={control}
                                             rules={{ required: "Required" }}
                                             defaultValue={defaultValues.requestStatus}
                                             render={({ field }) => (
                                                <>
                                                   <label htmlFor="requestStatus" className="form-label">
                                                      Rquest Status
                                                   </label>
                                                   <Select
                                                      inputId="requestStatus"
                                                      {...field}
                                                      value={REQUESTSTATUS.find((s) => s.value === field.value)}
                                                      onChange={(selectedOption) => {
                                                         console.log("selectedOption:", selectedOption);
                                                         // set client id
                                                         setSelectedRequestStatus(selectedOption.value);
                                                         field.onChange(selectedOption.value);
                                                      }}
                                                      onBlur={() => field.onBlur()}
                                                      options={REQUESTSTATUS}></Select>
                                                   {errors["requestStatus"] && <div className="text-danger">{errors["requestStatus"].message}</div>}
                                                </>
                                             )}
                                          />
                                       </Col>
                                    </Row>
                                    <Row className="mt-5">
                                       <Col>
                                          <Controller
                                             control={control}
                                             name="requestNote"
                                             rules={{ required: true }}
                                             render={({ field: { onChange, value } }) => (
                                                <div>
                                                   <CKEditor
                                                      editor={ClassicEditor}
                                                      name="requestNote"
                                                      onReady={(editor) => {
                                                         editorRequestRef.current = editor;
                                                         editor.ui.view.editable.element.style.height = "200px";
                                                      }}
                                                      data={value}
                                                      onChange={(event, editor) => {
                                                         const data = editor.getData().trim();
                                                         setValue("requestNote", data);
                                                         if (errors.treatmentNote) {
                                                            clearErrors("requestNote"); // Clear the error if present
                                                         }
                                                      }}
                                                   />
                                                </div>
                                             )}
                                          />
                                       </Col>
                                    </Row>
                                 </div>
                              </div>
                              {console.log("FORM Errors:", errors)}
                           </Form>
                        </div>
                     </div>
                  </Row>
                  <Row className="g-0 mt-4 ">
                     <Col className="d-flex justify-content-between">
                        <Button variant="outline-secondary" size="lg" onClick={onClose} className="rounded-pill">
                           Cancel
                        </Button>
                        <Button variant="secondary" size="lg" className="rounded-pill">
                           Save Request
                        </Button>
                     </Col>
                  </Row>
               </Container>
            </Offcanvas.Body>
         </Offcanvas>
      </>
   );
};

export default RequestModal;
