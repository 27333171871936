import React from "react";

export default function ReferralId({ data }) {
   console.log("START REFERRALNUMBER");
   const hasCntrolId = data.ca_control_no ? true : false;
   const hasClaimId = data.ca_claim_no ? true : false;
   const hasPolicyId = data.ca_policy_no ? true : false;
   const hasCaseId = data.ca_case_no ? true : false;

   const controlTitles = ["Control ID", "Claim ID", "Policy ID", "Case ID"];
   const controlValues = [];
   if (hasCntrolId) controlValues.push(data.ca_control_no);
   if (hasClaimId) controlValues.push(data.ca_claim_no);
   if (hasPolicyId) controlValues.push(data.ca_policy_no);
   if (hasCaseId) controlValues.push(data.ca_case_no);
   console.log("END REFERRALNUMBER");

   return (
      <>
         {controlTitles.map(
            (title, index) =>
               controlValues[index] && (
                  <div key={title}>
                     <span className="fw-semibold">{title}:</span>
                     <span className="ms-1">{controlValues[index]}</span>
                  </div>
               )
         )}
         {!controlValues.length && <p>No Referral control IDs found.</p>}
      </>
   );
}
