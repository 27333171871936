import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../context/authContext";
import LoadingSpinner from "../components/LoadingSpinner";
import Main from "../layouts/Main";

export default function PrivateRoute() {
   const { isAuthenticated, loading } = useAuth();
   const location = useLocation();

   if (loading) return <LoadingSpinner />;

   if (!isAuthenticated) return <Navigate state={{ from: location }} to="/pages/signin3" />;
   return (
      <Main>
         <Outlet />
      </Main>
   );
}
