import { useState } from "react";
import { Collapse, Row, Col } from "react-bootstrap";
import { EDUCATIONS, ETHNICITY, MARITALSTATUS, CLASS, GENDER, DIAGNOSIS, SECONDARY, MEDICATION, DOSEUNIT } from "../constants/clientConstants";
import ReferralId from "./ReferralId";
import ClientContactDetails from "./ClientContactDetails";
import ClientContactPhones from "./ClientContactPhones";

const CollapsibleContent = ({ title, data, type, isEdit, category, skin }) => {
   console.log("COLLAPSABLECONTENT:", title, data, type);
   const [isOpen, setIsOpen] = useState(type === "client" || type === "referral");

   const cursorStyle = isOpen ? "pointer" : "default";

   const handleToggle = () => setIsOpen(!isOpen);

   const getLabelById = (id, array) => {
      const item = array.find((elem) => elem.value == id);
      return item ? item.label : "";
   };

   const chevronIcon = isOpen ? (
      <i className="ri-arrow-down-s-line ms-auto "></i> // Up chevron when open
   ) : (
      <i className="ri-arrow-up-s-line ms-auto "></i> // Down chevron when closed
   );

   return (
      <>
         {/* CLIENT DETAILS COLLAPSE----------------------------------------------------------------*/}
         {type === "client" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title align-self-center fs-5 d-flex mb-0 cursor-${cursorStyle}`}>
                     {title}
                     <span className="collapseCount">{data.length}</span>
                  </div>
                  <div className="ms-2 d-flex fs-3">{chevronIcon}</div>
               </div>
               <Collapse in={isOpen}>
                  <div className="pt-0">
                     <ul className="list-group">
                        {data && data.c_first_name ? (
                           <ClientContactDetails data={data} isEdit={isEdit} skin={skin} />
                        ) : (
                           /* <li className="list-group-item border-0 ps-2 pe-2 mb-2">
                              <Row>
                                 <Col md={6}>
                                    <div className="d-flex flex-column flex-fill">
                                       <div className="fw-semibold text-nowrap">Name</div>
                                       <div>
                                          {data.c_first_name} {data.c_last_name}
                                       </div>
                                       <div className="mt-2 fw-semibold text-nowrap">Class</div>
                                       <div className="">{getLabelById(data.c_type_id, CLASS)}</div>
                                       <div className="mt-2 fw-semibold text-nowrap">Marital Status</div>
                                       <div className="">{getLabelById(data.c_relationship_status_id, MARITALSTATUS)}</div>
                                       <div className="mt-2 fw-semibold  text-nowrap ">Ethnicity</div>
                                       <div className="">{getLabelById(data.c_ethnicity_id, ETHNICITY)} </div>
                                    </div>
                                 </Col>
                                 <Col md={6}>
                                    <div className="d-flex flex-column flex-fill">
                                       <div className="fw-semibold text-nowrap">Gender</div>
                                       <div>{getLabelById(data.c_gender_id, GENDER)}</div>
                                       <div className="mt-2 fw-semibold text-nowrap">BOB</div>
                                       <div className="">{data.c_dob}</div>
                                       <div className="mt-2 fw-semibold text-nowrap">Age</div>
                                       <div>{data.c_age}</div>
                                       <div className="mt-2 fw-semibold text-nowrap ">Education</div>
                                       <div className="">{getLabelById(data.c_education_id, EDUCATIONS)}</div>
                                    </div>
                                 </Col>
                              </Row>
                           </li> */
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No details found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* PHONE COLLAPSE-------------------------------------------------------------------------*/}
         {type === "phone" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5  align-self-center d-flex mb-0 cursor-${cursorStyle}`}>
                     {title}
                     <span className=" collapseCount">{data.length}</span>
                  </div>
                  <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     <ul className="list-group">
                        {data && data.length > 0 ? (
                           <ClientContactPhones data={data} isEdit={isEdit} skin={skin} />
                        ) : (
                           // data.map((item, index) => (
                           //    <li key={index} className="list-group-item border-0 ps-2 pe-2 mb-2">
                           //       <Row>
                           //          <Col md={6}>
                           //             <div className="d-flex flex-column flex-fill">
                           //                <div className="mt-2 fw-semibold">{item.client_phone_type} Phone</div>
                           //                <div>{item.client_phone}</div>
                           //                <div className="mt-2 fw-semibold text-nowrap">Comment</div>
                           //                <div>{item.client_phone_comment || "No comment"}</div>
                           //             </div>
                           //          </Col>
                           //          <Col md={6}>
                           //             <div className="d-flex flex-column flex-fill">
                           //                <div className="mt-2 fw-semibold text-nowrap">Leave Message</div>
                           //                <div>{item.client_phone_message ? "Yes" : "No"}</div>
                           //                <div className="mt-2 fw-semibold text-nowrap">Appointment Reminder</div>
                           //                <div>{item.client_appointment_reminder ? "Yes" : "No"}</div>
                           //             </div>
                           //          </Col>
                           //       </Row>
                           //    </li>
                           // ))
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No phone found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* EMAIL COLLAPSE--------------------------------------------------------------------*/}
         {type === "email" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>
                     {title}
                     <span className=" collapseCount">{data.length}</span>
                  </div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here */}

                     <ul className="list-group">
                        {data && data.length > 0 ? (
                           data.map((item, index) => (
                              <li key={index} className="list-group-item border-0 ps-2 pe-2 mb-2">
                                 <Row>
                                    <Col md={6}>
                                       <div className="d-flex flex-column flex-fill">
                                          <div className="mt-2 fw-semibold ">{item.client_email_type} Email</div>
                                          <div>{item.client_email}</div>
                                          <div className="mt-2 fw-semibold text-nowrap">Comment</div>
                                          <div>{item.client_email_comment || "No comment"}</div>
                                       </div>
                                    </Col>
                                    <Col md={6}>
                                       <div className="d-flex flex-column flex-fill">
                                          <div className="mt-2 fw-semibold text-nowrap">Appointment Reminder:</div>
                                          <div>{item.client_appointment_reminder ? "Yes" : "No"}</div>
                                       </div>
                                    </Col>
                                 </Row>
                              </li>
                           ))
                        ) : (
                           //   <li key="nophone">No Phone found</li>
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No email found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* ADDRESS COLLAPSE -------------------------------------------------------------------- */}
         {type === "address" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>
                     {title}
                     <span className=" collapseCount">{data.length}</span>
                  </div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here */}

                     <ul className="list-group">
                        {data && data.length > 0 ? (
                           data.map((item, index) => (
                              <li key={index} className="list-group-item border-0 ps-2 pe-2">
                                 <div className="row">
                                    <div className="col-12">
                                       <div className="d-flex justify-content-between">
                                          <div>
                                             <div className="">{item.client_address_address}</div>
                                             <div>
                                                {item.client_address_city}, {item.client_address_province} {item.client_address_postal}
                                             </div>
                                             <div>{item.client_address_country}</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           ))
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No address found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* REFERALL COLLAPSE ----------------------------------------------------------------------------------------- */}
         {type === "referral" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{data.cu_name}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data && data.cu_contact_data ? (
                           <li key="referralKey" className="list-group-item border-0 ps-2 pe-2">
                              <div className="row">
                                 <div className="d-flex justify-content-between">
                                    <ul className="list-group">
                                       <ReferralId data={data} />
                                    </ul>
                                 </div>
                              </div>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No referral found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* REFERALL PHONE ----------------------------------------------------------------------------------------- */}
         {type === "referralphone" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="referralKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              <Row>
                                 <Col md={12}>
                                    <div className="d-flex flex-column flex-fill">
                                       <div className="d-flex justify-content-between">
                                          {data.length > 0 && (
                                             <div>
                                                {data.map((item, index) => (
                                                   <div key={`phone-${index}`} className="mb-2">
                                                      <div className="fw-semibold">Phone:</div>
                                                      <div>{item.number}</div>
                                                   </div>
                                                ))}
                                             </div>
                                          )}
                                       </div>
                                    </div>
                                 </Col>
                              </Row>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No referral phone found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* REFERALL EMAIL COLLAPSE ----------------------------------------------------------------------------------------- */}
         {type === "referralemail" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="referralKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              <Row>
                                 <Col md={12}>
                                    <div className="d-flex flex-column flex-fill">
                                       {data.length > 0 && (
                                          <div>
                                             {data.map((email, index) => (
                                                <div key={`email-${index}`} className="mb-2">
                                                   <div className="fw-semibold">Email:</div>
                                                   <div>{email.email}</div>
                                                   {email.comment && (
                                                      <div className="mt-2">
                                                         <div className="fw-semibold">Comment:</div>
                                                         <div>{email.comment}</div>
                                                      </div>
                                                   )}
                                                </div>
                                             ))}
                                          </div>
                                       )}
                                    </div>
                                 </Col>
                              </Row>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No referral email found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* REFERALL ADDRESS COLLAPSE ----------------------------------------------------------------------------------------- */}
         {type === "referraladdress" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="referralKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              <Row>
                                 <Col md={12}>
                                    <div className="d-flex flex-column flex-fill">
                                       {data.length > 0 && (
                                          <ul className="list-unstyled">
                                             {data.map((address, index) => (
                                                <li key={`address-${index}`} className="mb-2">
                                                   <div>{address.address}</div>
                                                   <div>
                                                      {address.city}, {address.province}, {address.postal}
                                                   </div>
                                                   <div>{address.country}</div>
                                                </li>
                                             ))}
                                          </ul>
                                       )}
                                    </div>
                                 </Col>
                              </Row>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No referral address found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* REFERALL FAX COLLAPSE ----------------------------------------------------------------------------------------- */}
         {type === "referralfax" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="referralKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              <Row>
                                 <Col md={12}>
                                    <div className="d-flex flex-column flex-fill">
                                       {data.length > 0 && (
                                          <div>
                                             {data.map(
                                                (fax, index) =>
                                                   fax.number && (
                                                      <div key={`fax-${index}`} className="mb-2">
                                                         <div className="fw-semibold">Fax:</div>
                                                         <div>{fax.number}</div>
                                                      </div>
                                                   )
                                             )}
                                          </div>
                                       )}
                                    </div>
                                 </Col>
                              </Row>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No referral fax found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* EMPLOYMENT ----------------------------------------------------------------------------------------- */}
         {type === "employmentdetails" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="employmentKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              {data.length > 0 && (
                                 <>
                                    {data.map((item, index) => (
                                       <div key={`${item.id}-${index}`} className="mb-3">
                                          <Row>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className=" mt-2 fw-semibold">Company</div>
                                                   <div>{item.company_name}</div>
                                                   <div className="mt-2 fw-semibold">Title</div>
                                                   <div> {item.company_title}</div>
                                                </div>
                                             </Col>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className="my-2 fw-semibold">Employed since</div>
                                                   <div>{item.employed_since_date}</div>
                                                   <div className="mt-2 fw-semibold">Employed since(text)</div>
                                                   <div> {item.employed_since_text}</div>
                                                </div>
                                             </Col>
                                          </Row>
                                       </div>
                                    ))}
                                 </>
                              )}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No employment found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* EMPLOYMENT CONTACT ----------------------------------------------------------------------------------------- */}

         {type === "employmentcontact" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="employmentKey" className="list-group-item border-0 ps-2 pe-2">
                              {data.length > 0 && (
                                 <>
                                    {data.map((item, index) => (
                                       <div key={`${item.id}-${index}`}>
                                          <Row>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className="mt-2 fw-semibold">Name</div>
                                                   <div className="">{item.company_contact_name}</div>
                                                   <div className="mt-2 fw-semibold">Phone</div>
                                                   <div className="">{item.company_contact_phone}</div>
                                                </div>
                                             </Col>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className="mt-2 fw-semibold">Email</div>
                                                   <div>{item.company_contact_email}</div>
                                                </div>
                                             </Col>
                                          </Row>
                                       </div>
                                    ))}
                                 </>
                              )}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No employment contact found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* DOCTOR DETAILS ----------------------------------------------------------------------------------------- */}

         {type === "doctordetails" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="doctorKey" className="list-group-item border-0 ps-2 pe-2">
                              <div className="row">
                                 <div className="d-flex justify-content-between">
                                    {data.length > 0 && (
                                       <>
                                          {" "}
                                          {/* Wrap the content in another JSX fragment */}
                                          {data.map((item, index) => (
                                             <div key={`${item.id}-${index}`}>
                                                <div className="">{item.doctor_name}</div>
                                             </div>
                                          ))}
                                       </>
                                    )}
                                 </div>
                              </div>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No doctor details found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* DOCTOR CONTACT ----------------------------------------------------------------------------------------- */}

         {type === "doctorcontact" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="doctorKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              {data.length > 0 && (
                                 <>
                                    {data.map((item, index) => (
                                       <div key={`${item.id}-${index}`} className="mb-3">
                                          <Row>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className=" mt-2 fw-semibold">Phone</div>
                                                   <div>{item.doctor_contact_phone}</div>
                                                   <div className="mt-2  fw-semibold">Phone Message</div>
                                                   <div>{item.doctor_contact_phone_text}</div>
                                                </div>
                                             </Col>
                                             <Col md={6}>
                                                <div className="d-flex flex-column flex-fill">
                                                   <div className="mt-2 fw-semibold">Email</div>
                                                   <div className="">{item.doctor_contact_email}</div>
                                                   <div className="mt-2 fw-semibold">Email Message</div>
                                                   <div>{item.doctor_contact_email_text}</div>
                                                   <div className="mt-2 fw-semibold">Fax</div>
                                                   <div>{item.doctor_contact_fax}</div>
                                                </div>
                                             </Col>
                                          </Row>
                                       </div>
                                    ))}
                                 </>
                              )}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No Doctor contact found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {/* DOCTOR ADDRESS ----------------------------------------------------------------------------------------- */}

         {type === "doctoraddress" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="doctorKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              {data.length > 0 && (
                                 <>
                                    {data.map((item, index) => (
                                       <div key={`${item.id}-${index}`} className="mb-3">
                                          <Row>
                                             <Col>
                                                <div className="flex-column flex-fill">
                                                   <div>{item.doctor_address}</div>
                                                   <div>
                                                      {item.doctor_city},{item.doctor_province},{item.doctor_postal}
                                                   </div>
                                                   <div>{item.doctor_country}</div>
                                                </div>
                                             </Col>
                                          </Row>
                                       </div>
                                    ))}
                                 </>
                              )}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No Doctor address found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {type === "diagnosis" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="doctorKey" className="list-group-item border-0 ps-2 pe-2 mb-2">
                              {data.length > 0 && (
                                 <Row>
                                    {data.map((item, index) => (
                                       <Col md={6} key={`${item.id}-${index}`}>
                                          <div className="mb-3">
                                             <div className="mt-2 fw-semibold">Diagnosis</div>
                                             <div className="">{getLabelById(item.diagnosis_id, DIAGNOSIS)}</div>
                                             <div className="mt-2 fw-semibold">{item.diagnosis_type}</div>
                                             <div className="">{item.diagnosis_text}</div>
                                             <div className="mt-2 fw-semibold">Date of onset</div>
                                             <div>{item.diagnosis_dateofonset}</div>
                                          </div>
                                       </Col>
                                    ))}
                                 </Row>
                              )}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No diagnosis found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {type === "secondary" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="doctorKey" className="list-group-item border-0 ps-2 pe-2">
                              <Row>
                                 {data.length > 0 && (
                                    <>
                                       {data.map((item, index) => (
                                          <Col md={6} key={`${item.id}-${index}`} className="mb-3">
                                             <div className="d-flex flex-column flex-fill">
                                                <div className="fw-semibold">Treatment</div>
                                                <div>{getLabelById(item.secondary_treatment_id, SECONDARY)}</div>
                                             </div>
                                          </Col>
                                       ))}
                                    </>
                                 )}
                              </Row>
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No secondary treatment found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
         {type === "medications" && (
            <>
               <div onClick={handleToggle} className="d-flex justify-content-between cursor-pointer">
                  <div className={`position-relative card-title fs-5 d-flex mb-0  align-self-center cursor-${cursorStyle}`}>{title}</div>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"></span> */}
                  <div>
                     <div className="ms-2 d-flex fs-3 ">{chevronIcon}</div>
                  </div>
               </div>

               <Collapse in={isOpen}>
                  <div className="pt-0">
                     {/* Display content here -*/}

                     <ul className="list-group">
                        {data ? (
                           <li key="medicationKey" className="list-group-item border-0 ps-2 pe-2">
                              {data.map((item, index) => (
                                 <Row className="mb-3">
                                    <Col md={6}>
                                       <div className="d-flex flex-column flex-fill">
                                          <div className="mt-2 fw-semibold">Medication</div>
                                          <div className=""> {getLabelById(item.medication_id, MEDICATION)}</div>

                                          <div className="mt-2 fw-semibold">Other Medication</div>
                                          <div>{item.medication_text}</div>
                                       </div>
                                    </Col>
                                    <Col md={6}>
                                       <div className="d-flex flex-column flex-fill">
                                          <div className="mt-2 fw-semibold">Dose</div>
                                          <div className="">
                                             {item.medication_dose} {getLabelById(item.medication_dose_unit, DOSEUNIT)}{" "}
                                          </div>
                                       </div>
                                    </Col>
                                 </Row>
                              ))}
                           </li>
                        ) : (
                           <li key="nophone" className="list-group-item border-0 ps-2 pe-2">
                              No medication found
                           </li>
                        )}
                     </ul>
                  </div>
               </Collapse>
            </>
         )}
      </>
   );
};

export default CollapsibleContent;
