import { useState, useEffect } from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import InitialsAvatar from "./InitialsAvatar";

const AppointmentModalHeader = ({ eData, appDate, eMode }) => {
   let splitText = "";
   let isLocked = false;
   let statusText = "";
   let internalDate = moment().format("YYYY-MM-DD");
   let appoinmentDate = appDate ? moment(appDate).format("YYYY-MM-DD") : "";
   let appointmentText = "";
   let eDate = appDate ? moment(appDate).format("MMM DD") : "";
   let eTime = appDate ? moment(appDate).format("h:mm A") : "";
   const statusList = [
      { id: 0, value: "Booked" },
      { id: 1, value: "Booked" },
      { id: 2, value: "Completed" },
      { id: 3, value: "Under Review" },
      { id: 4, value: "Reviewed with Changes" },
      { id: 5, value: "Submitted" },
      { id: 9, value: "Completed with Minor Changes" },
      { id: 6, value: "Decline" },
      { id: 7, value: "Accept" },
      { id: 8, value: "Repeal" },
      { id: 100, value: "Deleted" },
   ];

   if (eData && eData.split_link_no) {
      let otherDate;
      if (eData.other_split_id) {
         otherDate = eData.other_split_apt_date ? moment(eData.other_split_apt_date).format("MMM DD") : "";
         if (eData.split_link_no === 2) {
            splitText = "Initial Session Note on " + otherDate;
         } else {
            splitText = "Follow-up Session Note on " + otherDate;
         }
      } else {
         splitText = "Inital session note requires follow-up";
      }
   }
   if (eData && eData.sessionstatus > 1) {
      isLocked = true;
   }
   if (eData) {
      statusText = statusList.find((item) => item.id === eData.sessionstatus)?.value;
   }

   if (eData && (eData.sessionstatus === 1 || eData.sessionstatus === 0)) {
      if (appoinmentDate) {
         if (appoinmentDate < internalDate) {
            appointmentText = "Past Due";
         } else if (appoinmentDate > internalDate) {
            appointmentText = "";
         } else {
            appointmentText = "Today";
         }
      }
   }

   return (
      <>
         {eData && eData.treatmentname ? (
            <>
               <Row className="d-flex align-items-start">
                  <Col xs="auto">
                     <InitialsAvatar name={eData.clientname} />
                  </Col>
                  <Col>
                     <p className="fs-5 mb-2">
                        <i className="ri-psychotherapy-line pe-2"></i>
                        {eData.treatmentname}{" "}
                        {eDate && (
                           <>
                              <span className="small text-muted">on</span> {eDate} <span className="small text-muted">at</span> {eTime}
                           </>
                        )}
                     </p>
                     <p className="fs-5 mb-0">
                        <i className="{isLocked ? ri-lock-lock-line : ri-lock-unlock-line}"></i>
                        {statusText} {appointmentText}
                     </p>
                  </Col>
                  <Col>
                     <p className="d-flex mb-0">
                        <i className="ri-phone-line pe-2"></i>
                        {eData.phonecontact[0].client_phone}
                     </p>
                     <p className="d-flex mb-0">
                        <i className="ri-mail-line pe-2"></i>
                        {eData.emailcontact[0].client_email}
                     </p>
                     {splitText && (
                        <p className="d-flex mb-0">
                           <i className="ri-share-line pe-2"></i>
                           {splitText}
                        </p>
                     )}
                  </Col>
               </Row>
            </>
         ) : (
            <Row className="d-flex align-items-start">
               <Col>
                  <p className="fs-5 mb-2">New Appointment</p>
               </Col>
            </Row>
         )}
      </>
   );
};

export default AppointmentModalHeader;
