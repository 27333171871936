import React from "react";
import Forbidden from "../pages/Forbidden";
import ForgotPassword from "../pages/ForgotPassword";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import Signin2 from "../pages/Signin2";
import Signin3 from "../pages/Signin3";
import Signup from "../pages/Signup";
import Signup2 from "../pages/Signup2";
import VerifyAccount from "../pages/VerifyAccount";
import LoadingSpinner from "../components/LoadingSpinner";

const publicRoutes = [
   { path: "dashboard/spinner", link: <LoadingSpinner /> },
   { path: "pages/signin", link: <Signin /> },
   { path: "pages/signin2", link: <Signin2 /> },
   { path: "pages/signin3", link: <Signin3 /> },
   { path: "pages/signup", link: <Signup /> },
   { path: "pages/signup2", link: <Signup2 /> },
   { path: "pages/verify", link: <VerifyAccount /> },
   { path: "pages/forgot", link: <ForgotPassword /> },
   { path: "pages/lock", link: <LockScreen /> },
   { path: "pages/error-404", link: <NotFound /> },
   { path: "pages/error-500", link: <InternalServerError /> },
   { path: "pages/error-503", link: <ServiceUnavailable /> },
   { path: "pages/error-505", link: <Forbidden /> },
];

export default publicRoutes;
