import { useState, useEffect, useRef, useReducer } from "react";
import { Offcanvas } from "react-bootstrap";
import AppointmentForm from "../forms/AppointmentForm";
import AppointmentView from "../views/AppointmentView";
import ModalDialogue from "./modalDialogue";

//use this for the modal dialgue its an object
const initialModalDialgueState = {
   isOpen: false,
   question: "",
};

// reducer function which takes 2 arguments
const reducer = (state, action) => {
   //switch is another way to perform if else if nothing matches it goes to default
   switch (action.type) {
      case "OPEN_MODAL":
         return { ...state, isOpen: true, question: action.payload.question };
      case "CLOSE_MODAL":
         return { ...state, isOpen: false };
      default:
         return state;
   }
};

const AppointmentModal = ({ appointmentId, appointmentTitle, appointmentStartDate, eData, eMode, onCloseModal, skin }) => {
   // const initialFormData = {
   //    appointmentStatus: appointmentData.appointmentstatus,
   //    appointmentLocation: appointmentData.location_id,
   //    appointmentDate: appointmentStart,
   //    appointmentDuration: appointmentData.sessionduration,
   //    appointmentTreatment: appointmentData.treatmentname,
   //    appointmentClientName: appointmentData.clientname,
   //    appointmentClientId: appointmentData.clientid,
   //    appointmentRepeat: appointmentData.repeatConfig,
   //    appointmentHasNote: appointmentData.hasnote,
   //    appointmentStatus: appointmentData.sessionstatus,
   //    appointmentSplitEnabled: appointmentData.treatment_split_session_enabled,
   //    appointmentSplitActive: appointmentData.treatment_split_session_active,
   //    appointmentSplitCount: appointmentData.treatment_split_session_count,
   //    appointmentSplitNo: appointmentData.split_link_no,
   //    appointmentSplitId: appointmentData.other_split_id,
   //    appointmentSplitAltDate: appointmentData.other_split_apt_date,
   //    appointmentClientEmail: appointmentData.client_email,
   //    appointmentClientType: appointmentData.client_email_type,
   //    appointmentEmailComment: appointmentData.client_email_comment,
   //    appointmentEmailReminder: appointmentData.client_appointment_reminder,
   //    appointmentClientPhone: appointmentData.client_phone,
   //    appointmentClientPhoneType: appointmentData.client_phone_type,
   //    appointmentClientPhoneComment: appointmentData.client_phone_comment,
   //    appointmentClientPhoneMessage: appointmentData.client_phone_message,
   //    appointmentClientPhoneReminder: appointmentData.client_appointment_reminder,
   //    appointmentIsLocked: appointmentData.islocked,
   //    appointmentNoofSessions: appointmentData.noofsessions,
   // };
   //const [clientInitials, setClientInitals] = useState("");
   const [editMode, setEditMode] = useState(false);
   const [show, setShow] = useState(true);
   const [formIsDirty, setFormIsDirty] = useState(false);
   // We use this as a flag to determine if the offcanvas is in the procsess of closing due to user interaction (clickingclose button or backdrop)
   const isExitingRef = useRef(false);
   // We use useReducer because it can manage more complex state logic
   const [modalDialgueState, dispatchModal] = useReducer(reducer, initialModalDialgueState); // Start of Modal Dialogue state
   const { isOpen, question } = modalDialgueState; // Destructure modal state

   const editButtonClick = () => {
      setEditMode(true);
      setFormIsDirty(false);
   };

   const handleFormIsDirty = (value) => {
      console.log("PARENT Signal form is dirty value:", value);
      setFormIsDirty(value);
   };

   // the eMode manages if the AppointmentForm component is existing appointment or a new appointment
   // eMode is a prop passed from the Home page which has the Calendar component. If we click on an appointment its edit otherwise its new
   // we set the show variable to true
   useEffect(() => {
      if (eMode === "edit" || eMode === "new") {
         setShow(true);
         if (eMode === "new") {
            setEditMode(true);
         }
      } else {
         setShow(false);
      }
   }, [eMode]);

   // Callback fired right before the offcanvas transitions out
   const handleExit = () => {
      console.log("Offcanvas has exited 1", isExitingRef.current);
   };
   // Callback fired as the offcanvas begins to transition out
   const handleExiting = () => {
      console.log("Offcanvas has exiting 2", isExitingRef.current);
   };
   // Callback fired after the offcanvas finishes transitioning out
   const handleExited = () => {
      console.log("Offcanvas has exited 3", isExitingRef.current);
   };
   //This function is called when the AppointmentForm component is closing
   const onClose = (e) => {
      isExitingRef.current = true;
      setShow(false);
      console.log("Offcanvas has onClose");
      onCloseModal?.();
   };

   //This function is called when the Offcanvas component has been hidden
   const onHandleHide = (e) => {
      if (formIsDirty) {
         // Open modal with appropriate question
         dispatchModal({ type: "OPEN_MODAL", payload: { question: "Are you sure you want to discard changes?" } });
      } else {
         dispatchModal({ type: "CLOSE_MODAL", payload: { question: "" } });
         //we are here which means the offcanvas is hidden so set the ref to true
         isExitingRef.current = true;
         setShow(false);
         console.log("Offcanvas has onClose");
         onCloseModal?.();
      }
   };

   // we listen for the modal dialgue response which is a callback from ModalDialgue
   const handleModalResponse = (response) => {
      if (response === "discard") {
         // Your logic for discarding changes
         isExitingRef.current = true;
         setShow(false);
         console.log("Offcanvas has onClose");
         onCloseModal?.();
      } else if (response === "go_back") {
         dispatchModal({ type: "CLOSE_MODAL", payload: { question: "" } });
      }
      dispatchModal({ type: "CLOSE_MODAL" }); // Close modal after handling response
   };

   return (
      <>
         <Offcanvas
            show={show}
            onHide={onHandleHide}
            onExiting={handleExiting}
            onExit={handleExit}
            onExited={handleExited}
            placement="end"
            className="offcanvas-width-xl">
            <Offcanvas.Header closeButton className="pb-0" />
            <Offcanvas.Body className="pt-0" style={{ maxHeight: "calc(100vh - 3.5rem)", overflowY: "auto" }}>
               {console.log("Body isExitingRef.current", isExitingRef.current)}
               {eData && !isExitingRef.current ? (
                  editMode ? (
                     <AppointmentForm
                        appointmentId={appointmentId}
                        appointmentTitle={appointmentTitle}
                        appointmentStartDate={appointmentStartDate}
                        eData={eData}
                        eMode={eMode}
                        onCloseModal={onClose}
                        formIsDirty={handleFormIsDirty}
                        skin={skin}
                     />
                  ) : (
                     <AppointmentView appointmentStartDate={appointmentStartDate} eData={eData} eMode={eMode} onEditButtonClick={editButtonClick} />
                  )
               ) : !isExitingRef.current ? (
                  <>
                     {console.log("RETURN BACK: ", eData)}
                     <h5>No appointment details available.</h5>
                  </>
               ) : null}
            </Offcanvas.Body>
         </Offcanvas>
         <ModalDialogue isOpen={isOpen} question={question} onModalDialogueResponse={handleModalResponse} />
      </>
   );
};

export default AppointmentModal;
