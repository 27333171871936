import { createContext, useState, useEffect, useReducer } from "react";
import ModalDialogue from "../components/modalDialogue";

const ModalFormContext = createContext({
   isOpen: false,
   isFormDirty: false,
   handleBackdropClick: () => {},
   handleClose: () => {}, // Define handleClose before context value
   handleFormChange: (isDirty) => {},
   handleOpen: () => {},
   resetFormDirtyState: () => {},
});

const initialModalDialogueState = {
   isOpen: false,
   question: "",
};

const reducer = (state, action) => {
   switch (action.type) {
      case "OPEN_MODAL":
         return { ...state, isOpen: true, question: action.payload.question };
      case "CLOSE_MODAL":
         return { ...state, isOpen: false };
      default:
         return state;
   }
};

const ModalFormContextProvider = ({ children }) => {
   const [isOpen, setIsOpen] = useState(false);
   const [isFormDirty, setIsFormDirty] = useState(false);
   const [requestFormReset, setRequestFormReset] = useState(false);
   const [modalDialgueState, dispatchModal] = useReducer(reducer, initialModalDialogueState); // Start of Modal Dialogue state
   const { isOpen: isDialogueOpen, question } = modalDialgueState;

   // Define handleCloseModal with optional confirmation logic
   const handleCloseModal = () => {
      console.log("ModalFormContextProvider - CLOSE MODEL");
      if (isFormDirty) {
         // Open modal with appropriate question
         dispatchModal({ type: "OPEN_MODAL", payload: { question: "Are you sure you want to discard changes?" } });
         return;
      }
      // Confirmation logic (if needed)
      setIsOpen(false);
   };

   const handleOpen = () => {
      console.log("ModalFormContextProvider - OPEN MODEL");
      setIsOpen(true);
   };

   const handleBackdropClick = () => {
      console.log("ModalFormContextProvider - BACKDROP CLICKED");
      handleCloseModal(); // Optional check if handleClose exists before calling
   };

   const handleFormChange = (isDirty) => {
      console.log("ModalFormContextProvider -FORM HAS CHANGED");
      setIsFormDirty(isDirty);
   };

   const resetFormDirtyState = () => {
      setIsFormDirty(false);
      setRequestFormReset(true); // Mark that we want to reset the form and close the modal
   };

   useEffect(() => {
      if (!isFormDirty && requestFormReset) {
         handleCloseModal();
         setRequestFormReset(false); // Reset the request form reset state
      }
   }, [isFormDirty, requestFormReset]);

   const handleModalDialogueResponse = (response) => {
      if (response === "discard") {
         setIsFormDirty(false);
         setIsOpen(false);
      }
      dispatchModal({ type: "CLOSE_MODAL" });
   };

   return (
      <ModalFormContext.Provider
         value={{
            isOpen,
            isFormDirty,
            handleBackdropClick,
            handleClose: handleCloseModal, // Use defined handleCloseModal
            handleFormChange,
            handleOpen,
            resetFormDirtyState, // set the dirty state to false after form saved successfully
         }}>
         {console.log("MODALFORMPROVIDER:", isOpen, isFormDirty)}
         {children}
         {isDialogueOpen && <ModalDialogue isOpen={isDialogueOpen} question={question} onModalDialogueResponse={handleModalDialogueResponse} />}
      </ModalFormContext.Provider>
   );
};

export default ModalFormContextProvider;
export { ModalFormContext }; // Export the context object itself
