import React from "react";
import { Collapse, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { EDUCATIONS, ETHNICITY, MARITALSTATUS, CLASS, GENDER, DIAGNOSIS, SECONDARY, MEDICATION, DOSEUNIT } from "../constants/clientConstants";
import ReferralId from "./ReferralId";
import Select from "react-select";
import { customSelectStyles } from "../forms/customSelectStyles";

const ClientContactDetails = ({ data, isEdit, skin }) => {
   const getLabelById = (id, array) => {
      const item = array.find((elem) => elem.value == id);
      return item ? item.label : "";
   };

   return (
      // Contact Details
      <li className="list-group-item border-0 ps-2 pe-2 mb-2">
         <Row>
            {isEdit ? (
               <>
                  <Col md={6}>
                     <div className="d-flex flex-column flex-fill">
                        <Row>
                           <Col md={8} className="pe-1">
                              <Form.Label htmlFor="c_last_name">Last Name</Form.Label>
                              <Form.Control id="c_last_name" name="c_last_name" type="text" placeholder="Last Name" value={data.c_last_name} />
                           </Col>
                           <Col md={4} className="ps-0">
                              <Form.Label htmlFor="c_first_name">First Name</Form.Label>
                              <Form.Control id="c_first_name" name="c_first_name" type="text" placeholder="First Name" value={data.c_first_name} />
                           </Col>
                        </Row>
                        <Form.Label className="mt-2" htmlFor="clientClass">
                           Class
                        </Form.Label>
                        <Select inputId="clientClass" styles={customSelectStyles(skin)} options={CLASS} value={CLASS.find((s) => s.value === data.c_type_id)} />
                        <Form.Label className="mt-2" htmlFor="clientMaritalStatus">
                           Marital Status
                        </Form.Label>
                        <Select
                           inputId="clientMaritalStatus"
                           styles={customSelectStyles(skin)}
                           options={MARITALSTATUS}
                           value={MARITALSTATUS.find((s) => s.value === data.c_relationship_status_id)}
                        />
                        <Form.Label className="mt-2" htmlFor="clientEthnicity">
                           Ethnicity
                        </Form.Label>
                        <Select
                           inputId="clientEthnicity"
                           styles={customSelectStyles(skin)}
                           options={ETHNICITY}
                           value={ETHNICITY.find((s) => s.value === data.c_ethnicity_id)}
                        />
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="d-flex flex-column flex-fill">
                        <Form.Label className="" htmlFor="clientGender">
                           Gender
                        </Form.Label>
                        <Select
                           inputId="clientGender"
                           styles={customSelectStyles(skin)}
                           options={GENDER}
                           value={GENDER.find((s) => s.value === data.c_gender_id)}
                        />
                        <Form.Label className="mt-2" htmlFor="clientDOB">
                           DOB
                        </Form.Label>
                        <Form.Control id="clientDOB" name="clientDOB" type="date" value={data.c_dob} />
                        <Form.Label className="mt-2" htmlFor="clientAge">
                           Age
                        </Form.Label>
                        <Form.Control id="clientAge" name="clientAge" type="number" placeholder="Age" value={data.c_age} />
                        <Form.Label className="mt-2" htmlFor="clientEducation">
                           Education
                        </Form.Label>
                        <Select
                           inputId="clientEducation"
                           styles={customSelectStyles(skin)}
                           options={EDUCATIONS}
                           value={EDUCATIONS.find((s) => s.value === data.c_education_id)}
                        />
                     </div>
                  </Col>
               </>
            ) : (
               <>
                  <Col md={6}>
                     <div className="d-flex flex-column flex-fill">
                        <div className="fw-semibold text-nowrap">Name</div>
                        <div>
                           {data.c_first_name} {data.c_last_name}
                        </div>
                        <div className="mt-2 fw-semibold text-nowrap">Class</div>
                        <div className="">{getLabelById(data.c_type_id, CLASS)}</div>
                        <div className="mt-2 fw-semibold text-nowrap">Marital Status</div>
                        <div className="">{getLabelById(data.c_relationship_status_id, MARITALSTATUS)}</div>
                        <div className="mt-2 fw-semibold  text-nowrap ">Ethnicity</div>
                        <div className="">{getLabelById(data.c_ethnicity_id, ETHNICITY)} </div>
                     </div>
                  </Col>
                  <Col md={6}>
                     <div className="d-flex flex-column flex-fill">
                        <div className="fw-semibold text-nowrap">Gender</div>
                        <div>{getLabelById(data.c_gender_id, GENDER)}</div>
                        <div className="mt-2 fw-semibold text-nowrap">BOB</div>

                        <div className="">{data.c_dob}</div>
                        <div className="mt-2 fw-semibold text-nowrap">Age</div>
                        <div>{data.c_age}</div>
                        <div className="mt-2 fw-semibold text-nowrap ">Education</div>
                        <div className="">{getLabelById(data.c_education_id, EDUCATIONS)}</div>
                     </div>
                  </Col>
               </>
            )}
         </Row>
      </li>
   );
};
export default ClientContactDetails;
