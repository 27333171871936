function ClientAge({ dob, age }) {
   // Calculate age from dob if not provided
   const calculatedAge = dob ? calculateAgeFromDob(dob) : null;

   // Use calculatedAge or provided age, prioritizing calculatedAge
   const displayedAge = calculatedAge !== null && calculatedAge !== undefined ? calculatedAge : age;

   return displayedAge !== null && displayedAge !== undefined ? (
      <>{displayedAge} years old</>
   ) : (
      <></> // Render nothing if no age information is available
   );
}

function calculateAgeFromDob(dobString) {
   // Handle potential invalid date strings gracefully
   try {
      const dobDate = new Date(dobString);
      const today = new Date();
      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDifference = today.getMonth() - dobDate.getMonth();

      // Adjust for incomplete months
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
         age--;
      }

      return age;
   } catch (error) {
      console.error("Error parsing date of birth:", error);
      return null; // Return null for invalid dates to avoid displaying incorrect information
   }
}

export default ClientAge;
