import "../assets/svg/spinner_crh_style.css";
import { ReactComponent as Logo } from "../assets/svg/spinner_crh.svg";
const LoadingSpinner = () => {
   return (
      <div className="spinner-overlay">
         <Logo />
      </div>
   );
};

export default LoadingSpinner;
