import React from "react";
import { Form, Collapse, Row, Col } from "react-bootstrap";
import { EDUCATIONS, ETHNICITY, MARITALSTATUS, CLASS, GENDER, DIAGNOSIS, SECONDARY, MEDICATION, DOSEUNIT } from "../constants/clientConstants";
import ReferralId from "./ReferralId";
import Select from "react-select";
import { customSelectStyles } from "../forms/customSelectStyles";

const ClientContactPhones = ({ data, isEdit, skin }) => {
   const getLabelById = (id, array) => {
      const item = array.find((elem) => elem.value == id);
      return item ? item.label : "";
   };
   const handleSwitchChangeTemp = (e, item, index) => {
      // this confirms the onchange fires. The update does not work because
      // we need to include react hook form
      const isChecked = e.target.checked;
   };

   return (
      // Client Contact Phones
      data.map((item, index) => (
         <li key={index} className="list-group-item border-0 ps-2 pe-2 mb-2">
            {isEdit ? (
               <>
                  <Row>
                     <Col md={6}>
                        <div className="d-flex flex-column flex-fill">
                           <Form.Label className="form-label mt-2" htmlFor={`clientPhone-${item.client_phone_type}`}>
                              {item.client_phone_type} Phone
                           </Form.Label>
                           <Form.Control
                              id={`clientPhone-${item.client_phone_type}`}
                              name={`clientPhone-${item.client_phone_type}`}
                              type="text"
                              placeholder="Phone"
                              value={item.client_phone}
                           />
                           <Form.Label className="form-label mt-2" htmlFor={`clientPhone-${item.client_phone_type}`}>
                              Comment
                           </Form.Label>
                           <Form.Control
                              id={`clientPhoneComment-${item.client_phone_type}`}
                              name={`clientPhoneComment-${item.client_phone_type}`}
                              type="text"
                              placeholder="Comment"
                              value={item.client_phone_comment || "No comment"}
                           />
                        </div>
                     </Col>
                     <Col md={6} style={{ marginTop: "33px" }}>
                        {/* add the style tag to align switches to phone number */}
                        <div className="d-flex flex-column flex-fill">
                           {/* This is not required as the Form.Check has a label prop
                           <Form.Label className="form-label mt-3" htmlFor={`clientPhoneMessage-${item.client_phone_type}`}>
                              Leave Message
                           </Form.Label> */}
                           <Form.Check
                              type="switch"
                              id={`clientPhoneMessage-${item.client_phone_type}`}
                              name={`clientPhoneMessage-${item.client_phone_type}`}
                              label="Leave Message"
                              checked={item.client_phone_message}
                              onChange={(e) => handleSwitchChangeTemp(e, item, index)}
                           />
                           {/* <Form.Label className="form-label mt-3" htmlFor={`clientAppointmentReminder-${item.client_phone_type}`}>
                              Appointment Reminder
                           </Form.Label> */}
                           <Form.Check
                              type="switch"
                              id={`clientAppointmentReminder-${item.client_phone_type}`}
                              name={`clientAppointmentReminder-${item.client_phone_type}`}
                              label="Appointment Reminder"
                              checked={item.client_appointment_reminder}
                              onChange={(e) => handleSwitchChangeTemp(e, item, index)}
                           />
                        </div>
                     </Col>
                  </Row>
               </>
            ) : (
               <>
                  <Row>
                     <Col md={6}>
                        <div className="d-flex flex-column flex-fill">
                           <div className="mt-2 fw-semibold">{item.client_phone_type} Phone</div>
                           <div>{item.client_phone}</div>
                           <div className="mt-2 fw-semibold text-nowrap">Comment</div>
                           <div>{item.client_phone_comment || "No comment"}</div>
                        </div>
                     </Col>
                     <Col md={6}>
                        <div className="d-flex flex-column flex-fill">
                           <div className="mt-2 fw-semibold text-nowrap">Leave Message</div>
                           <div>{item.client_phone_message ? "Yes" : "No"}</div>
                           <div className="mt-2 fw-semibold text-nowrap">Appointment Reminder</div>
                           <div>{item.client_appointment_reminder ? "Yes" : "No"}</div>
                        </div>
                     </Col>
                  </Row>
               </>
            )}
         </li>
      ))
   );
};
export default ClientContactPhones;
