export const EDUCATIONS = [
   { value: 0, label: "Not Selected" },
   { value: 1, label: "No formal Education" },
   { value: 2, label: "Primary education" },
   { value: 3, label: "Secondary education" },
   { value: 6, label: "Doctorate" },
   { value: 5, label: "Master's Degree" },
   { value: 4, label: "Bachelor's Degree" },
   { value: 7, label: "Diploma" },
];

export const ETHNICITY = [
   { value: 0, label: "Not Selected" },
   { value: 1, label: "Caucasion" },
   { value: 2, label: "African" },
   { value: 3, label: "American" },
   { value: 4, label: "Hispanic" },
   { value: 5, label: "Asian" },
   { value: 6, label: "Other" },
];

export const MARITALSTATUS = [
   { value: 0, label: "Not Selected" },
   { value: 1, label: "Single" },
   { value: 2, label: "Married" },
   { value: 3, label: "Widowed" },
   { value: 4, label: "Seperated" },
   { value: 5, label: "Divorce" },
   { value: 6, label: "Common Law" },
];

export const CLASS = [
   { value: 0, label: "Not Selected" },
   { value: 2, label: "Civilian" },
   { value: 3, label: "Military" },
];

export const GENDER = [
   { value: -1, label: "Unknown" },
   { value: 1, label: "Male" },
   { value: 2, label: "Female" },
];
export const DIAGNOSIS = [
   { value: 0, label: "Not Selected" },
   { value: 1, label: "Pain - Low Back Pain" },
   { value: 2, label: "Pain - Whiplash" },
   { value: 3, label: "Pain - Upper Extremity Pain" },
   { value: 4, label: "Pain - Lower Extremity Pain" },
   { value: 5, label: "Pain - Fibromyalgia" },
   { value: 6, label: "Pain - CRPS" },
   { value: 7, label: "Pain - Neuropathic" },
   { value: 8, label: "Pain - Multiple Fracture" },
   { value: 10, label: "Mental Health - Depression" },
   { value: 11, label: "Mental Health - PTSD" },
   { value: 12, label: "Mental ealth - Phobia" },
   { value: 13, label: "Mental Health - Bipolar" },
   { value: 14, label: "Mental Health - Stress/Anxiety" },
   { value: 15, label: "Mental Health - Other" },
   { value: 16, label: "Cancer Survivor" },
   { value: 17, label: "Cardiovascular Condition" },
   { value: 18, label: "Diabetes" },
   { value: 19, label: "Renal Disease" },
   { value: 20, label: "Other" },
];

export const SECONDARY = [
   { value: 1, label: "Physiotherapy" },
   { value: 2, label: "Psychotherapy" },
   { value: 3, label: "Chiropractic" },
   { value: 4, label: "Vocational Rehab" },
];
export const MEDICATION = [
   { value: 0, label: "" },
   { value: 3, label: "Antidepressant" },
   { value: 4, label: "Anxiolytic" },
   { value: 5, label: "Hypnotic/sleeping" },
   { value: 2, label: "Narcotic" },
   { value: 1, label: "NSAIDS" },
   { value: 99, label: "Other" },
];
export const DOSEUNIT = [
   { value: "tsp", label: "Teaspoon" },
   { value: "tbsp", label: "Tablespoon" },
   { value: "mL", label: "Millilitre" },
   { value: "L", label: "Litre" },
   { value: "fl oz", label: "Fluid ounce" },
   { value: "pt", label: "Pint" },
   { value: "mcp", label: "Microgram" },
   { value: "mg", label: "Milligram" },
   { value: "gm", label: "Gram" },
   { value: "kg", label: "Kilogram" },
   { value: "oz", label: "Ounces" },
   { value: "lbs", label: "Pounds" },
];
