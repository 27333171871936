import { useState, useEffect, useContext } from "react";
import { Button, Form, Col, Row, Container, Collapse, InputGroup } from "react-bootstrap";
import ClientModalHeader from "../components/ClientModalHeader";
import CollapsibleContent from "../components/CollapsibleContent";
import { ModalFormContext } from "../context/ModalFormContext";

import { APPOINTMENT_STATUS_OPTIONS, APPOINTMENT_LOCATION_OPTIONS, APPOINTMENT_DURATION_OPTIONS } from "../constants/appointmentConstants";
import "./ClientContactView.css";

const ClientEmploymentView = ({ clientId, clientName, category, eData, eMode, isEdit, onEditButtonClick }) => {
   const { handleClose } = useContext(ModalFormContext);
   const [contentReady, setContentReady] = useState(false);

   const getStatusLabel = (statusValue) => {
      const option = APPOINTMENT_STATUS_OPTIONS.find((opt) => opt.value === statusValue);
      return option ? option.label : "Unknown";
   };

   const getLocationLabel = (locationValue) => {
      const option = APPOINTMENT_LOCATION_OPTIONS.find((opt) => opt.value === locationValue);
      return option ? option.label : "Unknown";
   };

   const getSessionDurationLabel = (sessionDurationValue) => {
      const option = APPOINTMENT_DURATION_OPTIONS.find((opt) => opt.value === sessionDurationValue);
      return option ? option.label : "Unknown";
   };

   useEffect(() => {
      const timer = setTimeout(() => {
         setContentReady(true);
      }, 1000);
      return () => clearTimeout(timer);
   }, []);

   const onHandleButtonClose = (e) => {
      handleClose();
   };

   return (
      <>
         <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Row>
               <Col className="event-body ps-3 pe-3">
                  <ClientModalHeader name={clientName} category={category} eMode={eMode} />
               </Col>
            </Row>
            <Row style={{ flex: "1", overflowY: "auto" }}>
               <Col>
                  <div className="card-bc border-0">
                     <div className="card-body border-top border-1" style={{ "--card-body-content": "'Employment Details'" }}>
                        <Row className="pt-2 pb-2 border-bottom">
                           <CollapsibleContent title={"Employment Details"} data={eData.c_employment_data} type="employmentdetails" />
                           {/* ... other CollapsibleContent components for other data sections */}
                        </Row>
                        <Row className="pt-2 pb-2 border-bottom">
                           <CollapsibleContent title={"Employment Contact"} data={eData.c_employment_data} type="employmentcontact" />
                           {/* ... other CollapsibleContent components for other data sections */}
                        </Row>
                     </div>
                  </div>
               </Col>
            </Row>
            <Row className="g-0 mt-4 ">
               <Col className="d-flex justify-content-between">
                  {isEdit ? (
                     <>
                        <Button variant="outline-secondary" size="lg" onClick={onHandleButtonClose} className="rounded-pill">
                           Cancel
                        </Button>
                        <Button variant="secondary" size="lg" className="rounded-pill">
                           Save
                        </Button>
                     </>
                  ) : (
                     <>
                        <Button variant="outline-secondary" size="lg" onClick={onHandleButtonClose} className="rounded-pill">
                           Cancel
                        </Button>
                        <Button variant="secondary" size="lg" className="rounded-pill" onClick={onEditButtonClick}>
                           Edit
                        </Button>
                     </>
                  )}
               </Col>
            </Row>
         </Container>
      </>
   );
};

export default ClientEmploymentView;
