import { useState, useContext, useEffect, useRef, useReducer } from "react";

import useFetchData from "./useFetchDatav3.js";
import { useToast } from "../context/useToast.js";
import InitialsAvatar from "../components/InitialsAvatar";
import { Button, Container, Col, Row, Card, Offcanvas, OverlayTrigger, Tooltip, ListGroup, ListGroupItem } from "react-bootstrap";
import StatusBadge from "../components/StatusBadge";
import ClientAge from "../components/ClientAge";
import { ModalFormContext } from "../context/ModalFormContext.js"; // useContext dirty
import moment from "moment";
import ClassicEditor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../ckeditor/ckeditor.styles.css";
import AppointmentStatusDropdown from "../components/AppointmentStatusDropDown.js";
import "./ResizeableEditor.style.css";

const SessionModal = ({ clientId, clientName, treatmentId, treatmentName, eData, eMode, skin }) => {
   const { isOpen, isFormDirty, handleFormChange, handleClose, resetFormDirtyState } = useContext(ModalFormContext);

   const editorRef = useRef();
   const editorWrapperRef = useRef(null);

   const [editMode, setEditMode] = useState(false);
   const [show, setShow] = useState(true);

   const [isLoadingClient, setIsLoadingClient] = useState(true);
   const { triggerToast } = useToast();
   const { fetchData } = useFetchData();

   // Fetch Client
   // useEffect(() => {
   //    // this useEffect sets initial state to false for other treatment tabs
   //    const getClient = async () => {
   //       console.log("GETCLIENTDATA:", clientId, treatmentId);
   //       const params = {
   //          cs_id: treatmentId,
   //          client_id: clientId,
   //       };
   //       const url = "/getClientDetails.sz";
   //       const method = "GET";
   //       const body = { params: JSON.stringify(params) };
   //       const contentType = "application/json";
   //       try {
   //          const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
   //          setIsLoadingClient(false);
   //          if (responseData.st === 1) {
   //             if (responseData?.payload?.clients?.length > 0) {
   //                setPayloadClient(responseData?.payload?.clients[0]);
   //                console.log("CLIENTMODAL PAYLOAD:", payloadClient);
   //             }
   //          } else {
   //             if (responseData.m_en) {
   //                setIsLoadingClient(false);
   //                //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
   //                triggerToast("warning", responseData.m_en, false, "", false);
   //             } else {
   //                setIsLoadingClient(false);
   //                triggerToast("error", "We seem to have an issue retrieving client detail, please try again later.", true, "Issue", false, 0);
   //             }
   //          }
   //       } catch (error) {
   //          setIsLoadingClient(false);
   //          triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
   //       }
   //    };
   //    getClient();
   // }, [clientId, treatmentId]);

   const editButtonClick = () => {
      setEditMode(true);
   };

   const resetEdit = () => {
      setEditMode(false);
   };
   // the eMode manages if the AppointmentForm component is existing appointment or a new appointment
   // eMode is a prop passed from the Home page which has the Calendar component. If we click on an appointment its edit otherwise its new
   // we set the show variable to true

   const handleSessionNoteClick = ({ id, treatmentid, action }) => {
      console.log("ACTION:", action);
   };
   function Header({ header }) {
      const eData = header;
      return (
         <Row className="row-cols-auto g-4">
            <Col xl>
               <div className="d-flex h-100">
                  <div className="me-3">
                     {eData ? (
                        <InitialsAvatar name={eData?.clientname} className="avatar-xxl avatar-rounded-xxl" />
                     ) : (
                        <span className="text-muted">No Avatar</span>
                     )}
                  </div>
                  <div className="h-100 w-100 d-flex flex-column justify-content-between">
                     <div className="d-flex align-items-start justify-content-start mt-2">
                        <h2 className="main-title fs-4 ps-3 mb-0">
                           Session Notes <span className="fs-7">for </span>
                           {eData?.clientname}
                           <div className="pt-1 pb-0 fs-6">
                              {moment.utc(eData.eventstart).format("MMMM Do YYYY, h:mm a")}{" "}
                              <span className="ms-2 d-inline-flex">
                                 <AppointmentStatusDropdown status={eData.appointment_status} locked={eData.islocked} />
                                 <span className="ps-3"> </span>
                                 <span className="btn-bc align-self-center">
                                    {eData.islocked ? (
                                       <OverlayTrigger
                                          placement="top"
                                          overlay={
                                             <Tooltip>
                                                <strong>Session</strong> is locked, updates are prohibited.
                                             </Tooltip>
                                          }>
                                          <i className="ri-lock-2-line"></i>
                                       </OverlayTrigger>
                                    ) : (
                                       <OverlayTrigger
                                          placement="top"
                                          overlay={
                                             <Tooltip>
                                                <strong>Session</strong> can be updated.
                                             </Tooltip>
                                          }>
                                          <i className="ri-lock-unlock-line"></i>
                                       </OverlayTrigger>
                                    )}
                                 </span>
                              </span>
                           </div>
                        </h2>
                     </div>
                     <div className="d-flex flex-wrap align-items-end justify-content-start">
                        <Button
                           variant="link"
                           className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center" // Remove margin for tight spacing
                           style={{ backgroundColor: "transparent" }}
                           disabled={eData.islocked}
                           onClick={() => handleSessionNoteClick(eData?.eventid, eData?.treatmentid, "Review")}>
                           <i className="me-2 ri-download-2-line"></i> Send for Review
                        </Button>
                        <Button
                           variant="link"
                           className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center" // Remove margin for tight spacing
                           style={{ backgroundColor: "transparent" }}
                           disabled={eData.islocked}
                           onClick={() => handleSessionNoteClick(eData?.eventid, eData?.treatmentid, "Complete")}>
                           <i className="me-2 ri-check-line"></i> Complete Session
                        </Button>
                        <Button
                           variant="link"
                           className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center" // Remove margin for tight spacing
                           style={{ backgroundColor: "transparent" }}
                           disabled={eData.islocked}
                           onClick={() => handleSessionNoteClick(eData?.eventid, eData?.treatmentid, "Clear")}>
                           <i className="me-2 ri-file-edit-line"></i> Clear Note
                        </Button>
                     </div>
                  </div>
               </div>
            </Col>
            <Col>
               <ListGroup horizontal className="mb-3 d-flex flex-wrap">
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">Case</div>
                     <div className="fs-6 text-start">{eData?.caident}</div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">Account Manager</div>
                     <div className="fs-6 text-start">{eData?.acctmgr_name}</div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">Session Allocation</div>
                     <div className="fs-6 text-start">
                        {eData?.totalsessions}{" "}
                        {eData?.remainingsessions && eData?.remainingsessions > 0 ? (
                           <>
                              <span className="fs-7">remaining</span> {eData?.remainingsessions}
                           </>
                        ) : (
                           ""
                        )}
                     </div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">
                        Treatment <StatusBadge name={eData?.treatmentstatus} bg="warning" />
                     </div>
                     <div className="fs-6 text-start">{eData?.treatmentname}</div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">Gender</div>
                     <div className="fs-6 text-start">{eData?.clientgender}</div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">Age</div>
                     <div className="fs-6 text-start">
                        <ClientAge dob={eData?.clientdbo} age={eData?.clientage} />
                     </div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">
                        Date <span style={{ fontSize: "10px" }}>of</span> Disability
                     </div>
                     <div className="fs-6 text-start">{eData?.clientdod}</div>
                  </ListGroupItem>
                  <ListGroupItem className="border-0">
                     <div className="fs-7 mb-0 text-muted">
                        Change <span style={{ fontSize: "10px" }}>of</span> Definition
                     </div>
                     <div className="fs-6 text-start">{eData?.clientcod}</div>
                  </ListGroupItem>
               </ListGroup>
            </Col>
         </Row>
      );
   }

   const SessionCards = ({ note }) => {
      return (
         <div className="container">
            <Row className="d-flex justify-content-center align-items-center">
               <Col key="reviewDetail" md="auto" className="d-flex flex-wrap justify-content-center">
                  <div className="card-bc border-0" style={{ minHeight: "100px", minWidth: "13rem" }}>
                     <div className="card-body border-top border-1" style={{ "--card-body-content": "'Review Details'" }}></div>
                     <div className="border-0 ms-3">
                        {note.eventstatus == 3 || note.eventstatus == 4 || note.eventstatus == 5 ? (
                           <>
                              <div className="fs-6 text-start fw-semibold">
                                 Submitted <span className="fs-7">for</span> Review
                              </div>
                              <div className="fs-6 text-start">By {note.submit_for_review_by}</div>
                              {note.submit_for_review_on && (
                                 <>
                                    <div className="fs-6 text-start">on moment.utc(note.submit_for_review_on).format("MMMM DD, YYYY hh:mm a")</div>
                                 </>
                              )}
                           </>
                        ) : note.eventstatus == 2 || note.eventstatus == 9 ? (
                           <>
                              <div className="fs-6 text-start fw-semibold">Reviewed</div>
                              <div className="fs-6 text-start">
                                 <span className="fs-7">By</span> {note.submit_for_review_by}
                              </div>
                              <div className="fs-6 text-start">
                                 {note.submit_for_review_on && (
                                    <>
                                       <span className="fs-7">On</span> {moment.utc(note.submit_for_review_on).format("MMM DD, YYYY hh:mm a")}
                                    </>
                                 )}
                              </div>
                           </>
                        ) : (
                           <div className="fs-6 text-start fw-semibold">Not Reviewed</div>
                        )}
                     </div>
                  </div>
               </Col>
               <Col key="sessionDetail" md="auto" className="d-flex flex-wrap justify-content-center">
                  <div className="card-bc border-0" style={{ minHeight: "100px", minWidth: "13rem" }}>
                     <div
                        className="card-body border-top border-1"
                        style={{
                           "--card-body-content": "'Session Detail'",
                        }}></div>
                     <div className="border-0 ms-3">
                        {note.eventstatus == 2 || note.eventstatus == 9 ? (
                           <>
                              <div className="fs-6 text-start fw-semibold">
                                 Completed {note.eventstatus == 9 && <span className="fs-7">minor changes</span>}
                              </div>
                              <div className="fs-6 text-start">
                                 <span className="fs-7">By</span> {note.se_complete_by}
                              </div>
                              <div className="fs-6 text-start">
                                 {note.se_completed_on && (
                                    <>
                                       <span className="fs-7">On</span> {moment.utc(note.se_completed_on).format("MMM DD, YYYY hh:mm a")}
                                    </>
                                 )}
                              </div>
                           </>
                        ) : (
                           <div className="fs-6 text-start fw-semibold">Not Completed</div>
                        )}
                     </div>
                  </div>
               </Col>
               <Col key="lastUpdated" md="auto" className="d-flex flex-wrap justify-content-center">
                  <div className="card-bc border-0 h-100" style={{ minHeight: "100px", minWidth: "13rem" }}>
                     <div
                        className="card-body border-top border-1"
                        style={{
                           "--card-body-content": "'Last Updated'",
                        }}></div>
                     <div className="border-0 ms-3">
                        {
                           <>
                              <div className="fs-6 text-start fw-semibold">{note.userupdatedby ? "Updated" : "Not Updated"}</div>
                              <div className="fs-6 text-start">
                                 {note.userupdatedby && (
                                    <>
                                       <span className="fs-7">By</span> {note.userupdatedby}
                                    </>
                                 )}
                              </div>
                              <div className="fs-6 text-start">
                                 {note.userupdatedon && (
                                    <>
                                       <span className="fs-7">On</span> {moment.utc(note.userupdatedon).format("MMM DD, YYYY hh:mm a")}
                                    </>
                                 )}
                              </div>
                           </>
                        }
                     </div>
                  </div>
               </Col>
               <Col key="sessionDuration" md="auto" className="d-flex flex-wrap justify-content-center">
                  <div className="card-bc border-0 h-100" style={{ minHeight: "100px", minWidth: "13rem" }}>
                     <div
                        className="card-body border-top border-1"
                        style={{
                           "--card-body-content": "'Session Duration'",
                        }}></div>
                     <div className="border-0 ms-3">
                        <div className="fs-6 text-start fw-semibold">{note.eventduration && `${note.eventduration} minutes `}</div>
                        {typeof note.split_link_no === "number" && note.split_link_no !== 0 && (
                           <>
                              <div className="fs-6 text-start fw-semibold">Session Split</div>
                              {note.split_link_no == 2 ? (
                                 <div className="fs-6 text-start">
                                    Follow-up Session <span className="fs-7">on</span>
                                    {moment.utc(note.split_link_other_apt_start).format("MMM DD hh:mm a")}
                                 </div>
                              ) : (
                                 <div className="fs-6 text-start">
                                    Initial Session <span className="fs-7">on</span>
                                    {moment.utc(note.split_link_other_apt_start).format("MMM DD hh:mm a")}
                                 </div>
                              )}
                           </>
                        )}
                     </div>
                  </div>
               </Col>
               <Col key="measuresDetail" md="auto" className="d-flex flex-wrap justify-content-center">
                  <div className="card-bc border-0 h-100" style={{ minHeight: "100px", minWidth: "13rem" }}>
                     <div
                        className="card-body border-top border-1"
                        style={{
                           "--card-body-content": "'Measures'",
                        }}></div>
                     <div className="border-0 ms-3">
                        {note.se_questionnaire ? (
                           <>
                              <div className="fs-6 text-start">
                                 <span className="fw-semibold">
                                    {note.se_questionnaire_type == 1
                                       ? "Initial"
                                       : note.se_questionnaire_type == 2
                                       ? "Interim"
                                       : note.se_questionnaire_type == 3
                                       ? "Final"
                                       : ""}
                                    Measure
                                 </span>
                              </div>
                              <div className="fs-6 text-start">
                                 Delivered <span className="fs-7">by</span> {note.se_questionnaire_delivery}
                              </div>
                              <div className="fs-6 text-start">
                                 {note.se_questionnaire_status_en} <span className="fs-7">on</span>{" "}
                                 {note.se_questionnaire_status == 1 || note.se_questionnaire_status == 2
                                    ? moment.utc(note.se_questionnaire_started).format("MMM DD hh:mm a")
                                    : moment.utc(note.se_questionnaire_completed).format("MMM DD hh:mm a")}
                              </div>
                           </>
                        ) : (
                           <div className="fs-6 text-start">No Measures</div>
                        )}
                     </div>
                  </div>
               </Col>
            </Row>
         </div>
      );
   };
   const onHandleButtonClose = (e) => {
      handleClose();
   };

   useEffect(() => {
      const handleResize = () => {
         const editorWrapper = editorWrapperRef.current;
         if (editorWrapper) {
            const resizeHandle = document.createElement("div");
            resizeHandle.className = "resize-handle";

            // Create and append the icon element
            const icon = document.createElement("i");
            icon.className = "ri-arrow-down-s-line";
            resizeHandle.appendChild(icon);

            editorWrapper.appendChild(resizeHandle);

            const handleMouseDown = (e) => {
               document.addEventListener("mousemove", handleMouseMove);
               document.addEventListener("mouseup", handleMouseUp);
            };

            const handleMouseMove = (e) => {
               const newHeight = e.clientY - editorWrapper.getBoundingClientRect().top;
               if (newHeight > 100) {
                  editorWrapper.style.height = `${newHeight}px`;
                  const editorElement = editorWrapper.querySelector(".ck.ck-editor__editable_inline");

                  if (editorElement) {
                     editorRef.current.ui.view.editable.element.style.height = `${newHeight - 40}px`;
                     editorRef.current.ui.view.editable.element.style.minHeight = `${newHeight - 40}px`;
                     document.documentElement.style.setProperty("--editor-height", `${newHeight - 40}px`); // Set initial height using custom property
                  }
               }
            };

            const handleMouseUp = () => {
               document.removeEventListener("mousemove", handleMouseMove);
               document.removeEventListener("mouseup", handleMouseUp);
            };

            resizeHandle.addEventListener("mousedown", handleMouseDown);

            return () => {
               resizeHandle.removeEventListener("mousedown", handleMouseDown);
               if (editorWrapper.contains(resizeHandle)) {
                  editorWrapper.removeChild(resizeHandle);
               }
            };
         }
      };
      if (isOpen) {
         setTimeout(handleResize, 100);
      }
   }, [isOpen]);

   return (
      <>
         <Offcanvas show={isOpen} onExited={resetEdit} placement="end" className="offcanvas-width-full">
            <Offcanvas.Header closeButton className="pb-3" />
            <Offcanvas.Body className="p-0" style={{ maxHeight: "100vh", overflowY: "auto", overflowX: "hidden" }}>
               <Container fluid className="p-0" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                  <Row>
                     <Col className="event-body ps-5 pe-5">
                        <Header header={eData} />
                     </Col>
                  </Row>
                  <Row style={{ flex: "1", overflowY: "auto" }}>
                     <Col>
                        <div className="pb-3 pt-3" style={{ paddingLeft: "8rem", paddingRight: "8rem" }}>
                           <div className="pb-5">
                              <SessionCards note={eData} />
                           </div>
                           <div className="editor-wrapper" ref={editorWrapperRef}>
                              <CKEditor
                                 editor={ClassicEditor}
                                 name="treatmentNote"
                                 onReady={(editor) => {
                                    editorRef.current = editor;
                                    editor.ui.view.editable.element.style.height = "200px";
                                 }}
                                 data={"Session Note"}
                                 onChange={(event, editor) => {
                                    const data = editor.getData().trim();
                                    //setValue("treatmentNote", data);
                                    //if (errors.treatmentNote) {
                                    //   clearErrors("treatmentNote"); // Clear the error if present
                                    //}
                                 }}
                              />
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <Row className="g-0 border-top pt-3 pb-3" style={{ backgroundColor: skin === "dark" ? "#27314b" : "#f8f9fa" }}>
                     <Col xs={4}></Col>
                     <Col className="d-flex justify-content-between">
                        <Button variant="outline-secondary" size="lg" onClick={onHandleButtonClose} className="rounded-pill">
                           Cancel
                        </Button>
                        <Button variant="primary" size="lg" className="rounded-pill">
                           Save Session
                        </Button>
                     </Col>
                     <Col xs={4}></Col>
                  </Row>
               </Container>
            </Offcanvas.Body>
         </Offcanvas>
      </>
   );
};

export default SessionModal;
