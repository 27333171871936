import { Col, Row } from "react-bootstrap";
import InitialsAvatar from "./InitialsAvatar";

const ModalHeader = ({ title, clientName, treatmentName }) => {
   return (
      <>
         <Row className="d-flex align-items-start align-items-center">
            <Col xs="auto">
               <InitialsAvatar name={clientName} />
            </Col>
            <Col>
               <p className="fs-5 mb-2">
                  <span className="fw-semibold">{title}</span> {treatmentName && <span className="fs-7">for</span>} {treatmentName}
               </p>
            </Col>
            <Col></Col>
         </Row>
      </>
   );
};

export default ModalHeader;
