import React, { useEffect, useState, useContext, onClick, useCallback, useRef, useMemo, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, DropdownButton, Dropdown, Form, Tab, Nav, Col, Row, ListGroup, ListGroupItem } from "react-bootstrap";
import useFetchData from "../components/useFetchDatav3";
import { useToast } from "../context/useToast.js";
import InitialsAvatar from "../components/InitialsAvatar";
import SessionModal from "../components/SessionModal.js";
import StatusBadge from "../components/StatusBadge";
import ClientAge from "../components/ClientAge";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { zodResolver, anyResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import ClassicEditor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../ckeditor/ckeditor.styles.css";

import { CLINICIAN_NOTE_TYPE } from "../constants/noteTypes.js";
import ClientModal from "../components/ClientModal";
import DocumentModal from "../components/DocumentModal";
import RequestModal from "../components/RequestModal";
import ReminderModal from "../components/ReminderModal";
import moment from "moment";
import "./Notes.styles.css";
import ModalFormContextProvider, { ModalFormContext } from "../context/ModalFormContext"; // useContext dirty

const noteSchema = z.object({
   treatmentId: z.number(),
   treatmentNote: z.string().min(5, { message: "Treatment Note is required." }),
   treatmentNoteType: z.string().refine(
      (value) => {
         const matchingOption = CLINICIAN_NOTE_TYPE.find((option) => option.value === value);
         return !!matchingOption;
      },
      {
         message: "Note Type selection is required",
      }
   ),
   treatmentNoteDate: z.date(),
   treatmentNoteTime: z.date(),
});

export default function Notes() {
   return (
      <ModalFormContextProvider>
         <NotesContent />
      </ModalFormContextProvider>
   );
}

function NotesContent() {
   const { isOpen, handleOpen } = useContext(ModalFormContext);
   const [renderNotesActiveTab, setRenderNotesActiveTab] = useState("summary");
   const [isLoadingNotes, setIsLoadingNotes] = useState(true);
   const [selectedClientId, setClientId] = useState("");
   const [selectedClientName, setClientName] = useState("");
   const [selectedCategory, setCategory] = useState("");
   const [selectedTreatmentId, setTreatmentId] = useState("");
   const [selectedTreatmentName, setTreatmentName] = useState("");
   const [selectedAction, setAction] = useState("");
   const [selectedMode, setSelectedMode] = useState(null);

   // variable that determins in client modal is displayed
   const [showClientModal, setShowClientModal] = useState(false);
   const [showDocumentModal, setShowDocumentModal] = useState(false);
   const [showReminderModal, setShowReminderModal] = useState(false);
   const [showRequestModal, setShowRequestModal] = useState(false);

   const [showSessionModal, setShowSessionModal] = useState(false);
   const noteFormRef = useRef(null);
   const { triggerToast } = useToast();
   const [payloadNotes, setPayloadNotes] = useState([]);
   const [eventTypeCounts, setEventTypeCounts] = useState(new Map());
   const editorNoteRef = useRef();

   const [payloadOtherTreatments, setPayloadOtherTreatments] = useState([]);
   const [payloadOtherTreatmentsLoaded, setPayloadOtherTreatmentsLoaded] = useState({});
   const [payloadEmailConsent, setPayloadEmailConsent] = useState({});
   const [selectedFilters, setSelectedFilters] = useState([{ type: "all" }]);
   const [isHoveredEventId, setIsHoveredEventId] = useState(null);
   const [editNote, setEditNote] = useState({});

   const [firstPayloadNote, setFirstPayloadNotes] = useState({});
   const location = useLocation();
   const { fetchData } = useFetchData();
   const { cid, tid } = location.state || {};

   const [selectedCID, setCID] = useState("");
   const [selectedTID, setTID] = useState("");

   const [loadedOtherTabs, setLoadedOtherTabs] = useState({});
   const [loadedOtherData, setLoadedOtherData] = useState({});

   const onSubmit = async (data) => {
      try {
         // await fetch('your-endpoint', {
         //   method: 'POST',
         //   body: JSON.stringify(data),
         //   headers: {
         //     'Content-Type': 'application/json'
         //   }
         // })

         //status, message, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
         triggerToast("warning", "Note was successfully saved.", true, "theis is a title", false, 0);
      } catch (error) {
         triggerToast("error", "Note was not saved.");
      }
   };

   useEffect(() => {
      if (cid && tid) {
         setCID(cid);
         setTID(tid);
      }
   }, [cid, tid]);

   const defaultValues = useMemo(
      () => ({
         treatmentId: selectedTID,
         treatmentNote: "",
         treatmentNoteType: "",
         treatmentNoteDate: new Date(),
         treatmentNoteTime: new Date(),
      }),
      [selectedTID]
   );
   const [errorMessage, setErrorMessage] = useState("");

   const {
      control,
      handleSubmit,
      setError,
      methods,
      watch,
      setValue,
      clearErrors,
      formState: { isDirty, dirtyFields, errors, isSubmitting, isSubmitted },
   } = useForm({
      resolver: zodResolver(noteSchema),
      defaultValues,
   });

   // useEffect(() => {
   //    console.log("Form Errors:", errors); // Debugging log
   //    console.log("Is Submitted:", isSubmitted); // Debugging log
   //    console.log("Is Submitting:", isSubmitting); // Debugging log
   // }, [errors, isSubmitted, isSubmitting]);

   useEffect(() => {
      document.body.classList.add("app-task");
      return () => {
         document.body.classList.remove("app-task");
      };
   }, []);

   const handleClientModalClose = () => {
      setTimeout(() => {
         setShowClientModal(false);
      }, 300);
   };

   const handleDocumentModalClose = () => {
      setTimeout(() => {
         setShowDocumentModal(false);
      }, 300);
   };

   const handleReminderModalClose = () => {
      setTimeout(() => {
         setShowReminderModal(false);
      }, 300);
   };

   const handleRequestModalClose = () => {
      setTimeout(() => {
         setShowRequestModal(false);
      }, 300);
   };

   ///// Skin Switch /////
   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
   const [skin, setSkin] = useState(currentSkin);

   const switchSkin = (skin) => {
      if (skin === "dark") {
         const btnWhite = document.getElementsByClassName("btn-white");

         for (const btn of btnWhite) {
            btn.classList.add("btn-outline-primary");
            btn.classList.remove("btn-white");
         }
      } else {
         const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

         for (const btn of btnOutlinePrimary) {
            btn.classList.remove("btn-outline-primary");
            btn.classList.add("btn-white");
         }
      }
   };

   switchSkin(skin);

   useEffect(() => {
      switchSkin(skin);
   }, [skin]);

   // Set initial loaded state to false for all tabs
   const initialOtherTreatmentsLoadedState = payloadOtherTreatments.reduce((acc, treatment) => {
      acc[treatment.other_id] = false;
      return acc;
   }, {});

   const TransformDataType = (type) => {
      // Use regular expression to replace spaces with hyphens and lowercase
      if (type) {
         return type.replace(/\s+/g, "-").toLowerCase();
      } else {
         return "";
      }
   };

   const handleMouseEnter = (id) => setIsHoveredEventId(id);
   const handleMouseLeave = () => setIsHoveredEventId(null);
   const isFilterButtonSelected = (type) => selectedFilters.some((filter) => filter.type["type"] === type);

   const FilterEventTypeButtons = ({ eventTypeCounts }) => {
      return (
         <div className="d-flex flex-wrap mb-3">
            <button
               key={-1}
               type="button"
               className={`btn btn-sm btn-outline-dark rounded-pill ps-2 pb-0 pe-2 pt-0 fs-7 me-1 mt-3 position-relative me-4 ${
                  isFilterButtonSelected("all") ? "active" : ""
               }`}
               data-id={-1}
               data-type="All"
               data-filtered="all"
               disabled={renderNotesActiveTab !== "summary" && renderNotesActiveTab !== "withnote"}
               onClick={() => handleFilterClick("all")}>
               All Types
               {/* <span class="position-absolute top-0 start-100 translate-middle badge border border-1 border-dark rounded-circle bg-light">
                  <span class="text-dark">{count}</span>
               </span> */}
            </button>
            {Array.from(eventTypeCounts.entries()).map(([id, { type, filterType, count }]) => (
               <button
                  key={id}
                  type="button"
                  className={`btn btn-sm btn-outline-dark rounded-pill ps-2 pb-0 pe-2 pt-0 fs-7 me-1 mt-3 position-relative me-4 ${
                     isFilterButtonSelected(type) ? "active" : ""
                  }`}
                  disabled={renderNotesActiveTab !== "summary" && renderNotesActiveTab !== "withnote"}
                  data-id={id}
                  data-type={type}
                  data-filter={filterType}
                  onClick={() => handleFilterClick({ type })}>
                  {type}
                  <span className="position-absolute top-0 start-100 translate-middle badge border border-1 border-dark rounded-circle bg-light">{count}</span>
               </button>
            ))}
         </div>
      );
   };

   //fetch notes
   useEffect(() => {
      // this useEffect sets initial state to false for other treatment tabs
      const getNotes = async () => {
         if (!selectedCID || !selectedTID) {
            return; // Exit early if missing parameters
         }
         const params = {
            cs_external_id: selectedTID,
            ca_external_id: selectedCID,
            orderby: [{ name: "se_apt_start", direction: "desc" }],
         };
         const url = "/getCaseNotesv3.sz";
         const method = "GET";

         //{params : "params=" + JSON.stringify(filter), othertreatments: t, caller: "getCaseNotesv3.sz", callback: "loadCaseNotes" };

         const body = { params: JSON.stringify(params) };
         const contentType = "application/json";

         try {
            const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);

            setIsLoadingNotes(false);
            if (responseData.st === 1) {
               if (responseData?.payload?.notes?.length > 0) {
                  setFirstPayloadNotes(responseData?.payload?.notes[0]);
               }
               setPayloadNotes(responseData.payload?.notes);
               setPayloadEmailConsent(responseData.payload?.consents);
               setPayloadOtherTreatments(responseData.payload?.treatments);
               setPayloadOtherTreatmentsLoaded(initialOtherTreatmentsLoadedState);
            } else {
               if (responseData.m_en) {
                  //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
                  triggerToast("warning", responseData.m_en, false, "", false);
               } else {
                  triggerToast("error", "We seem to have an issue retrieving notes, please try again later.", true, "Issue", false, 0);
               }
            }
         } catch (error) {
            triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         }
      };
      getNotes();
   }, [selectedCID, selectedTID]);

   useEffect(() => {
      const counts = new Map();
      payloadNotes.forEach((note) => {
         const { eventtype_desc: eventTypeDesc, eventtype: eventTypeId } = note;
         const filterEventTypeDesc = TransformDataType(eventTypeDesc);
         if (counts.has(eventTypeId)) {
            counts.get(eventTypeId).count += 1;
         } else {
            counts.set(eventTypeId, { type: eventTypeDesc, filterType: filterEventTypeDesc, count: 1 });
         }
      });
      setEventTypeCounts(counts);
   }, [payloadNotes]);

   // hook to mark modal to false
   useEffect(() => {
      if (!isOpen) {
         if (showClientModal) {
            setTimeout(() => {
               setShowClientModal(false);
            }, 300);
         }
         if (showSessionModal) {
            setTimeout(() => {
               setShowSessionModal(false);
            }, 300);
         }
      }
   }, [isOpen]);

   const handleFilterClick = (filter) => {
      setSelectedFilters((prevFilters) => {
         let newFilters = [...prevFilters];
         if (filter === "all") {
            return [{ type: "all" }];
         }
         const index = newFilters.findIndex((item) => item.type["type"] === filter.type);
         if (index !== -1) {
            newFilters.splice(index, 1);
         } else {
            newFilters.push({ type: filter });
            newFilters = newFilters.filter((item) => item.type !== "all");
         }
         if (newFilters.length === 0) {
            newFilters.push({ type: "all" });
         }
         return newFilters;
      });
   };
   const filteredNotes = selectedFilters.some((filter) => filter.type === "all")
      ? payloadNotes
      : payloadNotes.filter((note) => {
           //return selectedFilters.has(note.eventtype_desc);
           return selectedFilters.some((filter) => {
              return filter.type["type"] === note.eventtype_desc;
           });
        });
   //for future use
   const handleAddNote = (newNote) => {
      // Update notes and event type counts optimistically
      setPayloadNotes((prevNotes) => [...prevNotes, newNote]);

      setEventTypeCounts((prevCounts) => {
         const newCounts = new Map(prevCounts);
         const { eventtype_desc: eventTypeDesc, eventtype: eventTypeId } = newNote;
         if (newCounts.has(eventTypeId)) {
            newCounts.get(eventTypeId).count += 1;
         } else {
            newCounts.set(eventTypeId, { type: eventTypeDesc, count: 1 });
         }
         return newCounts;
      });

      // Call the parent function to actually add the note
      //onAddNote(newNote);
   };
   const handleNoteEditClick = ({ notes }) => {
      setEditNote(notes);
      setClientId(notes.clientid);
      setClientName(notes.clientname);
      setCategory("Update Note");
      setTreatmentId(notes.treatmentid);
      setTreatmentName(notes.treatmentname);
      setAction("Update Note");
      setSelectedMode("edit");
      setShowClientModal(true);
      handleOpen();
   };
   const handleDropdownItemClick = ({ id, name, category, treatmentid, treatmentname, action }) => {
      setClientId(id);
      setClientName(name);

      if (action) {
         setTreatmentId(treatmentid);
         setTreatmentName(treatmentname);
         setCategory(category);
         setAction(action);
         setSelectedMode("edit");
      } else {
         setCategory(category);
         setSelectedMode("edit");
      }
      if (
         action === "View Contacts" ||
         action === "Referral" ||
         action === "Employment" ||
         action === "doctor" ||
         action === "diagnosis" ||
         action === "secondary" ||
         action === "medications"
      ) {
         setShowClientModal(true);
         handleOpen();
      }
      if (action === "Documents") {
         //launches the Offcanvas
         setShowDocumentModal(true);
         handleOpen();
      }
      if (action === "Reminders") {
         setShowReminderModal(true);
      }
      if (action === "Requests") {
         setShowRequestModal(true);
      }
   };
   const handleSessionNoteClick = (id) => {
      const selectedNote = payloadNotes.find((note) => note.id === id);
      setEditNote(selectedNote);
      setClientId(selectedNote.clientid);
      setClientName(selectedNote.clientname);
      setTreatmentId(selectedNote.treatmentid);
      setTreatmentName(selectedNote.treatmentname);
      setSelectedMode("edit");
      setShowSessionModal(true);
      handleOpen();
   };
   const handleEmailConsentClick = () => {};
   const handleModalClose = (modal) => {
      if (modal === "Documents") {
         setShowDocumentModal("false");
      } else if (modal === "Requests") {
         setShowRequestModal("false");
      } else if (modal === "Reminders") {
         setShowReminderModal("false");
      }
   };

   const handleTabChange = (key) => {
      setRenderNotesActiveTab(key);
   };
   const TransformSessionStatus = ({ note }) => {
      const statusText = () => {
         if (note.eventstatus === 2 || note.eventstatus === 9) {
            return (
               <>
                  <span className="fs-7 ms-1">on</span> {moment.utc(note?.se_completed_on).format("DD MMM YYYY")}
               </>
            );
         } else if (note.eventstatus === 3) {
            return (
               <>
                  <span className="fs-7 ms-1">on</span> {moment.utc(note?.eventnotesubmitreviewon).format("DD MMM YYYY")}
               </>
            );
         } else {
            return <>Insert on {moment.utc(note.sysnotecreatedon).format("DD MMM YYYY hh:mm a ")}</>;
         }
      };

      return (
         <span>
            <span className="fw-bold">{note.eventstatustext}</span>
            {statusText()}
            {note.userupdatedby && (
               <>
                  <span className="fs-7 ms-1 me-1">by</span> {note.userupdatedby}
               </>
            )}
            {note.eventstatus === 2 || note.eventstatus === 9 || note.eventstatus === 3 ? (
               <i className="ri-lock-2-line"></i>
            ) : (
               <i className="ri-unlock-2-line"></i>
            )}
         </span>
      );
   };
   const TransformAssessment = ({ note, tabType }) => {
      // If not questionare then nothing to display

      if (!note.s_questionnaire) {
         return null;
      }

      let ss, rs, rt, dl, cb;
      if (parseInt(note.se_questionnaire_status, 10)) {
         ss = parseInt(note.se_questionnaire_status, 10);
      } else {
         ss = 0;
      }

      dl = note.se_questionnaire_delivery;

      const createButton = () => {
         return (
            <>
               {tabType === "treatment" ? (
                  <button
                     style={{ verticalAlign: "inherit" }}
                     type="button"
                     className="btn btn-link btn-bc ps-1 pe-1 pt-0 pb-0"
                     data-questionnaire-delivery={note.se_questionnaire_delivery}
                     data-questionnaire-type={note.se_questionnaire_type}
                     data-questionnaire-status={note.se_questionnaire_status}
                     data-eid={note.eventid}>
                     <i className="ri-external-link-line fs-6"></i>
                     <span className="fw-bold">{ss === 0 ? "Start" : note.questionnaire_type_name_en}</span> Assessment
                  </button>
               ) : (
                  ss !== 0 && (
                     <>
                        <span className="fw-bold">{note.questionnaire_type_name_en}</span> Assessment
                     </>
                  )
               )}
            </>
         );
      };

      if (ss === 3) {
         rt = note.se_questionnaire_completed ? (
            <>
               <span className="fs-7 ms-1">on</span> {moment.utc(note.se_questionnaire_completed).format("DD MMM YYYY")}
            </>
         ) : (
            "N/A"
         );
         rs = (
            <>
               {dl && <span className="fs-7">({dl})</span>} {note.se_questionnaire_status_en}
            </>
         );
      } else if (ss > 0) {
         rt = note.se_questionnaire_completed ? (
            <>
               <span className="fs-7 ms-1">on</span> {moment.utc(note.se_questionnaire_started).format("DD MMM YYYY")}
            </>
         ) : (
            "N/A"
         );
         rs = (
            <>
               <span className="fw-bold">{note.questionnaire_type_name_en}</span> Assessment {dl && <span className="fs-7">({dl})</span>}{" "}
               {note.se_questionnaire_status_en}
            </>
         );
      } else {
         ss = 0;
         rt = null;
         rs = null;
      }
      cb = createButton();

      return (
         <span>
            {cb}
            {rs}
            {rt}
         </span>
      );
   };
   // Left column content here
   const TransformNotesHeading = ({ note }) => {
      return (
         <div className="fs-5">
            {note.eventtype === 2 ? (
               <button type="button" className="btn btn-link btn-bc p-0 fs-5 btn-session text-start" onClick={() => handleSessionNoteClick(note.id)}>
                  {note.eventtype_desc}
               </button>
            ) : (
               note.eventtype_desc
            )}
            <span className="ms-1 fs-7">{moment.utc(note.eventstart).format("hh:mm a")}</span>
            <div className="fs-7 text-danger">{note.appointment_status === 2 ? "Cancelled" : note.appointment_status === 4 ? "No Show" : ""}</div>
         </div>
      );
   };
   const TransformReportDetails = ({ note, tabType }) => {
      if (!note.s_questionnaire) {
         return null;
      }

      let st, sd;
      if (note.se_report_created_on !== null) {
         st = "Re-create";
         sd = <span>last created on {moment.utc(note.se_report_created_on).format("DD MMM YYYY")} </span>;
      } else {
         st = "Create";
         sd = null;
      }

      //console.log("button:", note.eventid, note.treatmentid, note.se_questionnaire_type, st, note.questionnaire_type_name_en);
      const createReportButton = () => {
         return (
            <>
               {tabType === "treatment" ? (
                  <button
                     style={{ verticalAlign: "inherit" }}
                     type="button"
                     className="btn btn-link btn-bc ps-1 pe-1 pt-0 pb-0"
                     data-eid={note.eventid}
                     data-id={note.treatmentid}
                     data-rid={note.se_questionnaire_type ? note.se_questionnaire_type : ""}>
                     <i className="ri-external-link-line fs-6"></i>
                     <span className="fw-bold">
                        {st} {note.questionnaire_type_name_en}
                     </span>{" "}
                     Report
                  </button>
               ) : (
                  sd && (
                     <>
                        <span className="fw-bold">{note.questionnaire_type_name_en}</span> Report{" "}
                     </>
                  )
               )}
            </>
         );
      };

      return (
         <span>
            {createReportButton()}
            {sd}
         </span>
      );
   };
   // eventtype = 2 session otherwise its a note
   const TransformNotesDetails = ({ note, tabType }) => {
      return (
         <div className="fs-6 session-note-status-data">
            {note.eventtype === 2 ? (
               <>
                  <TransformSessionStatus note={note} />
                  <TransformAssessment note={note} tabType={tabType} />
                  <TransformReportDetails note={note} tabType={tabType} />
               </>
            ) : (
               <>
                  <span className="fw-semibold">Created {moment.utc(note.sysnotecreatedon).format("DD MMM YYYY hh:mm a")}</span>
                  {note.se_read_on ? (
                     <span>
                        <span className="ps-2 pe-1 fw-semibold">Reviewed</span>
                        {moment.utc(note.se_read_on).format("DD MMM YYYY hh:mm a")}
                        <span className="ps-1 pe-1 fs-7">by</span>
                        <span>{note.se_read_by}</span> <i className="ri-lock-2-line"></i>
                     </span>
                  ) : (
                     <span className="ps-2 fw-semibold">Not Reviewed</span>
                  )}
               </>
            )}
         </div>
      );
   };
   const TransformNotes = React.memo(({ note, tab, tabType }) => {
      return (
         (tab === "withnote" || tabType === "other") && (
            <div className="row event-note-view mt-2 mb-2" data-filter-notes="filter-notes">
               <div className="col">
                  {Array.isArray(note.eventnotequestion)
                     ? note.eventnotequestion.map((question, idx) => {
                          const finalMarkup = note.eventnotefinal && note.eventnotefinal[idx] ? { __html: note.eventnotefinal[idx].html_final } : null;
                          const answerMarkup = note.eventnoteanswer && note.eventnoteanswer[idx] ? { __html: note.eventnoteanswer[idx].html_answer } : null;
                          return (
                             <div key={note.id + idx}>
                                {question.question ? <h6 className="fw-bolder mt-1 mb-0">{question.question}</h6> : ""}
                                {note.eventstatus === 2 ||
                                note.eventstatus === 3 ||
                                note.eventstatus === 4 ||
                                note.eventstatus === 5 ||
                                note.eventstatus === 9 ? (
                                   <div>{finalMarkup ? <div dangerouslySetInnerHTML={finalMarkup} /> : <p>No eventnotefinal data</p>}</div>
                                ) : (
                                   <div>{answerMarkup ? <div dangerouslySetInnerHTML={answerMarkup} /> : <p>No eventnoteanswer data</p>}</div>
                                )}
                             </div>
                          );
                       })
                     : ""}
               </div>
            </div>
         )
      );
   });
   const TreatmentRenderedNotes = React.memo(({ payloadNotes, tab, tabType }) => {
      return (
         <div className="note-styles" style={{ marginTop: "20px" }}>
            {isLoadingNotes && (
               <>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
               </>
            )}

            {payloadNotes && payloadNotes.length > 0 ? (
               filteredNotes.map((notes, idx) => {
                  const eventTypeDesc = notes.eventtype_desc;
                  const eventType = notes.eventtype;
                  const eventId = notes.eventid;
                  const isLocked = notes.islocked;
                  const mth = moment.utc(notes.eventstart).format("MMM");
                  const dd = moment.utc(notes.eventstart).format("DD");
                  return (
                     <div className="d-flex flex-nowrap notes-hover-nav" key={idx} data-id={eventId} data-type={TransformDataType(eventTypeDesc)}>
                        <div className="d-flex flex-column notes-start-date">
                           <div className="fs-5 mb-0">{mth}</div>
                           <div className="fs-5">{dd}</div>
                        </div>
                        <div className="note-col-2 flex-grow-1 d-flex row align-items-start">
                           <div className="col-2 align-self-start">
                              <TransformNotesHeading note={notes} />
                           </div>
                           <div className="col-8 align-self-start d-none d-lg-block">
                              <TransformNotesDetails note={notes} tabType={tabType} />
                           </div>
                           <div className="d-flex flex-column align-items-end col-2">
                              <div className="hover-content">
                                 {(eventType === 7 || eventType === 8 || eventType === 9) && !isLocked && (
                                    <Nav variant="underline">
                                       <Nav.Item>
                                          <Nav.Link
                                             key={`navlinkEdit-${eventId}`}
                                             className="fw-semibold pt-0 align-items-start"
                                             data-type="edit"
                                             onClick={() => handleNoteEditClick({ notes })}
                                             eventKey={eventId}>
                                             Edit
                                          </Nav.Link>
                                       </Nav.Item>
                                       <span className="align-items-center">|</span>
                                       <Nav.Item>
                                          <Nav.Link
                                             key={`navlinkDelete-${eventId}`}
                                             className="fw-semibold pt-0 align-items-start"
                                             data-type="delete"
                                             eventKey={eventId}>
                                             Delete
                                          </Nav.Link>
                                       </Nav.Item>
                                    </Nav>
                                 )}
                              </div>
                           </div>
                           <TransformNotes note={notes} tab={tab} tabType={tabType} />
                           <div className="border-bottom border-1"></div>
                        </div>
                     </div>
                  );
               })
            ) : (
               <Row className="d-flex flex-nowrap">
                  <Col className="d-flex flex-column notes-start-date">
                     <div></div>
                  </Col>
                  <div className="flex-grow-1 d-flex">{!isLoadingNotes && <div>Notes not found</div>}</div>
               </Row>
            )}
         </div>
      );
   });
   const TreatmentRenderedOtherNotes = React.memo(({ payloadNotes, tab, tabType }) => {
      return (
         <div className="note-styles" style={{ marginTop: "20px" }}>
            {payloadNotes && payloadNotes.length > 0 ? (
               payloadNotes.map((notes, idx) => {
                  const eventTypeDesc = notes.eventtype_desc;
                  const eventType = notes.eventtype;
                  const eventId = notes.eventid;
                  const isLocked = notes.islocked;
                  const mth = moment.utc(notes.eventstart).format("MMM");
                  const dd = moment.utc(notes.eventstart).format("DD");
                  return (
                     <div className="d-flex flex-nowrap notes-hover-nav" key={idx} data-id={eventId} data-type={TransformDataType(eventTypeDesc)}>
                        <div className="d-flex flex-column notes-start-date">
                           <div className="fs-5 mb-0">{mth}</div>
                           <div className="fs-5">{dd}</div>
                        </div>
                        <div className="note-col-2 flex-grow-1 d-flex row align-items-start">
                           <div className="col-2 align-self-start">
                              <TransformNotesHeading note={notes} />
                           </div>
                           <div className="col-8 align-self-start d-none d-lg-block">
                              <TransformNotesDetails note={notes} tabType={tabType} />
                           </div>
                           <div className="d-flex flex-column align-items-end col-2"></div>
                           <TransformNotes note={notes} tab={tab} tabType={tabType} />
                           <div className="border-bottom border-1"></div>
                        </div>
                     </div>
                  );
               })
            ) : (
               <Row className="d-flex flex-nowrap">
                  <Col className="d-flex flex-column notes-start-date">
                     <div></div>
                  </Col>
                  <div className="flex-grow-1 d-flex">
                     <div className="flex-grow-1 d-flex">{!isLoadingNotes && <div>Notes not found</div>}</div>
                  </div>
               </Row>
            )}
         </div>
      );
   });
   const OtherTreatmentsNav = React.memo(({ payloadOtherTreatments, handleOtherTabLink }) => {
      return (
         <>
            {payloadOtherTreatments && payloadOtherTreatments.length > 0
               ? payloadOtherTreatments.map((other, idx) => (
                    <React.Fragment key={`navitem-${other.other_id}`}>
                       <Nav.Item>
                          <Nav.Link
                             onClick={() => handleOtherTabLink(other.other_id)}
                             key={`navlink-${other.other_id}`}
                             className="d-flex flex-column align-items-center"
                             eventKey={other.other_id}>
                             <span className="fs-7 align-self-end" style={{ fontWeight: "300" }}>
                                {other.treatment_status}
                             </span>
                             <span className="fw-semibold">{other.treatment_name}</span>
                          </Nav.Link>
                       </Nav.Item>
                       {idx < payloadOtherTreatments.length - 1 && <span className="ms-3 me-3 d-flex align-items-center">|</span>}
                    </React.Fragment>
                 ))
               : null}
         </>
      );
   });
   const OtherTreatmentsPane = React.memo(({ payloadOtherTreatments, loadedOtherTabs, loadedOtherData }) => {
      return (
         <>
            {isLoadingNotes && (
               <>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
                  <div className="placeholder-glow">
                     <span className="placeholder col-12"></span>
                  </div>
               </>
            )}
            {payloadOtherTreatments && payloadOtherTreatments.length > 0
               ? payloadOtherTreatments.map((other) => (
                    <React.Fragment key={`navpane-${other.other_id}`}>
                       <Tab.Pane eventKey={other.other_id}>
                          {loadedOtherTabs[other.other_id] ? (
                             loadedOtherData[other.other_id] && loadedOtherData[other.other_id].length > 0 ? (
                                <div>
                                   <TreatmentRenderedOtherNotes payloadNotes={loadedOtherData[other.other_id]} tab={other.other_id} tabType="other" />
                                </div>
                             ) : (
                                <div className="flex-grow-1 d-flex">{!isLoadingNotes && <div>Notes not found for {other.treatment_name}</div>}</div>
                             )
                          ) : (
                             <div className="flex-grow-1 d-flex">{!isLoadingNotes && <div>Notes not found for {other.treatment_name}</div>}</div>
                          )}
                       </Tab.Pane>
                    </React.Fragment>
                 ))
               : null}
         </>
      );
   });
   const getOtherNotes = async (treatmentId) => {
      if (!selectedCID) {
         return null;
      }
      setIsLoadingNotes(true);

      const params = {
         cs_external_id: treatmentId,
         ca_external_id: selectedCID,
         orderby: [{ name: "se_apt_start", direction: "desc" }],
      };
      const url = "/getCaseNotesv3.sz";
      const method = "GET";

      const body = { params: JSON.stringify(params) };
      const contentType = "application/json";

      try {
         const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);

         setIsLoadingNotes(false);

         if (responseData.st === 1) {
            return responseData.payload?.notes;
         } else {
            if (responseData.m_en) {
               //status, length, (isHeading = false), (headingTitle = ""), (autoHide = true), (delay = 3500);
               triggerToast("warning", responseData.m_en, false, "", false);
               return null;
            } else {
               triggerToast("error", "We seem to have an issue retrieving notes, please try again later.", true, "Issue", false, 0);
               return null;
            }
         }
      } catch (error) {
         triggerToast("error", "We seem to have an access your data, please try again later.", true, "Network Issue", false, 0);
         return null;
      }
   };
   const handleOtherTabLink = useCallback(
      async (otherId) => {
         if (!loadedOtherTabs[otherId]) {
            const fetchedData = await getOtherNotes(otherId);
            setLoadedOtherTabs((prev) => ({ ...prev, [otherId]: true }));
            setLoadedOtherData((prev) => ({ ...prev, [otherId]: fetchedData }));
         }
      },
      [loadedOtherTabs, selectedCID]
   );
   return (
      <>
         <Header onSkin={setSkin} />
         <div className="main main-app p-3 p-lg-4 p-xxl-5">
            <Row className="row-cols-auto g-4 g-xxl-5">
               <Col xl>
                  <div className="d-flex h-100">
                     <div className="me-3">
                        {firstPayloadNote ? (
                           <InitialsAvatar name={firstPayloadNote?.clientname} className="avatar-xxl avatar-rounded-xxl" />
                        ) : (
                           <span className="text-muted">No Avatar</span>
                        )}
                     </div>
                     <div className="h-100 w-100 d-flex flex-column justify-content-between">
                        <div className="d-flex align-items-start justify-content-start mt-2">
                           <h2 className="main-title fs-4 ps-3 mb-0">
                              Notes <span className="fs-7">for </span>
                              {firstPayloadNote?.clientname}
                           </h2>
                        </div>
                        <div className="d-flex flex-wrap align-items-end justify-content-start">
                           <DropdownButton
                              variant="link"
                              className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center dropdown-custom"
                              style={{ backgroundColor: "transparent" }}
                              title={
                                 <span className="d-flex align-items-center">
                                    <i className="me-2 ri-user-line" /> Client Details
                                 </span>
                              }>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Contact Details",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "View Contacts",
                                    })
                                 }>
                                 Contact Details
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Referral Details",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "Referral",
                                    })
                                 }>
                                 Referral
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Employment Details",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "Employment",
                                    })
                                 }>
                                 Employment
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Doctor Details",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "doctor",
                                    })
                                 }>
                                 Doctor
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Diagnosis",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "diagnosis",
                                    })
                                 }>
                                 Diagnosis
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Secondary Treatments",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "secondary",
                                    })
                                 }>
                                 Secondary Treatments
                              </Dropdown.Item>
                              <Dropdown.Item
                                 onClick={() =>
                                    handleDropdownItemClick({
                                       id: firstPayloadNote?.clientid,
                                       name: firstPayloadNote?.clientname,
                                       category: "Medications",
                                       treatmentid: firstPayloadNote?.treatmentid,
                                       treatmentname: firstPayloadNote?.treatmentname,
                                       action: "medications",
                                    })
                                 }>
                                 Medications
                              </Dropdown.Item>
                           </DropdownButton>
                           <Button
                              variant="link"
                              className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center" // Remove margin for tight spacing
                              style={{ backgroundColor: "transparent" }}
                              onClick={() =>
                                 handleDropdownItemClick({
                                    id: firstPayloadNote?.clientid,
                                    name: firstPayloadNote?.clientname,
                                    category: "Documents",
                                    treatmentid: firstPayloadNote?.treatmentid,
                                    treatmentname: firstPayloadNote?.treatmentname,
                                    action: "Documents",
                                 })
                              }>
                              <i className="me-2 ri-file-list-line" /> Documents
                           </Button>
                           <Button
                              variant="link"
                              className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center" // Remove margin for tight spacing
                              style={{ backgroundColor: "transparent" }}
                              onClick={() =>
                                 handleDropdownItemClick({
                                    id: firstPayloadNote?.clientid,
                                    name: firstPayloadNote?.clientname,
                                    category: "Requests",
                                    treatmentid: firstPayloadNote?.treatmentid,
                                    treatmentname: firstPayloadNote?.treatmentname,
                                    action: "Requests",
                                 })
                              }>
                              <i className="me-2 ri-user-voice-line" /> Requests
                           </Button>
                           <Button
                              variant="link"
                              className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center"
                              style={{ backgroundColor: "transparent" }}
                              onClick={() =>
                                 handleDropdownItemClick({
                                    id: firstPayloadNote?.clientid,
                                    name: firstPayloadNote?.clientname,
                                    category: "Reminders",
                                    treatmentid: firstPayloadNote?.treatmentid,
                                    treatmentname: firstPayloadNote?.treatmentname,
                                    action: "Reminders",
                                 })
                              }>
                              <i className="me-2 ri-notification-3-line" /> Reminders
                           </Button>
                           <Button
                              variant="link"
                              className="btn-bc button-transparent border-0 shadow-none d-flex align-items-center"
                              style={{ backgroundColor: "transparent" }}
                              disabled={payloadEmailConsent?.consentAcceptance}
                              onClick={() => handleEmailConsentClick()}>
                              <i className="me-2 ri-mail-send-line" /> {payloadEmailConsent?.consentAcceptance ? "Consent Accepted" : "Email Consent"}
                           </Button>
                        </div>
                     </div>
                  </div>
               </Col>
               <Col>
                  <ListGroup horizontal className="mb-3 d-flex flex-wrap">
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">Case</div>
                        <div className="fs-6 text-start">{firstPayloadNote?.caident}</div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">Account Manager</div>
                        <div className="fs-6 text-start">{firstPayloadNote?.acctmgr_name}</div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">Session Allocation</div>
                        <div className="fs-6 text-start">
                           {firstPayloadNote?.totalsessions}{" "}
                           {firstPayloadNote?.remainingsessions && firstPayloadNote?.remainingsessions > 0 ? (
                              <>
                                 <span className="fs-7">remaining</span> {firstPayloadNote?.remainingsessions}
                              </>
                           ) : (
                              ""
                           )}
                        </div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">
                           Treatment <StatusBadge name={firstPayloadNote?.treatmentstatus} bg="warning" />
                        </div>
                        <div className="fs-6 text-start">{firstPayloadNote?.treatmentname}</div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">Gender</div>
                        <div className="fs-6 text-start">{firstPayloadNote?.clientgender}</div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">Age</div>
                        <div className="fs-6 text-start">
                           <ClientAge dob={firstPayloadNote?.clientdbo} age={firstPayloadNote?.clientage} />
                        </div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">
                           Date <span style={{ fontSize: "10px" }}>of</span> Disability
                        </div>
                        <div className="fs-6 text-start">{firstPayloadNote?.clientdod}</div>
                     </ListGroupItem>
                     <ListGroupItem className="border-0">
                        <div className="fs-7 mb-0 text-muted">
                           Change <span style={{ fontSize: "10px" }}>of</span> Definition
                        </div>
                        <div className="fs-6 text-start">{firstPayloadNote?.clientcod}</div>
                     </ListGroupItem>
                  </ListGroup>
               </Col>
            </Row>
            <Row>
               <Col xl={12} xxl={10}>
                  <Form ref={noteFormRef} id="treatmentNoteForm" onSubmit={handleSubmit(onSubmit)}>
                     <div className="card-bc card border-0">
                        <div className="card-body border-top border-1" style={{ "--card-body-content": "'Treatment Note'" }}>
                           <Row className="g-0 mt-3">
                              <Col>
                                 <Controller name="treatmentId" control={control} render={({ field }) => <input type="hidden" {...field} />} />
                                 <Controller
                                    control={control}
                                    name="treatmentNote"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                       <div>
                                          <CKEditor
                                             editor={ClassicEditor}
                                             name="treatmentNote"
                                             onReady={(editor) => {
                                                editorNoteRef.current = editor;
                                                editor.ui.view.editable.element.style.height = "200px";
                                             }}
                                             data={value}
                                             onChange={(event, editor) => {
                                                const data = editor.getData().trim();
                                                setValue("treatmentNote", data);
                                                if (errors.treatmentNote) {
                                                   clearErrors("treatmentNote"); // Clear the error if present
                                                }
                                             }}
                                          />
                                       </div>
                                    )}
                                 />
                                 {errors.treatmentNote?.message && <div className="text-danger">{errors.treatmentNote.message}</div>}
                              </Col>
                           </Row>
                           <Row className="g-0 mt-3 align-items-start">
                              <Col className="d-flex flex-column align-items-center justify-content-center">
                                 <div className="d-flex justify-content-center w-100">
                                    <div className="d-flex flex-column align-items-start me-3">
                                       <Controller
                                          name="treatmentNoteDate"
                                          control={control}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                             <>
                                                <label className="form-label" htmlFor="treatmentNoteDate">
                                                   Date
                                                </label>
                                                <DatePicker
                                                   dateFormat="yyyy-MM-dd"
                                                   id="treatmentNoteDate"
                                                   selected={field.value}
                                                   onChange={(date) => field.onChange(date)}
                                                   className="form-control picker"
                                                />
                                                {errors["treatmentNoteDate"] && <div className="text-danger">{errors["treatmentNoteDate"].message}</div>}
                                             </>
                                          )}
                                       />
                                    </div>
                                    <div className="d-flex flex-column align-items-start me-3">
                                       <Controller
                                          name="treatmentNoteTime"
                                          control={control}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                             <>
                                                <label className="form-label" htmlFor="treatmentNoteTime">
                                                   Time
                                                </label>
                                                <DatePicker
                                                   selected={field.value}
                                                   onChange={(date) => field.onChange(date)}
                                                   showTimeSelect
                                                   id="treatmentNoteTime"
                                                   showTimeSelectOnly
                                                   timeIntervals={30}
                                                   timeCaption="Time"
                                                   dateFormat="h:mm aa"
                                                   className="form-control picker"
                                                />
                                             </>
                                          )}
                                       />
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                       <Form.Group controlId="treatmentNoteType-9">
                                          <fieldset>
                                             <legend style={{ fontSize: "14px", fontWeight: "600" }}>Note type:</legend>
                                             <Col>
                                                <Controller
                                                   name="treatmentNoteType"
                                                   control={control}
                                                   defaultValue=""
                                                   render={({ field }) => (
                                                      <>
                                                         <Form.Check
                                                            type="radio"
                                                            id="treatmentNoteType-9"
                                                            label="Telephone"
                                                            value="9"
                                                            {...field}
                                                            onChange={(e) => {
                                                               if (e.target.checked) {
                                                                  setValue("treatmentNoteType", "9");
                                                               }
                                                               clearErrors("treatmentNoteType");
                                                            }}
                                                         />
                                                         <Form.Check
                                                            type="radio"
                                                            id="treatmentNoteType-8"
                                                            label="Email"
                                                            value="8"
                                                            {...field}
                                                            onChange={(e) => {
                                                               if (e.target.checked) {
                                                                  setValue("treatmentNoteType", "8");
                                                               }
                                                               clearErrors("treatmentNoteType");
                                                            }}
                                                         />
                                                         <Form.Check
                                                            type="radio"
                                                            id="treatmentNoteType-7"
                                                            label="Conference Call"
                                                            value="7"
                                                            {...field}
                                                            onChange={(e) => {
                                                               if (e.target.checked) {
                                                                  setValue("treatmentNoteType", "7");
                                                               }
                                                               clearErrors("treatmentNoteType");
                                                            }}
                                                         />
                                                      </>
                                                   )}
                                                />
                                                {errors.treatmentNoteType && <div className="text-danger">{errors.treatmentNoteType.message}</div>}
                                             </Col>
                                          </fieldset>
                                       </Form.Group>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs="auto" className="d-flex align-items-start justify-content-end ms-auto">
                                 <Button type="submit" form="treatmentNoteForm" disabled={isSubmitting} variant="primary">
                                    {isSubmitting ? "Saving Note..." : "Save Note"}
                                 </Button>
                              </Col>
                           </Row>
                        </div>
                     </div>
                  </Form>
               </Col>
            </Row>
            <Row>
               <Col xl={12} xxl={10}>
                  <div className="card-bc card border-0">
                     <div
                        className="card-body border-top border-1 has-right-content"
                        style={{
                           "--card-body-content": `"${firstPayloadNote?.treatmentname} Notes"`,
                           "--card-body-content-right": "'Additional Notes'",
                        }}></div>
                     <FilterEventTypeButtons eventTypeCounts={eventTypeCounts} />
                  </div>
               </Col>
            </Row>
            <Tab.Container activeKey={renderNotesActiveTab} onSelect={handleTabChange}>
               <Row>
                  <Col xl={12} xxl={10}>
                     <Row>
                        <Col className="border-bottom border-1">
                           <Nav variant="underline" className="d-flex justify-content-between">
                              <div className="d-flex align-items-end">
                                 <Nav.Item key={`summaryitem-${firstPayloadNote?.eventid}`}>
                                    <Nav.Link key={`summarykey-${firstPayloadNote?.eventid}`} eventKey="summary" className="fw-semibold">
                                       Summary
                                    </Nav.Link>
                                 </Nav.Item>
                                 <span className="ms-3 me-3 mt-3 d-flex align-self-center">|</span>
                                 <Nav.Item key={`withnoteitem-${firstPayloadNote?.eventid}`}>
                                    <Nav.Link key={`withnotelink-${firstPayloadNote?.eventid}`} eventKey="withnote" className="fw-semibold">
                                       With Notes
                                    </Nav.Link>
                                 </Nav.Item>
                              </div>
                              <div className="d-flex">
                                 <OtherTreatmentsNav payloadOtherTreatments={payloadOtherTreatments} handleOtherTabLink={handleOtherTabLink} />
                              </div>
                           </Nav>
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                           <Tab.Content>
                              <Tab.Pane eventKey="summary">
                                 <TreatmentRenderedNotes payloadNotes={payloadNotes} tab="summary" tabType="treatment" />
                              </Tab.Pane>
                              <Tab.Pane eventKey="withnote">
                                 <TreatmentRenderedNotes payloadNotes={payloadNotes} tab="withnote" tabType="treatment" />
                              </Tab.Pane>
                              <OtherTreatmentsPane
                                 payloadOtherTreatments={payloadOtherTreatments}
                                 loadedOtherTabs={loadedOtherTabs}
                                 loadedOtherData={loadedOtherData}
                              />
                           </Tab.Content>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Tab.Container>
            <Footer />
         </div>
         {showClientModal && (
            <ClientModal
               clientId={selectedClientId}
               clientName={selectedClientName}
               category={selectedCategory}
               treatmentId={selectedTreatmentId}
               treatmentName={selectedTreatmentName}
               eData={editNote}
               action={selectedAction}
               eMode={selectedMode}
               skin={skin}
            />
         )}
         {showSessionModal && (
            <>
               <SessionModal
                  clientId={selectedClientId}
                  clientName={selectedClientName}
                  treatmentId={selectedTreatmentId}
                  treatmentName={selectedTreatmentName}
                  eData={editNote}
                  eMode={selectedMode}
                  skin={skin}
               />
            </>
         )}
         {showDocumentModal && (
            <>
               <DocumentModal
                  clientId={selectedClientId}
                  clientName={selectedClientName}
                  treatmentId={selectedTreatmentId}
                  treatmentName={selectedTreatmentName}
                  onCloseModal={handleDocumentModalClose}
                  skin={skin}
               />
            </>
         )}
         {showReminderModal && (
            <>
               <ReminderModal
                  clientId={selectedClientId}
                  clientName={selectedClientName}
                  treatmentId={selectedTreatmentId}
                  treatmentName={selectedTreatmentName}
                  onCloseModal={handleReminderModalClose}
                  skin={skin}
               />
            </>
         )}
         {showRequestModal && (
            <>
               <RequestModal
                  clientId={selectedClientId}
                  clientName={selectedClientName}
                  treatmentId={selectedTreatmentId}
                  treatmentName={selectedTreatmentName}
                  onCloseModal={handleRequestModalClose}
                  skin={skin}
               />
            </>
         )}
      </>
   );
}
