import React from "react";

// Dashboard
import Home from "../dashboard/Home";
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagement from "../dashboard/ProductManagement";

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Clients from "../apps/Clients";
import ParentTest from "../apps/ParentTest";
import Notes from "../apps/Notes";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";

const protectedRoutes = [
   { path: "dashboard/home", link: <Home /> },
   { path: "dashboard/finance", link: <FinanceMonitoring /> },
   { path: "dashboard/events", link: <EventManagement /> },
   { path: "dashboard/sales", link: <SalesMonitoring /> },
   { path: "dashboard/analytics", link: <WebsiteAnalytics /> },
   { path: "dashboard/crypto", link: <Cryptocurrency /> },
   { path: "dashboard/helpdesk", link: <HelpdeskService /> },
   { path: "dashboard/storage", link: <StorageManagement /> },
   { path: "dashboard/product", link: <ProductManagement /> },
   { path: "apps/gallery-music", link: <GalleryMusic /> },
   { path: "apps/gallery-video", link: <GalleryVideo /> },
   { path: "apps/tasks", link: <Tasks /> },
   { path: "apps/clients", link: <Clients /> },
   { path: "apps/parenttest", link: <ParentTest /> },
   { path: "apps/notes", link: <Notes /> },
   { path: "apps/contacts", link: <Contacts /> },
   { path: "apps/chat", link: <Chat /> },
   { path: "apps/calendar", link: <AppCalendar /> },
   { path: "apps/email", link: <Email /> },
   { path: "apps/file-manager", link: <FileManager /> },
   { path: "pages/pricing", link: <Pricing /> },
   { path: "pages/faq", link: <Faq /> },
   { path: "pages/profile", link: <Profile /> },
   { path: "pages/people", link: <People /> },
   { path: "pages/activity", link: <Activity /> },
   { path: "pages/events", link: <Events /> },
   { path: "pages/settings", link: <Settings /> },
   { path: "docs/layout/grid", link: <LayoutGrid /> },
   { path: "docs/layout/columns", link: <LayoutColumns /> },
   { path: "docs/layout/gutters", link: <LayoutGutters /> },
   { path: "docs/com/accordions", link: <Accordions /> },
   { path: "docs/com/alerts", link: <Alerts /> },
   { path: "docs/com/avatars", link: <Avatars /> },
   { path: "docs/com/badge", link: <Badges /> },
   { path: "docs/com/breadcrumbs", link: <Breadcrumbs /> },
   { path: "docs/com/buttons", link: <Buttons /> },
   { path: "docs/com/cards", link: <Cards /> },
   { path: "docs/com/carousel", link: <Carousels /> },
   { path: "docs/com/dropdown", link: <Dropdowns /> },
   { path: "docs/com/images", link: <Images /> },
   { path: "docs/com/listgroup", link: <Listgroup /> },
   { path: "docs/com/markers", link: <Markers /> },
   { path: "docs/com/modal", link: <Modals /> },
   { path: "docs/com/navtabs", link: <NavTabs /> },
   { path: "docs/com/offcanvas", link: <OffCanvas /> },
   { path: "docs/com/pagination", link: <Paginations /> },
   { path: "docs/com/placeholders", link: <Placeholders /> },
   { path: "docs/com/popovers", link: <Popovers /> },
   { path: "docs/com/progress", link: <Progress /> },
   { path: "docs/com/spinners", link: <Spinners /> },
   { path: "docs/com/toasts", link: <Toasts /> },
   { path: "docs/com/tooltips", link: <Tooltips /> },
   { path: "docs/com/tables", link: <Tables /> },
   { path: "docs/form/links", link: <FormElements /> },
   { path: "docs/form/selects", link: <FormSelects /> },
   { path: "docs/form/checksradios", link: <FormChecksRadios /> },
   { path: "docs/form/range", link: <FormRange /> },
   { path: "docs/form/pickers", link: <FormPickers /> },
   { path: "docs/form/layouts", link: <FormLayouts /> },
   { path: "docs/chart/apex", link: <ApexCharts /> },
   { path: "docs/chart/chartjs", link: <ChartJs /> },
   { path: "docs/map/leaflet", link: <MapLeaflet /> },
   { path: "docs/map/vector", link: <MapVector /> },
   { path: "docs/icon/remix", link: <IconRemix /> },
   { path: "docs/icon/feather", link: <IconFeather /> },
   { path: "docs/util/background", link: <UtilBackground /> },
   { path: "docs/util/border", link: <UtilBorder /> },
   { path: "docs/util/colors", link: <UtilColors /> },
   { path: "docs/util/divider", link: <UtilDivider /> },
   { path: "docs/util/flex", link: <UtilFlex /> },
   { path: "docs/util/sizing", link: <UtilSizing /> },
   { path: "docs/util/spacing", link: <UtilSpacing /> },
   { path: "docs/util/opacity", link: <UtilOpacity /> },
   { path: "docs/util/position", link: <UtilPosition /> },
   { path: "docs/util/typography", link: <UtilTypography /> },
   { path: "docs/util/shadows", link: <UtilShadows /> },
   { path: "docs/util/extras", link: <UtilExtras /> },
];

export default protectedRoutes;
