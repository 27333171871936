import React, { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using React Bootstrap

const ModalDialogue = ({ isOpen, question, onModalDialogueResponse }) => {
   const modalRef = useRef(null);

   const handleClose = (response) => {
      onModalDialogueResponse(response);
   };

   return (
      <>
         isOpen && (
         <Modal centered={true} backdrop="static" ref={modalRef} show={isOpen} onHide={handleClose} animation={false} style={{ zIndex: 1050 }}>
            <Modal.Header closeButton>
               <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <p>{question}</p>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => handleClose("go_back")}>
                  No, Go Back
               </Button>
               <Button variant="primary" onClick={() => handleClose("discard")}>
                  Discard Changes!
               </Button>
            </Modal.Footer>
         </Modal>
         )
      </>
   );
};

export default ModalDialogue;
