import { Toast, ToastContainer } from "react-bootstrap";
import { useState, createContext, useContext, useCallback } from "react";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
   const [toasts, setToasts] = useState([]);

   const triggerToast = useCallback(
      (status, message, isHeading = false, headingTitle = "", autoHide = true, delay = 3500) => {
         const id = Date.now();
         setToasts([...toasts, { id, message, status, isHeading, headingTitle, autoHide, delay }]);

         if (autoHide) {
            setTimeout(() => {
               setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
            }, delay);
         }
      },
      [toasts]
   );

   const handleRemoveToast = useCallback((id) => {
      setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
   }, []);

   const toastClassNames = "bc-toasts-success position-relative";

   console.log("TOASTS:", toasts);

   // linear-gradient(to right, #b7e7b7, white);

   return (
      <ToastContext.Provider value={{ triggerToast }}>
         {children}
         <ToastContainer className="p-3" position="top-center" style={{ zIndex: 1050, position: "fixed" }}>
            {toasts.map((toast, index) => (
               <Toast
                  key={toast.id}
                  onClose={() => handleRemoveToast(toast.id)}
                  autohide={toast.isHeading ? false : !toast.autoHide ? true : toast.autoHide}
                  delay={toast.isHeading ? null : toast.delay === 0 ? 3500 : toast.delay}
                  className={`bc-toasts-${toast.status} position-relative`}
                  show>
                  {toast.isHeading && (
                     <Toast.Header className={`bc-toasts-${toast.status} bc-toasts-header`} closeButton={true}>
                        <strong className="me-auto">{toast.headingTitle}</strong>
                     </Toast.Header>
                  )}
                  <Toast.Body className={`bc-toasts bc-toasts-${toast.status}`}>{toast.message}</Toast.Body>
               </Toast>
            ))}
         </ToastContainer>
      </ToastContext.Provider>
   );
};
