export const REQUESTSUBJECTS = [
   { value: 0, label: "" },
   { value: 1, label: "PGAP additional treatment" },
   { value: 2, label: "Other treatment" },
   { value: 3, label: "Session delivery method" },
   { value: 4, label: "Workbook" },
   { value: 5, label: "Invoice" },
   { value: 6, label: "Report" },
   { value: 7, label: "Consent" },
   { value: 8, label: "Intake" },
   { value: 9, label: "Other" },
];

export const REQUESTSTATUS = [
   { value: 0, label: "" },
   { value: 1, label: "Requested" },
   { value: 2, label: "Accepted" },
   { value: 3, label: "Declined" },
   { value: 4, label: "Deleted" },
   { value: 5, label: "Completed" },
];
