import React from "react";

function InitialsAvatar({ name, className = "avatar-xl avatar-rounded-xl", color = "secondary" }) {
   const initials = name ? generateInitials(name) : "";

   return (
      <div className={className}>
         <span className={`avatar-initial bg-${color}`}>{initials}</span>
      </div>
   );
}

function generateInitials(clientName) {
   if (!clientName) {
      return ""; // Return empty string for undefined or empty clientName
   }
   const words = clientName.split(" ");
   const firstName = words[0];
   const lastName = words[words.length - 1];
   const initials = firstName.charAt(0) + lastName.charAt(0);
   return initials.toUpperCase();
}

export default InitialsAvatar;
