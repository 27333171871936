import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/authContext";
import { ReactComponent as Logo } from "../assets/svg/crh.svg";
import crhLogoWhite from "../assets/img/crh-logo-white.png";
import crhLogoDark from "../assets/img/crh-logo-dark.png";
import { useNavigate, Link } from "react-router-dom";

import useDeviceDetect from "../components/useDeviceDetect";
import useFetchData from "../components/useFetchDatav3"; // Import the custom hook

import "./Signin3.styles.css";

const Signin3 = () => {
   const { fetchData } = useFetchData();
   const { currentUser, login, logout } = useAuth();
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [isSigningIn, setIsSigningIn] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");

   const onSubmit = useCallback(
      async (e) => {
         console.log("submitting login form");
         e.preventDefault();
         if (!isSigningIn) {
            console.log("Form submitted");
            //console.log(auditData);
            setIsSigningIn(true);
            try {
               const userCredential = await login(email, password);
               const user = userCredential.user;

               if (user) {
                  const verified = await verifyToken(user.accessToken, email);

                  if (verified) {
                     if (verified.st === 1) {
                        navigate("/dashboard/home", { replace: true });
                     } else {
                        setErrorMessage("Your account could not be authenticated, contact support");
                     }
                  } else {
                     setErrorMessage("Your account could not be authenticated, contact support");
                  }
               } else {
                  setErrorMessage("Invalid user or could not be verified, contact support");
               }
            } catch (error) {
               const firebaseErrorCode = error.code;
               let errorMessage = "Sign-in failed";
               switch (firebaseErrorCode) {
                  case "auth/invalid-credential":
                     errorMessage = "authentication token is in error";
                     break;
                  case "auth/wrong-password":
                     errorMessage = "Invalid password";
                     break;
                  case "auth/user-not-found":
                     errorMessage = "Invalid email";
                     break;
                  default:
                     console.error("Unexpected error:", error);
               }
               setErrorMessage(errorMessage);
               //updateUserLoggedInStatus(false);
            } finally {
               // Reset signing-in state
               setIsSigningIn(false);
            }
         }
      },
      [email, password, isSigningIn]
   );

   // useEffect(() => {
   //    if (!fetchLoading) {
   //       if (!fetchError && fetchDataResponse) {
   //          navigate("/dashboard/home", { replace: true });
   //       } else if (fetchError) {
   //          // Handle error response from verification API
   //          console.error("Verification error:", fetchError);
   //          setErrorMessage(fetchError);
   //          setIsSigningIn(false);
   //       }
   //    }
   // }, [fetchDataResponse, fetchLoading, fetchError, navigate]);

   const verifyToken = async (token, email) => {
      const postData = {
         email: email,
         token: token,
      };

      const url = "/verifytoken.sz";
      const method = "POST";
      const body = postData;
      const contentType = "application/x-www-form-urlencoded";

      try {
         const { responseData, fetchLoading, fetchError } = await fetchData(url, method, body, contentType);
         console.log(responseData);
         console.log(fetchLoading);
         console.log(fetchError);
         if (responseData.st === 1 || responseData.st === 2) {
            return {
               st: responseData.st,
               payload: responseData.payload,
            };
         } else {
            setIsSigningIn(false);

            if (responseData.m_en) {
               setErrorMessage(responseData.m_en);
            } else {
               setErrorMessage("We seem to have an issue authenticating, please try again later.");
            }
            return { st: -1, payload: null };
         }
      } catch (error) {
         console.error("Error fetching data:", error);
         setIsSigningIn(false);
      }
   };

   // const onGoogleSignIn = (e) => {
   //    e.preventDefault();
   //    if (!isSigningIn) {
   //       setIsSigningIn(true);
   //       doSignInWithGoogle().catch((err) => {
   //          setIsSigningIn(false);
   //       });
   //    }
   // };

   useEffect(() => {
      if (currentUser) {
         logout()
            .then(() => {
               console.log("User signed out successfully.");
            })
            .catch((error) => {
               setErrorMessage("Could not signout, contact support");
               console.error("Sign out error:", error);
            });
      }
      // eslint-disable-next-line
   }, []);

   return (
      <>
         <div id="main-wrapper" style={{ minHeight: "100vh" }}>
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 w-100">
               <div className="position-relative z-index-5">
                  <div className="row">
                     <div className="col-xl-7 col-xxl-8">
                        <a href="login.html" className="d-none d-xl-flex text-nowrap logo-img d-block px-4 py-9 w-100">
                           <img src={crhLogoWhite} className="light-logo" alt="CRH" />
                        </a>
                        <div className="d-none d-xl-flex align-items-center justify-content-center" style={{ height: "calc(100vh - 80px)" }}>
                           <Logo />
                        </div>
                     </div>
                     <div className="col-xl-5 col-xxl-4 min-vh-100 d-flex flex-column">
                        <div className="bg-body row justify-content-between align-items-start p-4">
                           <div className="col-auto ps-0">
                              <h5 className="perry" style={{ color: "#0c90cb" }}>
                                 Centre for Rehabilitation and Health
                              </h5>
                           </div>
                           <div className="col-auto ms-auto">
                              <a href="login.html" className="text-nowrap logo-img d-block px-4 py-9">
                                 <img src={crhLogoDark} className="logo" alt="CRH" />
                              </a>
                           </div>
                        </div>
                        <div className="flex-grow-1 authentication-login bg-body row justify-content-center align-items-center p-4">
                           <div className="auth-max-width col-sm-8 col-md-6 col-xl-7 px-4">
                              <h2 className="mb-5 fs-3 fw-bolder">Welcome to CRH</h2>
                              <form className="form-horizontal form-material" id="loginForm" onSubmit={onSubmit}>
                                 <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                       Username
                                    </label>
                                    <input
                                       className="form-control"
                                       type="email"
                                       required=""
                                       minLength="6"
                                       id="email"
                                       name="email"
                                       placeholder="Email"
                                       autoComplete="off"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                    />
                                 </div>
                                 <div className="mb-4">
                                    <label htmlFor="loginPassword" className="form-label">
                                       Password
                                    </label>
                                    <input
                                       className="form-control"
                                       type="password"
                                       id="loginPassword"
                                       name="loginPassword"
                                       placeholder="Password"
                                       autoComplete="current-password"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                    />
                                 </div>

                                 {errorMessage && <span className="text-red-600 font-bold">{errorMessage}</span>}

                                 {/* {siteKey && (
                                    <ReCAPTCHA sitekey={siteKey} onloadCallback={() => setRecaptchaLoaded(true)} verifyCallback={handleVerifyCallback} />
                                 )} */}
                                 <div className="d-flex justify-content-center align-items-center">
                                    <button
                                       type="submit"
                                       disabled={isSigningIn}
                                       className={`btn btn-primary w-50 py-8 mt-5 mb-4 rounded-5> ${
                                          isSigningIn
                                             ? "bg-gray-300 cursor-not-allowed"
                                             : "bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
                                       }`}>
                                       {isSigningIn ? "Signing In..." : "Sign In"}
                                    </button>
                                 </div>
                                 <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                       <Link to="/reset-password">
                                          <span className="fs-7">Forgot your password?</span>
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-center justify-content-center mt-3">
                                    <div className="fs-7 language">
                                       <span className="fs-7">By clicking &quot;Sign In&quot;, you agree to our </span>
                                       <a href="https://clinic/terms.sz" target="_blank" rel="noreferrer">
                                          <span className="fs-7 language"> Terms of Service </span>
                                       </a>
                                       and
                                       <a href="https://clinic/privacy.sz" target="_blank" rel="noreferrer">
                                          <span className="fs-7 language"> Privacy Policy</span>
                                       </a>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Signin3;
