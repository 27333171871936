export const APPOINTMENT_STATUS_OPTIONS = [
   // { value: 0, label: "Select appointment status" },
   { value: 1, label: "Show" },
   { value: 2, label: "Cancelled" },
   { value: 4, label: "Client No-Show" },
];
export const APPOINTMENT_LOCATION_OPTIONS = [
   { value: 0, label: "Select location" },
   { value: 1, label: "Remote" },
   { value: 2, label: "At Clients" },
   { value: 3, label: "At Office" },
   { value: 4, label: "Community" },
];
export const APPOINTMENT_REPEAT_OPTIONS = [
   { value: "", label: "No Repeat", singular: "", plural: "" },
   { value: "daily", label: "Daily", singular: "Day", plural: "Days" },
   { value: "weekly", label: "Weekly", singular: "Week", plural: "Weeks" },
];
export const APPOINTMENT_END_TYPE_OPTIONS = [
   { value: "", label: "Select Ending On" },
   { value: "after", label: "After" },
   { value: "ondate", label: "On Date" },
];
export const APPOINTMENT_REPEAT_OCCURANCE_OPTIONS = [
   { value: 1, label: "1" },
   { value: 2, label: "2" },
   { value: 3, label: "3" },
   { value: 4, label: "4" },
];
export const APPOINTMENT_REPEAT_END_AFTER_OPTIONS = [
   { value: 1, label: "1" },
   { value: 2, label: "2" },
   { value: 3, label: "3" },
   { value: 4, label: "4" },
   { value: 5, label: "5" },
   { value: 6, label: "6" },
   { value: 7, label: "7" },
   { value: 8, label: "8" },
   { value: 9, label: "9" },
   { value: 10, label: "10" },
   { value: 11, label: "11" },
   { value: 12, label: "12" },
   { value: 13, label: "13" },
   { value: 14, label: "14" },
   { value: 15, label: "15" },
];
export const APPOINTMENT_DURATION_OPTIONS = [
   { value: 5, label: "5  min" },
   { value: 10, label: "10 min" },
   { value: 15, label: "15 min" },
   { value: 20, label: "20 min" },
   { value: 25, label: "25 min" },
   { value: 30, label: "30 min" },
   { value: 35, label: "35 min" },
   { value: 40, label: "40 min" },
   { value: 45, label: "45 min" },
   { value: 50, label: "50 min" },
   { value: 55, label: "55 min" },
   { value: 60, label: "60 min" },
   { value: 65, label: "1:05 hrs" },
   { value: 70, label: "1:10 hrs" },
   { value: 75, label: "1:15 hrs" },
   { value: 80, label: "1:20 hrs" },
   { value: 85, label: "1:25 hrs" },
   { value: 90, label: "1:30 hrs" },
   { value: 95, label: "1:35 hrs" },
   { value: 100, label: "1:40 hrs" },
   { value: 105, label: "1:45 hrs" },
   { value: 110, label: "1:50 hrs" },
   { value: 115, label: "1:55 hrs" },
];
