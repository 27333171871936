import { createContext, useContext, useEffect, useState } from "react";
import { firebaseApp } from "../firebase/firebase";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import LoadingSpinner from "../components/LoadingSpinner";

const AuthContext = createContext({
   currentUser: null,
   loading: false,
   setUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
   const [currentUser, setCurrentUser] = useState(null);
   const [isAuthenticated, setIsAuthenticated] = useState(false);
   const [loading, setLoading] = useState(true);
   const [clientCardView, setClientCardView] = useState({});
   const auth = getAuth(firebaseApp);

   function login(email, password) {
      return signInWithEmailAndPassword(auth, email, password);
   }

   function logout() {
      return signOut(auth);
   }

   useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
         //custom claims from firebase
         //config: {
         //   calendar: {view: "month"}
         //   client: {sort: {sort_field: 'cs_open_date', sort_type: 'dateField', sort_dir: 'desc'}, view: "card"}
         //   global: {settings: {langauge: 'en-CA', date: 'YYYY-MM-DD', time: 'HH12:MI AM'}, skin: ""}
         //}

         if (user) {
            user
               .getIdTokenResult()
               .then((idTokenResult) => {
                  const claims = idTokenResult.claims;
                  setCurrentUser({ ...user, customClaims: claims });
                  setIsAuthenticated(true);
                  setClientCardView(claims.config.client);
                  console.log("currentUser Custom Claims set", claims);
               })
               .catch((error) => {
                  console.error("Error fetching custom claims:", error);
               })
               .finally(() => {
                  setLoading(false);
               });

            // user.getIdTokenResult()
            //    .then((idTokenResult) => {
            //       const { claims } = idTokenResult; // Extract claims
            //       const customClaims = { ...claims }; // Include all claims
            //       console.log("useAuth useEffect Claims -> " + JSON.stringify(customClaims));
            //       setCurrentUser({ ...user, customClaims });
            //       console.log("useAuth currentUser -> " + JSON.stringify(currentUser));
            //    })
            //    .catch((error) => {
            //       console.error("Error fetching custom claims:", error);
            //    })
            //    .finally(() => {
            //       setLoading(false); // Set loading state to false
            //    });
            //}
         } else {
            setCurrentUser(null);
            setIsAuthenticated(false);
            setLoading(false);
         }
      });
      return unsubscribe; // Cleanup function to prevent memory leaks
   }, [auth]);

   const value = {
      currentUser,
      setCurrentUser,
      login,
      logout,
      loading,
      isAuthenticated,
      clientCardView,
      setClientCardView,
   };

   useEffect(() => {
      console.log("is authenticated", isAuthenticated);
   }, [isAuthenticated]);

   return <AuthContext.Provider value={value}>{loading ? <LoadingSpinner /> : children}</AuthContext.Provider>;
}
