import { Modal, Button } from "react-bootstrap";
import { useModal } from "../context/ErrorModalContext";

const ErrorModal = () => {
   const { modal, closeModal } = useModal();

   return (
      <Modal show={modal.isOpen} onHide={closeModal}>
         <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
         </Modal.Header>
         <Modal.Body>{modal.message}</Modal.Body>
         <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
               Close
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default ErrorModal;
