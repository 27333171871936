const appointments = [
   {
      id: "2666636c-0ca0-4a3e-be74-b601f942a5c4",
      start: "2024-02-05T09:22:00.000Z",
      end: "2024-02-05T10:22:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "fa625dc7-4fa1-48a6-a0d0-e01665664125",
         treatmentname: "Psychotherapy",
         islocked: false,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "e4d1e9d4-4e3e-4862-959d-07885f81c87c",
      start: "2024-02-06T15:39:00.000Z",
      end: "2024-02-06T16:39:00.000Z",
      title: "Model Tesla",

      status: 0,
      message_en: null,
      message_fr: null,
      className: "app-past-event",
      extendedProps: {
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 2,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "fa625dc7-4fa1-48a6-a0d0-e01665664125",
         treatmentname: "Psychotherapy",
         islocked: false,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "d67176b6-8da0-451a-b86e-a2fe4a02e126",
      title: "Model Tesla",
      start: "2024-02-07T16:30:00.000Z",
      end: "2024-02-07T17:30:00.000Z",
      className: "app-past-event",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,

         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "fa625dc7-4fa1-48a6-a0d0-e01665664125",
         treatmentname: "Psychotherapy",
         islocked: false,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "3794fb97-1a93-41a7-8a87-4cb4580663d6",
      start: "2024-02-08T07:53:00.000Z",
      end: "2024-02-08T08:53:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event app-cancelled",

         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: true,
         appointmentstatus: 2,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 3,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "91e0f295-7e9e-49d0-acf7-da5e28bf9aa7",
      start: "2024-02-13T13:42:00.000Z",
      end: "2024-02-13T14:42:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: true,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: true,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 3,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: 1,
         other_split_id: "d6643568-bec2-480c-ab75-5e3bf2da1aac",
         other_split_apt_date: "2024-02-15T13:42:00.000Z",
      },
   },
   {
      id: "cf85a535-2065-4139-a87b-5b4ffeaf1bb6",
      start: "2024-02-13T16:04:00.000Z",
      end: "2024-02-13T17:04:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         className: "app-past-event",
         status: 0,
         message_en: null,
         message_fr: null,
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: true,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 3,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "fa625dc7-4fa1-48a6-a0d0-e01665664125",
         treatmentname: "Psychotherapy",
         islocked: false,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "086f2f7b-f4f2-435b-b80d-3d74beb52d5a",
      start: "2024-02-14T13:42:00.000Z",
      end: "2024-02-14T14:42:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: true,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: 1,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "d6643568-bec2-480c-ab75-5e3bf2da1aac",
      start: "2024-02-15T13:42:00.000Z",
      end: "2024-02-15T14:42:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: true,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: 2,
         other_split_id: "91e0f295-7e9e-49d0-acf7-da5e28bf9aa7",
         other_split_apt_date: "2024-02-13T13:42:00.000Z",
      },
   },
   {
      id: "758506db-af99-4145-8804-26c0651ad054",
      start: "2024-02-16T07:53:00.000Z",
      end: "2024-02-16T08:53:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 3,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "6036c769-0d6b-40b2-a529-0ee7fbecaeed",
      start: "2024-02-17T13:41:00.000Z",
      end: "2024-02-17T13:41:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "case-offer case-accept",
         clientappointment: 1,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 7,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: null,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 7,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "58643af9-2b93-47f1-9e7e-659336f6d2f0",
      start: "2024-02-19T16:35:00.000Z",
      end: "2024-02-19T17:35:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "248ffb6b-5562-4080-9f06-1cb4187962bc",
         treatmentname: "PGAP-Tel",
         islocked: false,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "80e5b022-aeed-4bae-b60c-e3bfaa4d2682",
      start: "2024-02-20T09:20:00.000Z",
      end: "2024-02-20T09:20:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "case-offer case-accept",
         clientappointment: 1,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 7,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: null,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "fa625dc7-4fa1-48a6-a0d0-e01665664125",
         treatmentname: "Psychotherapy",
         islocked: true,
         appointmenttext: null,
         noofsessions: 10,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 7,
         treatment_split_session_enabled: false,
         treatment_split_session_active: false,
         treatment_split_session_count: 0,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
   {
      id: "3b82c226-2606-47f5-9717-10640213da68",
      start: "2024-02-21T15:11:00.000Z",
      end: "2024-02-21T16:11:00.000Z",
      title: "Model Tesla",
      extendedProps: {
         status: 0,
         message_en: null,
         message_fr: null,
         className: "app-past-event",
         clientappointment: 2,
         clientname: "Model",
         clientid: "f94a1d93-a2fd-4fed-bfa4-36db7732ecf1",
         hasnote: false,
         appointmentstatus: 1,
         sessionduration: 60,
         appoint_config_id: null,
         repeat: null,
         emailcontact: [
            {
               client_email: "roula@centreforrehab.com",
               client_email_type: "1",
               client_email_comment: "lets remind by email",
               client_appointment_reminder: true,
            },
         ],
         phonecontact: [
            {
               client_phone: "416 371 6220",
               client_phone_type: "work",
               client_phone_comment: "note+phone+2",
               client_phone_message: false,
               client_appointment_reminder: true,
            },
         ],
         location_id: 1,
         caseid: "e7fda774-19ae-446d-8fb7-b13e91dfdf58",
         treatmentid: "543204ce-1742-4405-9be1-6548a54efcd9",
         treatmentname: "Short Duration CBT",
         islocked: false,
         appointmenttext: null,
         noofsessions: 5,
         casebudgetamount: 0,
         casebudgettraveldistanceamount: 0,
         casebudgettraveltimeamount: 0,
         casebudgetotheramount: 0,
         sessionstatus: 1,
         treatment_split_session_enabled: true,
         treatment_split_session_active: true,
         treatment_split_session_count: 2,
         split_link_no: null,
         other_split_id: null,
         other_split_apt_date: null,
      },
   },
];

const clientsWithTreatments = appointments.reduce((acc, appointment) => {
   const { clientid, clientname, treatmentid, treatmentname } = appointment.extendedProps;
   const existingClient = acc.find((client) => client.clientid === clientid);
   if (!existingClient) {
      acc.push({ clientid, clientname, treatments: [{ treatmentid, treatmentname }] });
   } else {
      const existingTreatment = existingClient.treatments.find((treatment) => treatment.treatmentid === treatmentid);
      if (!existingTreatment) {
         existingClient.treatments.push({ treatmentid, treatmentname });
      }
   }
   return acc;
}, []);

export { appointments, clientsWithTreatments };
