import { Offcanvas } from "react-bootstrap";
import ChildFormTest from "../forms/ChildFormTest";
import { useContext } from "react";
import { ModalFormContext } from "../context/ModalFormContext";
import { Col, Row, Button } from "react-bootstrap";

const ChildTest = () => {
   const { isOpen, handleBackdropClick, handleClose } = useContext(ModalFormContext);

   return (
      <Offcanvas placement="end" show={isOpen} onHide={handleBackdropClick}>
         <p>ChildTest</p>
         <Row className="mt-5 mb-5">
            <Col>
               <ChildFormTest />
            </Col>
         </Row>
         <Button onClick={handleClose}>Close (Offcancas)</Button>
      </Offcanvas>
   );
};
export default ChildTest;
